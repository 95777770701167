import { Api } from '@/core/api.js'
import { db_timers, db_checks_to_sync } from '@/database'
import { v4 as uuidv4 } from 'uuid'

const api = new Api()

const getDefaultState = () => {
    return {
        timers: null,
        checksToSync: [],
        interval: null
    }
}

// initial state
const state = getDefaultState()

const getters = {
    getTimers(state, rootState) {
        return state.timers
    },

    getTimerById: (state, rootState) => (id) => {
        return state.timers.find((timer) => timer.id === id)
    }
}

const actions = {
    loadTimers(context, params) {
        if (!fnCheckConnection()) {
            return loadTimersOffline(context)
        }
        // const fakeTimers = [
        //     {
        //         id: 'cGxRSUZUNkxvQTVQTGdnL1JVQWtYZz09',
        //         name: 'Limpieza de trapos',
        //         description: 'Meter trapos en la lavadora',
        //         icon: 'https://alex-itw.s3.eu-west-1.amazonaws.com/gallery/timers/Timer_Icon_03.svg',
        //         color: '#d3d3d3',
        //         type: '1',
        //         order: '5',
        //         times: [13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20],
        //         status: '1',
        //         timer_rel: '8',
        //         rel_id: '30'
        //     },
        //     {
        //         id: 'cGxRSUZUNkxvQTVQTGdnL1JVQWtYZz10',
        //         name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a dolor rutrum elit ultrices ullamcorper at non mauris. Phasellus nec neque dolor.',
        //         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a dolor rutrum elit ultrices ullamcorper at non mauris. Phasellus nec neque dolor.',
        //         icon: 'https://alex-itw.s3.eu-west-1.amazonaws.com/gallery/timers/Timer_Icon_03.svg',
        //         color: '#d3d3d3',
        //         type: '1',
        //         order: '5',
        //         times: [20],
        //         status: '1',
        //         timer_rel: '8',
        //         rel_id: '30'
        //     },
        //     {
        //         id: 'cGxRSUZUNkxvQTVQTGdnL1JVQWtYZz11',
        //         name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a dolor rutrum elit ultrices ullamcorper at non mauris. Phasellus nec neque dolor.',
        //         description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean a dolor rutrum elit ultrices ullamcorper at non mauris. Phasellus nec neque dolor.',
        //         icon: 'https://alex-itw.s3.eu-west-1.amazonaws.com/gallery/timers/Timer_Icon_03.svg',
        //         color: '#d3d3d3',
        //         type: '1',
        //         order: '5',
        //         times: [20],
        //         status: '1',
        //         timer_rel: '8',
        //         rel_id: '30'
        //     }
        // ]

        return api
            .get('timer-op/timers')
            .then((response) => {
                const timersArr = Object.values(response.data)

                // formattear y settear los Timers:
                context.dispatch('formatTimers', timersArr)

                // context.dispatch('formatTimers', fakeTimers)

                // actualizar los timers (pending alarm, next alarm, isAvailable):
                context.dispatch('updateTimers')

                // setear función para chequear timers y actualizarlos por segundo:
                context.dispatch('setupInterval')
            })
            .catch((error) => {
                console.error(error)
                // return loadTimersOffline(context)
            })
    },

    getLastTimesDone(context, params) {
        if (!fnCheckConnection()) {
            return loadTimersOffline(context)
        }

        const timersCopy = structuredClone(context.state.timers)

        const timersIds = timersCopy
            .map((timer) => {
                return timer.id
            })
            .join(',')

        return api
            .post('timer-op/timers/checked', { timers: timersIds })
            .then((response) => {
                const lastCheckedTimers = Object.values(response.data)

                lastCheckedTimers.forEach((checked) => {
                    if (checked.last_done) {
                        const lastDoneTimeStamp = moment(checked.last_done, 'YYYY-MM-DD HH:mm:ss').valueOf()

                        timersCopy.forEach((timer) => {
                            if (checked.id === timer.id) {
                                timer.lastAlarmDone = lastDoneTimeStamp
                            }
                            if (timer.currentPendingAlarm && timer.lastAlarmDone >= timer.currentPendingAlarm) {
                                timer.isAvailable = false
                            }
                        })
                    }
                })

                context.commit('setTimers', timersCopy)
                db_timers.clear().then(() => {
                    timersCopy.forEach((value) => {
                        db_timers.setItem(value.id, value)
                    })
                })
            })
            .catch((error) => {
                console.error(error)
                // return loadTimersOffline(context)
            })
    },

    formatTimers(context, params) {
        if (!Array.isArray(params)) {
            return
        }

        function floatToTimeStamp(float, offsetDays = 0) {
            const today = moment().add(offsetDays, 'days').format('YYYY-MM-DD 00:00:00')
            const timeStamp = moment(today)
                .add(float * 60, 'minutes')
                .valueOf()
            return timeStamp
        }

        const timers = Object.values(params)

        const formattedTimers = timers.map((timer) => {
            const formattedAlarms = timer.times.map((float) => {
                const formattedTime = { float: float, timestamp: floatToTimeStamp(float) }
                return formattedTime
            })

            // Obtener los timestamps de mañana, y si no existen crearlos (para evitar contador en 00:00)
            let closestNextTimestamp = null
            let smallestDifference = Infinity
            const currentTimestamp = new Date().getTime()

            for (let i = 0; i < formattedAlarms.length; i++) {
                const timeDifference = formattedAlarms[i].timestamp - currentTimestamp
                if (timeDifference > 0 && timeDifference < smallestDifference) {
                    smallestDifference = timeDifference
                    closestNextTimestamp = formattedAlarms[i].timestamp
                }
            }

            if (closestNextTimestamp === null) {
                const formattedAlarmsTomorrow = timer.times.map((float) => {
                    return { float: float, timestamp: floatToTimeStamp(float, 1) }
                })
                formattedAlarms.push(...formattedAlarmsTomorrow)
            }

            const formattedTimer = {
                ...timer,
                times: formattedAlarms,
                isAvailable: false,
                previousAlarm: null,
                currentPendingAlarm: null,
                nextAlarm: null,
                lastAlarmDone: null
            }
            return formattedTimer
        })

        // setear Timers con los times formateados:
        context.commit('setTimers', formattedTimers)
        db_timers.clear().then(() => {
            formattedTimers.forEach((value, i) => {
                db_timers.setItem(value.id, { ...value, i })
            })
        })
    },

    updateTimers(context, params) {
        // const currentTimestamp = Date.now()

        const currentTimestamp = moment().valueOf()
        let smallestDifference

        const updatedTimers = state.timers.map((timer) => {
            // CURRENT PENDING ALARM ----------------------
            let closestPreviousAlarmTimestamp = null

            const timestamps = timer.times.map((time) => time.timestamp)

            smallestDifference = Infinity

            for (let i = 0; i < timestamps.length; i++) {
                const timeDifference = currentTimestamp - timestamps[i]
                if (timeDifference >= 0 && timeDifference < smallestDifference) {
                    smallestDifference = timeDifference
                    closestPreviousAlarmTimestamp = timestamps[i]
                }
            }
            timer.previousAlarm = closestPreviousAlarmTimestamp

            if (closestPreviousAlarmTimestamp !== null) {
                timer.currentPendingAlarm = closestPreviousAlarmTimestamp
            } else {
                timer.currentPendingAlarm = null
            }

            // IS AVAILABLE: -----------------------------
            // compare current time timestamp with closest previous alarm timestamp
            if (closestPreviousAlarmTimestamp <= currentTimestamp && closestPreviousAlarmTimestamp > timer.lastAlarmDone) {
                timer.isAvailable = true
            } else {
                timer.isAvailable = false
                timer.currentPendingAlarm = null
            }

            // NEXT ALARM: -------------------------------
            let closestNextTimestamp = null
            smallestDifference = Infinity

            for (let i = 0; i < timestamps.length; i++) {
                const timeDifference = timestamps[i] - currentTimestamp
                if (timeDifference > 0 && timeDifference < smallestDifference) {
                    smallestDifference = timeDifference
                    closestNextTimestamp = timestamps[i]
                }
            }

            if (closestNextTimestamp !== null) {
                timer.nextAlarm = closestNextTimestamp
            } else {
                timer.nextAlarm = timestamps[0]
            }

            return timer
        })

        context.commit('setTimers', updatedTimers)
    },

    setupInterval(context, params) {
        if (state.interval) {
            clearInterval(state.interval)
        }

        const interval = setInterval(() => {
            context.dispatch('updateTimers')
        }, 1000)

        context.commit('setInterval', interval)
    },

    setAlarmAsDone(context, params) {
        let requireSync = !(fnCheckConnection() && context.rootGetters.getFastConnection) && context.rootGetters.getOffline ? true : undefined

        const { alarm, checkDateTimestamp } = params

        // update Timers view
        const timersCopy = structuredClone(state.timers)

        let updatedAlarm = null

        const updatedTimers = timersCopy.map((timer) => {
            if (timer.id === alarm.id) {
                const currentPendingAlarm = alarm.currentPendingAlarm

                updatedAlarm = {
                    ...timer,
                    lastAlarmDone: currentPendingAlarm,
                    isAvailable: false,
                    currentPendingAlarm: null
                }

                return updatedAlarm
            } else return timer
        })

        context.commit('setTimers', updatedTimers)
        db_timers.clear().then(() => {
            updatedTimers.forEach((value, i) => {
                db_timers.setItem(value.id, { ...value, i })
            })
        })
        const employeeId = context.rootGetters['loginUser/getLocalEmployee']
        const formattedCheckedAlarm = moment(updatedAlarm.lastAlarmDone).format('YYYY-MM-DD HH:mm:ss')
        const formattedBusinessDate = moment(updatedAlarm.lastAlarmDone).format('YYYY-MM-DD')
        const formattedCheckDate = moment(checkDateTimestamp).format('YYYY-MM-DD HH:mm:ss')

        const alarmData = {
            employee_id: employeeId,
            alarm_date: formattedCheckedAlarm,
            business_date: formattedBusinessDate,
            check_date: formattedCheckDate
        }

        if (requireSync) {
            let id = uuidv4()
            db_checks_to_sync.setItem(id, { ...alarmData, alarm_id: updatedAlarm.id, id }).then(() => {
                context.dispatch('getCountSyncItems', {}, { root: true })
            })
            return true
        }

        // update in API
        return api
            .post(`timer-op/timers/${updatedAlarm.id}`, alarmData)
            .then((response) => {})
            .catch((error) => {
                console.error(error)
            })
    },
    syncChecks(context, params) {
        let checks = []
        db_checks_to_sync
            .iterate(function (value) {
                checks.push(value)
            })
            .then(async function () {
                const groupedChecks = checks.reduce((acc, check) => {
                    acc[check.alarm_id] = acc[check.alarm_id] || []
                    acc[check.alarm_id].push(check)
                    return acc
                }, {})

                Object.values(groupedChecks).forEach((group) => {
                    group.sort((a, b) => new Date(a.alarm_date) - new Date(b.alarm_date))
                })

                const batches = []

                let batch = {}
                let batchCount = 0

                for (const [alarm_id, group] of Object.entries(groupedChecks)) {
                    while (group.length > 0) {
                        if (!batch[alarm_id]) {
                            batch[alarm_id] = []
                        }

                        const toAdd = group.splice(0, 10 - batchCount)
                        batch[alarm_id] = batch[alarm_id].concat(toAdd)
                        batchCount += toAdd.length

                        if (batchCount >= 10) {
                            batches.push(batch)
                            batch = {}
                            batchCount = 0
                        }
                    }
                }

                if (batchCount > 0) {
                    batches.push(batch)
                }

                for (const batch of batches) {
                    await api
                        .post(`timer-op/sync`, { timers: JSON.stringify(batch) })
                        .then((res) => {
                            if (res.status == true) {
                                const idsToDelete = Object.values(batch)
                                    .flat()
                                    .map((check) => check.id)
                                idsToDelete.forEach((id) => db_checks_to_sync.removeItem(id))
                                context.dispatch('getCountSyncItems', {}, { root: true })
                            }
                        })
                        .catch(function (error) {
                            logError(error)
                            return false
                        })
                }

                context.dispatch('getCountSyncItems', {}, { root: true })
            })
    },
    getCountSync(context) {
        return new Promise((resolve, reject) => {
            var count = 0
            db_checks_to_sync
                .iterate(function (value, key, iterationNumber) {
                    count++
                })
                .then(function () {
                    resolve(count)
                })
        })
    }
}

function loadTimersOffline(context) {
    let timers = []
    return db_timers
        .iterate(function (value, key, iterationNumber) {
            timers.push(value)
        })
        .then(function () {
            timers.sort((a, b) => a.i - b.i)
            context.commit('setTimers', timers)
            context.dispatch('updateTimers')
            context.dispatch('setupInterval')
        })
}

const mutations = {
    setTimers(state, payload) {
        state.timers = payload
    },
    setInterval(state, data) {
        state.interval = data
    },
    setChecksToSync(state, data) {
        state.checksToSync = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
