// Import
import i18n from '@/i18n'
import Vue from 'vue'
import store from '@/store'
import { Api } from '@/core/api.js'

import { FORMAT } from '@/constants'
import { db_registers_queue, db_templates, db_registers, db_registers_schemas, db_issues } from '@/database'
// import localforage from 'localforage'
const api = new Api()

// const db_templates = localforage.createInstance({
//     name: 'alexdb',
//     storeName: 'templates'
// })

// const db_registers_queue = localforage.createInstance({
//     name: 'alexdb',
//     storeName: 'registers_queue'
// })

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        stats: {
            alerts: {},
            activity: {}
        },
        items: {},
        categories: {},
        current: false,
        templates: {},
        headers: {},
        number_items: 0,
        number_page: 1,
        type: 3,
        config: {
            date_format: 'YYYY-MM-DD',
            hour_format: 'HH:mm',
            datetime_format: 'YYYY-MM-DD HH:mm',
            has_score: false,
            has_state: false,
            has_timing: false,
            has_mandatory: true,
            is_autosave: true,
            can_create: true,
            item_search: false,
            has_calendar: false,
            temporal_view: false,
            close_when_completed: false,
            auth_users: {
                pin: 2,
                review: 2,
                task: 1
            },
            can_edit: {
                title: false,
                state: false,
                inPast: true,
                inTime: true,
                inFuture: true
            },
            item: {
                itemsTitle: [FORMAT.TITLE, FORMAT.SUBTITLE],
                itemsImage: [FORMAT.PHOTO, FORMAT.SIGNATURE],
                itemsInfo: [FORMAT.DOCUMENT, FORMAT.MESSAGE],
                itemsExcluded: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.DOCUMENT, FORMAT.MESSAGE, FORMAT.LINK, FORMAT.RESOURCE],
                itemsVisibles: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.MESSAGE],
                can_pospone: false,
                rate: false, // Esto es por si van a tener puntuaciones en funcion de lo que se responda
                can_review: false,
                has_periodicity: false,
                has_score: false
            }
        },
        itemsLoaded: false
    }
}

// initial state
const state = getDefaultState()

const loadLocalForageInformation = async () => {
    var registers_page = await db_templates.getItem('registers_page')
    if (registers_page) state.number_page = registers_page

    var registers_items = await db_templates.getItem('registers_items')
    if (registers_items) state.number_items = registers_items
}

loadLocalForageInformation()

// getters
const getters = {
    getItemsLoaded: (state, getters, rootState) => {
        return state.itemsLoaded
    },
    getAll: (state, getters, rootState) => {
        return state.items
    },
    getById: (state, getters, rootState) => (id) => {
        return id ? state.items[id] : false
    },
    getConfig: (state, getters, rootState) => {
        return { ...state.config }
    },
    getTemplates: (state, getters, rootState) => {
        return state.templates
    },
    getTemplateById: (state, getters, rootState) => (id) => {
        return state.templates[id]
    },
    getSubtasks: (state, getters, rootState) => (check, task) => {
        let tasks = check ? state.items[check].items : {}
        let subtask = []

        Object.keys(tasks).map(function (item_id, i) {
            var item = tasks[item_id]
            if (item.parent_id == task) {
                subtask.push(item.item_id)
            }
        })

        return subtask
    },
    getAllStats: (state, getters, rootState) => {
        return state.stats
    },
    getStats: (state, getters, rootState) => (wich) => {
        return state.stats[wich]
    },
    getNumberItems: (state, getters) => {
        return state.number_items
    },
    getNumberPage: (state, getters) => {
        return state.number_page
    },
    getItems: (state, getters, rootState) => {
        return state.items
    },
    getHeaders: (state, getters, rootState) => {
        return state.headers
    },
    getCategories: (state, getters, rootState) => (id) => {
        if (id) {
            return state.categories[id]
        }
        return state.categories
    },
    getTemplatesSearched: (state, getters, rootState) => (search) => {
        var searchValues = search.split(' ')
        var results = []

        for (var templatesIndex in state.templates) {
            var template = state.templates[templatesIndex]
            var templateName =
                template.name != null
                    ? template.name
                          .trim()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .toLowerCase()
                    : ''

            template.searchscore = 0

            for (var searchIndex in searchValues) {
                var searchWord = searchValues[searchIndex]
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()

                template.searchscore = templateName.indexOf(searchWord) > -1 ? (template.searchscore += 1 + searchWord.length * 0.1) : template.searchscore
            }

            if (template.searchscore > 0) {
                results.push(template)
            }
        }

        results.sort((a, b) => (parseInt(a.searchscore) < parseInt(b.searchscore) ? 1 : -1))

        return results
    },
    getItemsBySection: (state, getters, rootState) => (check, section) => {
        var newSectionFound = false
        var startLooking = false
        var resultItems = {}
        var items = check ? Object.values({ ...state.items[check].items }) : []

        // CLEAN OF SUBITEMS
        items = items.filter((obj) => {
            return !obj.parent_id
        })

        // FIRST SORT BY ORDER AND ADD ITEMS TO RESULT
        items
            .sort(function (l, r) {
                return l.order - r.order
            })
            .forEach(function (item) {
                if (item.type != 8 && startLooking && !newSectionFound) {
                    resultItems[item.item_id] = item
                } else {
                    if (!startLooking && item.item_id == section) {
                        startLooking = true
                    } else if (startLooking) {
                        newSectionFound = true
                        startLooking = false
                    }
                }
            })

        return resultItems
    },
    getItemsWithTemporalId(state, getters, rootState) {
        return Object.values(state.items).filter((item) => item.temporal_id)
    }
}

// actions
const actions = {
    resetState({ commit }) {
        commit('resetState')
    },
    async addIssue(context, params) {
        context.dispatch('calcScore', params.checklist_ref)

        let check = context.getters.getById(params.checklist_ref)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        var oData = { template: params.tpl_id }
        if (params.checklist_ref) {
            oData.checklist_ref = params.checklist_ref
        }
        if (params.item_ref) {
            oData.item_ref = params.item_ref
        }

        oData.resume = JSON.stringify(resume)

        if (params.isOfflineCreated) {
            oData.isOfflineCreated = true
        }

        if (params.toolQueue) {
            oData.toolQueue = params.toolQueue
        }

        const canWorkOffline = context.rootGetters.getOffline

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api.post('checklist/add', oData).then(function (response) {
                var items = { ...state.items }
                var reference = {
                    checklist: Object.values(response.data)[0].id,
                    type: Object.values(response.data)[0].type,
                    status: Object.values(response.data)[0].status
                }
                var item = {
                    ...items[params.checklist_ref].items[params.item_ref]
                }
                item.reference = reference
                items[params.checklist_ref].items[params.item_ref] = item
                Vue.set(state, 'items', items)
                return response.data
            })
        } else {
            if (canWorkOffline) {
                log('> register addIssue', oData)
                const issue = await store.dispatch('issues/add', oData)
                log('>> register-ISSUE ITEM-ID', issue)
                const reference = {
                    checklist: issue.id,
                    type: issue.type,
                    status: issue.status
                }

                context.state.items[params.checklist_ref].items[params.item_ref].reference = reference

                db_issues.getItem(params.checklist_ref).then((register) => {
                    if (register) {
                        register.items[params.item_ref].reference = reference
                        db_issues.setItem(params.checklist_ref, register)
                    }
                })
                return issue
            }
        }
    },
    comment(context, params) {
        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 1 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        var sended = {
            action: 'comment',
            checklist: params.check,
            task: params.answer,
            message: params.message,
            total: total,
            answered: answered,
            score: score,
            resume: JSON.stringify(resume)
        }

        if (typeof params.category !== 'undefined') sended.category = params.category
        if (typeof params.file !== 'undefined') sended.file = params.file

        const canWorkOffline = context.rootGetters.getOffline

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/comment', sended)
                .then(function (response) {
                    if (!response.status && context.rootGetters.getOffline) {
                        return context.dispatch('syncAction', sended)
                    } else if (!response.status) {
                        var error = {
                            check: params.check,
                            answer: params.answer
                        }
                        if (typeof params.file !== 'undefined') error.image = null
                        else error.message = ''

                        context.commit('setComment', error)
                        return { status: false }
                    } else {
                        var returned = {
                            check: params.check,
                            answer: params.answer,
                            message: response.data.message
                        }
                        if (typeof response.data.image !== 'undefined') {
                            returned.image = response.data.image
                        }

                        return context.commit('setComment', returned)
                    }
                })
                .catch(() => {
                    if (context.rootGetters.getOffline) {
                        return context.dispatch('syncAction', sended)
                    } else {
                        var error = {
                            check: params.check,
                            answer: params.answer
                        }
                        if (typeof params.file !== 'undefined') error.image = null
                        else error.message = ''

                        context.commit('setComment', error)
                        return { status: false }
                    }
                })
        } else if (context.rootGetters.getOffline) {
            let offlineMessage = {
                check: params.check,
                answer: params.answer,
                message: params.message,
                requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
            }
            if (typeof params.file !== 'undefined') offlineMessage.image = params.file
            return context
                .dispatch('syncAction', sended)
                .then((response) => {
                    return context.commit('setComment', offlineMessage)
                })
                .catch(() => {
                    return context.commit('setComment', offlineMessage)
                })
        } else {
            var error = {
                check: params.check,
                answer: params.answer
            }
            if (typeof params.file !== 'undefined') error.image = null
            else error.message = ''

            context.commit('setComment', error)
            return { status: false }
        }
    },
    complete(context, params) {
        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        const canWorkOffline = context.rootGetters.getOffline
        const paramsApi = {
            checklist: params.check,
            employee: params.emp,
            complete_date: params.date,
            resume: JSON.stringify(resume)
            // type: context.state.type,
        }
        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api.post('checklist/complete', paramsApi).then(function (response) {
                var data = Object.values(response.data)[0]
                // params.values = JSON.parse(params.values);
                // Object.keys(params.values).map(function (id, i) {
                Object.keys(data.items).map(function (id, i) {
                    var val = data.items[id]

                    if (state.config.item.itemsImage.includes(val.type)) {
                        val.value = val.value ? splitCommasBase64(val.value) : val.value
                    }

                    context.commit('setAnswer', {
                        check: data.id,
                        answer: val.item_id,
                        emp: val.employee.id,
                        date: data.complete_date,
                        value: val.value
                    })
                })
                return response
            })
        } else {
            if (canWorkOffline) {
                paramsApi.action = 'complete'
                paramsApi.status = 1
                return context
                    .dispatch('syncAction', paramsApi)
                    .then(() => {
                        Object.keys(check.items).map((id, i) => {
                            const val = check.items[id]

                            if (state.config.item.itemsImage.includes(val.type)) {
                                val.value = val.value && val.value.includes(',') ? splitCommasBase64(val.value) : val.value
                            }
                            const answerObj = {
                                check: params.check,
                                answer: val.item_id,
                                emp: val.employee ? val.employee.id : val.employee,
                                date: params.date,
                                value: val.value,
                                requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
                            }
                            context.commit('setAnswer', answerObj)
                        })

                        //Save check complete
                        check.complete_date = params.date
                        check.status = 1
                        const allItems = { ...context.state.items, [params.check]: check }
                        context.dispatch('format', { data: allItems })
                        context.dispatch('calcScore', params.check)
                        context.commit('setNumberPage', 1)

                        return { status: true }
                    })
                    .catch(() => {
                        Object.keys(check.items).map((id, i) => {
                            const val = check.items[id]

                            if (state.config.item.itemsImage.includes(val.type)) {
                                val.value = val.value && val.value.includes(',') ? splitCommasBase64(val.value) : val.value
                            }
                            const answerObj = {
                                check: params.check,
                                answer: val.item_id,
                                emp: val.employee ? val.employee.id : val.employee,
                                date: params.date,
                                value: val.value,
                                requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
                            }
                            context.commit('setAnswer', answerObj)
                        })

                        //Save check complete
                        check.complete_date = params.date
                        check.status = 1
                        const allItems = { ...context.state.items, [params.check]: check }
                        context.dispatch('format', { data: allItems })
                        context.dispatch('calcScore', params.check)
                        context.commit('setNumberPage', 1)

                        return { status: true }
                    })
            } else {
                return { status: false }
            }
        }
    },
    loadById(context, params) {
        return api.get('checklist/id/' + params.id, {}).then(function (response) {
            context.dispatch('format', { data: response.data })
            context.dispatch('calcScore', params.id)
        })
    },
    loadCategories(context) {
        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api.get('checklist/3/categories').then(function (response) {
                var categories = response.data
                if (Object.values(response.data).length > 0) {
                    categories['all'] = {
                        id: 'all',
                        name: i18n.t('registers.all_registers'),
                        highlight: true,
                        order: -1,
                        color: '#374673',
                        type: 3
                    }
                }

                context.commit('setCategories', categories)
            })
        }
    },
    loadTemplates(context, params) {
        if (!fnCheckConnection()) {
            return loadTemplatesOffline(context)
        }

        return api
            .get('checklist/templates/' + state.type, {})
            .then(function (response) {
                context.commit('setTemplates', { data: response.data })

                _.forEach(_.values(response.data), function (value, key) {
                    db_templates.setItem(value.id, JSON.stringify(value))
                })
            })
            .catch((error) => {
                logError(error)
                return loadTemplatesOffline(context)
            })
    },
    reload(context, params) {
        context.dispatch('format', { data: checklist })
        context.dispatch('calcScore')
    },
    async answer(context, params) {
        let value = params.value
        let subtask = context.getters.getSubtasks(params.check, params.answer)
        const canWorkOffline = context.rootGetters.getOffline

        // In case of image or signature type, JSON-parse the value
        if (state.config.item.itemsImage.includes(params.answer_type)) {
            value = JSON.stringify(value)
        }

        // answer
        context.commit('setAnswer', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.date,
            value: params.value,
            requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
        })

        // calculate score
        await context.dispatch('calcScore', params.check)

        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item })
            })
        }

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]
            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        const paramsApi = {
            checklist: params.check,
            task: params.answer,
            employee: params.emp,
            complete_date: params.date,
            value: value,
            total: total,
            answered: answered,
            score: score,
            resume: JSON.stringify(resume),
            type: params.answer_type
        }

        if (canWorkOffline) {
            await context.dispatch('clearOfflineQueue', { data: paramsApi, action: 'answer' })
        }

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/answer', paramsApi)
                .then(function (response) {
                    if (!response.status) {
                        context.commit('setReset', { check: params.check, answer: params.answer })
                        context.dispatch('calcScore', params.check)
                    } else {
                        let value = response.data.value
                        // In case of image or signature type, JSON-parse the value
                        if (state.config.item.itemsImage.includes(params.answer_type)) {
                            value = splitCommasBase64(value)

                            // answer
                            context.commit('setAnswer', {
                                check: params.check,
                                answer: params.answer,
                                emp: params.emp,
                                date: params.date,
                                value: value
                            })
                        }
                    }
                    return response
                })
                .catch((error) => {
                    if (canWorkOffline) {
                        logError(error)
                        paramsApi.action = 'answer'
                        return context
                            .dispatch('syncAction', paramsApi)
                            .then(() => {
                                return context.dispatch('calcScore', params.check).then(function () {
                                    return { status: false }
                                })
                            })
                            .catch(() => {
                                return context.dispatch('calcScore', params.check).then(function () {
                                    return { status: false }
                                })
                            })
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer })
                        return context.dispatch('calcScore', params.check).then(function () {
                            return { status: false }
                        })
                    }
                })
        } else {
            if (canWorkOffline) {
                paramsApi.action = 'answer'
                return context
                    .dispatch('syncAction', paramsApi)
                    .then(() => {
                        return context.dispatch('calcScore', params.check).then(function () {
                            return { status: false }
                        })
                    })
                    .catch(() => {
                        return context.dispatch('calcScore', params.check).then(function () {
                            return { status: false }
                        })
                    })
            } else {
                context.commit('setReset', { check: params.check, answer: params.answer })
                return context.dispatch('calcScore', params.check).then(function () {
                    return { status: false }
                })
            }
        }
    },
    async apply(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)
        let item = state.items[params.check].items[params.answer]
        let items = context.getters.getItemsBySection(params.check, params.answer)

        context.commit('setApply', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.date == 0 ? params.date : false,
            apply: params.apply
        })

        if (item.type == FORMAT.TITLE) {
            Object.keys(items).map(function (item_id, i) {
                var it = items[item_id]
                context.commit('setApply', { check: params.check, answer: it.item_id, date: params.apply == 0 ? params.date : false, apply: params.apply })
            })
        }

        await context.dispatch('calcScore', params.check)

        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', {
                    check: params.check,
                    answer: item
                })
            })
        }

        let check = context.getters.getById(params.check)
        let total = check.total_score
        let answered = check.completed
        let score = check.score
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let section_id in check.sections) {
            let section = check.sections[section_id]

            for (let item_id in section.items) {
                let item = section.items[item_id]

                if (item.is_visible) {
                    if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }
        }

        let paramsApi = {
            checklist: params.check,
            task: params.answer,
            employee: params.emp,
            complete_date: params.date,
            apply: params.apply,
            total: total,
            answered: answered,
            score: score,
            resume: JSON.stringify(resume)
        }

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/apply', paramsApi)
                .then(function (response) {
                    if (!response.status && context.rootGetters.getOffline) {
                        paramsApi.action = 'apply'
                        return context.dispatch('syncAction', paramsApi)
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
                    }
                })
                .catch(() => {
                    if (context.rootGetters.getOffline) {
                        paramsApi.action = 'apply'
                        return context.dispatch('syncAction', paramsApi)
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
                    }
                })
        } else {
            if (context.rootGetters.getOffline) {
                paramsApi.action = 'apply'
                return context.dispatch('syncAction', paramsApi)
            } else {
                context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
            }
        }
    },
    async applyrecurrent(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)

        // apply
        context.commit('setApply', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.apply == 0 ? params.date : false,
            apply: params.apply
        })

        var item = state.items[params.check].items[params.answer]
        if (item.type == FORMAT.TITLE) {
            let items = context.getters.getItemsBySection(params.check, params.answer)

            Object.keys(items).map(function (item_id, i) {
                var it = items[item_id]
                context.commit('setApply', { check: params.check, answer: it.item_id, date: params.apply == 0 ? params.date : false, apply: params.apply })
            })
        }

        // calculate score
        await context.dispatch('calcScore', params.check)

        // reset child items
        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item })
            })
        }

        // calculate score

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/applyrecurrent', {
                checklist: params.check,
                task: params.answer,
                employee: params.emp,
                complete_date: params.date,
                apply: params.apply,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            })
            .then(function (response) {
                if (!response.status) {
                    context.commit('setReset', { check: params.check, answer: params.answer })
                }
            })
    },
    async reset(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)

        context.commit('setReset', {
            check: params.check,
            answer: params.answer
        })
        await context.dispatch('calcScore', params.check)

        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                var item = subtask[item_id]
                log('subtask ')
                log(item)
                context.commit('setReset', {
                    check: params.check,
                    answer: item
                })
            })
        }

        return { status: true }
    },
    async add(context, params) {
        var type = context.state.type
        var oData = { template: params.tpl_id }
        if (params.emp_ref) {
            oData.employee_ref = params.emp_ref
        }
        if (params.checklist_ref) {
            oData.checklist_ref = params.checklist_ref
        }
        if (params.item_ref) {
            oData.item_ref = params.item_ref
        }

        const canWorkOffline = context.rootGetters.getOffline

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api.post('checklist/add', oData).then(function (response) {
                context.dispatch('format', { data: response.data })
                context.dispatch('calcScore', Object.keys(response.data)[0])
                context.commit('setNumberPage', 1)
                return response.data[Object.keys(response.data)[0]]
            })
        } else {
            if (canWorkOffline) {
                //canWorkOffline
                const items = JSON.parse(JSON.stringify(context.state.templates[params.tpl_id].items))
                const itemsWithTempIds = items.map((item) => {
                    if (parseInt(item.type) === FORMAT.MULTIPLE && item.config && item.config.values) {
                        item.config.values = item.config.values.map((option) => {
                            if (option.c) {
                                return { v: option.v, c: option.c }
                            } else if (option.v && !option.c) {
                                return { v: option.v, c: false }
                            } else {
                                return { v: option, c: false }
                            }
                        })
                    }
                    return {
                        ...item,
                        alerts: item.alerts ? JSON.parse(JSON.stringify(item.alerts)) : false,
                        type: parseInt(item.type),
                        item_id: item.id,
                        complete_date: false,
                        value: null,
                        review: false,

                        alerts_triggers: item.alerts ? JSON.parse(JSON.stringify(item.alerts)) : false
                    }
                })
                log('//itemsWithTempIds', itemsWithTempIds)
                const oTemplateItems = {}
                itemsWithTempIds.forEach((item) => {
                    oTemplateItems[item.id] = item
                    log('//itemsWithTempIds', item)
                })
                const user = store.getters['loginUser/getUserInformation']
                log('$$$ user', user)
                const newRegister = {
                    business_date: moment(),
                    category_id: context.state.templates[params.tpl_id].category_id,
                    complete_date: false,
                    completed: false,
                    config: context.state.templates[params.tpl_id].config,
                    created_date: moment(),
                    data: false,
                    def: state.config,
                    employee: { ...user },
                    employee_ref: { ...user },
                    employees: [],
                    id: 'tmpitw' + Math.floor(Date.now() / 1000),
                    issue_id: false,
                    items: JSON.parse(JSON.stringify(oTemplateItems)),
                    justify_id: false,
                    recover: false,
                    score: 0,
                    status: 0,
                    template_id: params.tpl_id,
                    title: context.state.templates[params.tpl_id].name,
                    total: items.length,
                    type: context.state.templates[params.tpl_id].type,
                    unanswered: items.length,
                    uncompleted: 0,
                    requireSync: true
                }
                const syncData = {
                    action: 'add',
                    checklist: newRegister.id,
                    employee: newRegister.employee.id,
                    template_ref: params.tpl_id,
                    type: newRegister.type
                }

                store.dispatch('register/syncAction', syncData)
                const allItems = { [newRegister.id]: newRegister, ...context.state.items }

                await context.dispatch('format', { data: allItems })
                await context.dispatch('calcScore', newRegister.id)
                context.commit('setNumberPage', 1)

                db_registers
                    .setItem(newRegister.id, JSON.parse(JSON.stringify(newRegister)))
                    .then(() => {
                        log('Register added to localForage')
                    })
                    .catch((err) => {
                        log('Error adding register to localForage', err)
                    })

                return state.items[newRegister.id]
            }
        }
    },
    delete(context, params) {
        var oData = {
            checklist: params.check,
            employee: params.emp,
            status: -1
        }

        if (params.ref_item) {
            oData['ref_item'] = params.ref_item
        }

        return api.post('checklist/update', oData).then(function (response) {
            db_registers.removeItem(params.check).then(() => {
                log('Register removed from localForage')
            })
            return response
        })
    },
    loadStats(context, params) {
        context.commit('resetStats', {})
        var currentDate = moment(params.date)

        // SET ALERTS --------------------------------------------------------------

        // SET ACTIVITY ------------------------------------------------------------

        // -- Created Registers
        context
            .dispatch('loadFromDate', {
                date: currentDate.format('YYYY-MM-DD')
            })
            .then(function (response) {
                let result = response.data ? Object.keys(response.data).length : 0
                if (response.data && result > 0) {
                    context.commit('setStats', {
                        where: 'activity',
                        key_name: 'created',
                        values: {
                            name: i18n.tc('home.activity.registers_generated', result),
                            value: result
                        }
                    })
                }
            })
    },
    format({ state, context, commit, dispatch, rootState }, params = {}) {
        // var append = typeof params.append != "undefined" ? params.append : false;
        var aItems = {}
        // if (append) {
        //   var aItems = state.items;
        // }
        if (typeof params.data !== 'undefined') {
            Object.keys(params.data).map(function (item_id, i) {
                var list = params.data[item_id]

                //Offline cases
                if (list.created_date && typeof list.created_date === 'string') {
                    list.created_date = moment(list.created_date)
                }

                if (list.business_date && typeof list.business_date === 'string') {
                    list.business_date = moment(list.business_date)
                }

                if (list.complete_date && typeof list.complete_date === 'string') {
                    list.complete_date = moment(list.complete_date)
                }

                log('>> format cd', list.created_date, list.created_date ? (moment.isMoment(list.created_date) ? list.created_date : moment(list.created_date * 1000)) : false)
                // Timezone set
                list.business_date = list.business_date ? (moment.isMoment(list.business_date) ? list.business_date : moment(list.business_date * 1000)) : false
                list.created_date = list.created_date ? (moment.isMoment(list.created_date) ? list.created_date : moment(list.created_date * 1000)) : false
                list.complete_date = list.complete_date ? (moment.isMoment(list.complete_date) ? list.complete_date : moment(list.complete_date * 1000)) : false

                list.def = state.config

                // Loop for all items
                Object.keys(list.items).map(function (item_id, i) {
                    var item = list.items[item_id]

                    // Timezone set
                    item.complete_date = item.complete_date ? (moment.isMoment(item.complete_date) ? item.complete_date : moment(item.complete_date * 1000)) : false
                    if (item.review) {
                        item.review['complete_date'] = item.review.complete_date ? (moment.isMoment(item.review.complete_date) ? item.review.complete_date : moment(item.review.complete_date * 1000)) : false
                    }

                    // If type image - Split value to array
                    if (state.config.item.itemsImage.includes(item.type)) {
                        item.value = item.value && typeof item.value === 'string' ? splitCommasBase64(item.value) : item.value
                    }

                    item.complete_date = item.complete_date
                    item.available = false

                    list.items[item_id] = { ...item }
                    Vue.set(list.items, item_id, { ...item })
                })

                aItems[list.id] = list
            })
        }

        commit('setFormat', { items: aItems })
    },
    calcScore({ state, context, commit, dispatch, rootState }, id) {
        var aItems = state.items

        Object.keys(aItems).map(function (item_id, i) {
            var list = aItems[item_id]
            if (typeof id === 'undefined' || item_id == id) {
                console.warn('calcScore', item_id, list)
                var past_score = list.score ? list.score : 0

                list.def = state.config
                list.uncompleted = 0
                list.completed = 0
                list.unanswered = 0
                list.total = 0
                list.score = 0
                list.justify_id = list.justify_id ? list.justify_id : list.justify_msg ? '0' : false

                // Loop for all items
                Object.keys(list.items).map(function (item_id, i) {
                    var item = list.items[item_id]
                    item.is_visible = true
                    item.alerts = false

                    // Subtasks
                    if (typeof item.conditions !== 'undefined' && item.conditions != null && item.conditions) {
                        var conditions = item.conditions
                        var current_show = null
                        var current_order = -1
                        var cond_operator = typeof conditions.operator !== 'undefined' ? conditions.operator : 'AND'
                        for (var key in conditions.values) {
                            var show = false
                            let condition = conditions.values[key]
                            log(key, condition, list.items)
                            // console.log(list.items)
                            console.log('parent', list.items[key] ? list.items[key] : false)
                            let value = list.items[key] ? list.items[key].value : false
                            item.is_visible = false

                            var operator = condition && condition.operator ? condition.operator : '='
                            var val = condition ? condition.value : false

                            if ((value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(list.items[key].type)) && list.items[key] && list.items[key].is_visible) {
                                if (operator == 'true' && val == 'true') {
                                    show = true
                                } else {
                                    // Get task type from parent
                                    switch (list.items[key].type) {
                                        //   switch (item.type) {
                                        case FORMAT.EMAIL:
                                        case FORMAT.DOCUMENT:
                                        case FORMAT.CHECK:
                                        case FORMAT.YESNO:
                                            console.log(val, value, operator, parseInt(val) == 1 || (val == '' && operator == '!empty'))
                                            show = compare(parseInt(val) == 1 || (val == '' && operator == '!empty'), value == true || value == 'true' || (value != null && val == '' && operator == '!empty'), operator)
                                            break
                                        case FORMAT.YESNONC:
                                            show = compare(parseInt(val) || (val == '' && operator == '!empty'), value || (val == '' && operator == '!empty'), operator)
                                            break
                                        case FORMAT.MULTIPLE:
                                            show = compare(val || (val == '' && operator == '!empty'), value, operator)
                                            break
                                        case FORMAT.TEXT:
                                        case FORMAT.TEXTAREA:
                                        case FORMAT.PHOTO:
                                        case FORMAT.DATE:
                                        case FORMAT.TIME:
                                        case FORMAT.DATETIME:
                                        case FORMAT.SIGNATURE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.NUMBER:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (val == '' && operator == '!empty')
                                            break
                                        case FORMAT.TEMPERATURE:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (val == '' && operator == '!empty')
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            log(current_show, show, cond_operator)
                            if (current_show == null) current_show = show
                            else {
                                switch (cond_operator) {
                                    case 'AND':
                                        current_show = current_show && show
                                        break
                                    case 'OR':
                                        current_show = current_show || show
                                        break
                                }
                            }
                            if (show) {
                                current_order = list.items[key].order
                            }
                        }

                        item.is_visible = current_show
                        item.order = parseFloat(current_order) + 0.5
                    }

                    // Alerts TODO
                    if (item.alerts_triggers) {
                        let alerts = item.alerts_triggers
                        let value = item.value

                        let oAlert = {}
                        let message = ''
                        let preview = ''
                        let issues = []
                        let classes = []

                        for (var i in alerts) {
                            let alert = alerts[i]
                            let operator = alert.operator ? alert.operator : '='
                            let val = alert.value

                            let show = false

                            if ((value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(item.type)) && item.is_visible) {
                                if (operator == 'true' && val == 'true') {
                                    show = true
                                } else {
                                    switch (item.type) {
                                        case FORMAT.EMAIL:
                                        case FORMAT.DOCUMENT:
                                        case FORMAT.CHECK:
                                        case FORMAT.YESNO:
                                            show = compare(parseInt(val) == 1 || (val == '' && operator == '!empty'), value == true || value == 'true', operator)
                                            break
                                        case FORMAT.YESNONC:
                                            show = compare(parseInt(val) || (val == '' && operator == '!empty'), value, operator)
                                            break
                                        case FORMAT.MULTIPLE:
                                            show = compare(val || (val == '' && operator == '!empty'), value, operator)
                                            break
                                        case FORMAT.TEXT:
                                        case FORMAT.TEXTAREA:
                                        case FORMAT.PHOTO:
                                        case FORMAT.DATE:
                                        case FORMAT.TIME:
                                        case FORMAT.DATETIME:
                                        case FORMAT.SIGNATURE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.NUMBER:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (value != '-' && operator == '!empty')
                                            break
                                        case FORMAT.TEMPERATURE:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (value != '-' && operator == '!empty')
                                            break
                                        default:
                                            break
                                    }
                                }
                            }

                            if (show && alert.msg) {
                                if (message == '') message = alert.msg
                                else message = message + '<br/>' + alert.msg

                                if (preview == '') preview = alert.msg
                                else preview = preview + ', ' + alert.msg
                            }

                            if (show && alert.tpl) {
                                issues.push(alert.tpl)
                            }
                            if (show && alert.class) {
                                if (alert.class == 'alert') {
                                    alert.class = 'warning'
                                }
                                classes.push(alert.class)
                            }
                        }

                        oAlert = {
                            preview: preview,
                            message: message,
                            issues: issues,
                            class: classes
                        }

                        if (oAlert.message.length > 0 || oAlert.issues.length > 0) {
                            item.alerts = oAlert
                            Vue.set(item, 'alerts', oAlert)
                        }
                    }

                    if (((!item.parent_id && list.def.item.itemsExcluded.indexOf(item.type) == -1) || (item.parent_id && item.is_visible)) && !item.planned_to_date) {
                        switch (item.type) {
                            case FORMAT.YESNO: // SI/NO
                            case FORMAT.YESNONC: // SI/NO/NSNC
                            case FORMAT.CHECK: // CHECKS
                            case FORMAT.TEXT: // TEXTO
                            case FORMAT.PHOTO: // FOTO
                            case FORMAT.NUMBER: // NUMBER
                            case FORMAT.DATE: // DATE
                            case FORMAT.TEXTAREA: // TEXTAREA
                            case FORMAT.MULTIPLE: // MULTIPLE SELECT
                            case FORMAT.TIME: // TIME
                            case FORMAT.DATETIME: // DATETIME
                            case FORMAT.SIGNATURE: // SIGNATURE
                            case FORMAT.TEMPERATURE: // TEMPERATURE
                            case FORMAT.EMAIL: // EMAIL
                            case FORMAT.DOCUMENT: // DOCUMENT
                                if (item.complete_date == false && item.apply) list.unanswered++
                                else {
                                    // Required to be reviewed
                                    if (item.require_review) {
                                        list.completed += item.review ? 1 : 0.5
                                    } else list.completed += 1 // Not required to be reviewed
                                }

                                break

                            default:
                                break
                        }
                        if (![FORMAT.LINK, FORMAT.RESOURCE].includes(item.type)) {
                            list.total++
                        }
                    }
                })

                list.score = Math.round((parseFloat(list.completed) * 100) / parseInt(list.total))

                // ORDER
                Object.keys(aItems).map(function (item_id, i) {
                    let list = JSON.parse(JSON.stringify(aItems[item_id]))
                    var items = Object.keys(list.items)
                        .sort((a, b) => {
                            return parseFloat(list.items[a].order) - parseFloat(list.items[b].order)
                        })
                        .reduce((prev, curr, i) => {
                            prev[i] = list.items[curr]
                            return prev
                        }, {})

                    let aux2 = {}
                    for (var k in items) {
                        aux2[items[k].item_id] = items[k]
                    }
                    // // // console.log(aux,aux2)

                    list.items = aux2
                    aItems[item_id] = list
                })

                aItems[list.id] = { ...list }
            }
        })

        commit('setScore', { items: aItems })
    },
    loadItems(context, params) {
        context.commit('setItemsLoaded', false)
        if (!params.noRefresh && fnCheckConnection() && context.rootGetters.getFastConnection) {
            if (!fnCheckConnection()) {
                return loadItemsOffline(context.commit, context.dispatch, params.template)
            }

            return api
                .post('checklist/resume/' + params.template, { number_pages: context.state.number_page, number_items: 10 })
                .then(function (response) {
                    _.forEach(_.values(response.data.values), async function (value, key) {
                        console.log('INSERT -> ', value.id, value)
                        await db_registers.setItem(value.id, value)
                        if (!(await db_registers_schemas.getItem(params.template))) {
                            await db_registers_schemas.setItem(params.template, response.data.schema)
                        }
                    })
                    context.dispatch('mergeOffline', { data: response.data }).then(function (response2) {
                        context.dispatch('format', { data: response2.data.values })
                        context.dispatch('calcScore')
                        context.commit('setResume', { data: response2.data.schema })
                        context.commit('setNumberItems', response2.data.number_items)
                        context.commit('setItemsLoaded', true)
                    })
                })
                .catch((error) => {
                    return loadItemsOffline(context.commit, context.dispatch, params.template)
                })
        } else if (!params.noRefresh) {
            loadItemsOffline(context.commit, context.dispatch, params.template)
            context.dispatch('calcScore')
        }
    },
    loadItemsPagination(context, params) {
        context.commit('setItemsLoaded', false)
        if (!fnCheckConnection()) {
            return loadItemsOffline(context.commit, context.dispatch)
        }
        return api
            .post('checklist/resume/' + params.id, params)
            .then(function (response) {
                _.forEach(_.values(response.data.values), async function (value, key) {
                    console.log('INSERT -> ', value.id, value)
                    await db_registers.setItem(value.id, value)
                    await db_registers_schemas.setItem(value.id, response.data.schema)
                })
                context.dispatch('mergeOffline', { data: response.data }).then(function (response2) {
                    context.dispatch('format', { data: response.data.values })
                    context.dispatch('calcScore')
                    context.commit('setResume', { data: response.data.schema })
                    context.commit('setNumberItems', response.data.number_items)
                    context.commit('setNumberPage', params.number_pages)
                    context.commit('setItemsLoaded', true)
                })
            })
            .catch((error) => {
                return loadItemsOffline(context.commit, context.dispatch)
            })
    },
    loadFromDate(context, params) {
        var cType = 3

        params.date = params.date ? params.date : moment().format('YYYY-MM-DD')
        return api.get('checklist/' + cType + '/' + params.date, {}).then(function (response) {
            return response
        })
    },
    recoverValues(context, params) {
        // TODO APPEND
        return api.post('checklist/recover/' + params.check, {}).then(function (response) {
            if (response.status) {
                context.dispatch('format', { data: response.data })
                context.dispatch('calcScore', params.id)
            } else {
                router.go()
            }

            return response.data
        })
    },
    syncAction(context, params) {
        params.queued_date = moment().valueOf()
        params.id = moment().valueOf()

        return context.dispatch('checklist/addRegisterToQueue', params, { root: true }).then(() => {
            return { status: true }
        })
    },
    sync(context, queue) {
        let checklists = {}
        for (let key in queue) {
            let queueElement = queue[key]
            if (typeof checklists[queueElement.checklist] === 'undefined') {
                checklists[queueElement.checklist] = {
                    items: [],
                    resume: { it: 0, is: 0, a: 0, n: 0 },
                    total: 0,
                    answered: 0,
                    score: 0
                }
            }

            checklists[queueElement.checklist].resume = queueElement.resume
            checklists[queueElement.checklist].total = queueElement.total
            checklists[queueElement.checklist].answered = queueElement.answered
            checklists[queueElement.checklist].score = queueElement.score
            checklists[queueElement.checklist].items.push(queueElement)
        }
        return api
            .post(`checklist/${state.type}/sync`, { checklists: JSON.stringify(checklists) })
            .then(function (response) {
                if (!response.status) {
                    throw new Error('API error') //Por si falla algo de email
                }
                if (response.data) {
                    const issues = response.data.filter((queue) => queue.issue_id)
                    if (issues.length > 0) {
                        log('>> updateIssueOfflineDatabase', issues)
                        context.dispatch('updateIssueOfflineDatabase', issues)
                        response.data = response.data.filter((queue) => !queue.issue_id)
                    }
                }
                return context
                    .dispatch('updateOfflineDatabase', response)
                    .then(() => {
                        return response
                    })
                    .catch(function (error) {
                        logError(error)
                        return false
                    })
            })
            .catch(function (error) {
                logError(error)
                return false
            })
    },
    async updateIssueOfflineDatabase(context, response) {
        if (response) {
            const checklists = response
            log('>> updateIssueOfflineDatabase', checklists)
            checklists.forEach(async (checklist) => {
                //Actualizar el estado de sincronización de los registros
                await store.dispatch('issues/updateStateOfSync', checklist)

                log('>> updateOfflineDatabase issues', checklist)
                // Actualizar el id de sincronización de los registros
                if (checklist && checklist.checklist_temporal && checklist.checklist) {
                    const register = await db_issues.getItem(checklist.checklist_temporal)
                    if (register) {
                        register.id = checklist.checklist
                        register.issue_id = checklist.issue_id
                        await db_issues.removeItem(checklist.checklist_temporal)
                        await db_issues.setItem(checklist.checklist, register)
                    }
                    //change the key of the item and id
                    const copy = store.getters['issues/getAll']
                    log('>> updateOfflineDatabase issues store', copy)
                    copy[checklist.checklist_temporal].id = checklist.checklist
                    copy[checklist.checklist_temporal].requireSync = false
                    copy[checklist.checklist_temporal].issue_id = checklist.issue_id
                    copy[checklist.checklist_temporal].temporal_id = checklist.checklist_temporal
                    const updateItem = { [checklist.checklist]: copy[checklist.checklist_temporal] }
                    delete copy[checklist.checklist_temporal]

                    const allItems = { ...updateItem, ...copy }
                    /* context.state.items = {...copy} */
                    await store.dispatch('issues/format', { data: allItems })

                    //update allItems
                    const newIssueItem = store.getters['issues/getAll'][copy.status]
                    newIssueItem[checklist.checklist_temporal].id = checklist.checklist
                    newIssueItem[checklist.checklist_temporal].requireSync = false
                    newIssueItem[checklist.checklist_temporal].issue_id = checklist.issue_id
                    newIssueItem[checklist.checklist_temporal].temporal_id = checklist.checklist_temporal
                    const newAllItems = { ...newIssueItem }
                    store.commit('issues/setAllItems', { items: newAllItems, type: copy.status })
                }
            })
        }
    },
    async mergeOffline({ state, context, commit, dispatch, rootState }, params = {}) {
        await db_registers_queue.iterate(function (answer, checklistId, iterationNumber) {
            if (typeof params.data.values[answer.checklist] !== 'undefined' && params.data.values[answer.checklist].items[answer.task] !== 'undefined') {
                params.data.values[answer.checklist].items[answer.task].employee = answer.employee
                params.data.values[answer.checklist].items[answer.task].complete_date = answer.complete_date
                params.data.values[answer.checklist].items[answer.task].value = answer.value.toString()
                params.data.values[answer.checklist].items[answer.task].requireSync = true
            }
        })
        log('mergeOffline', params)
        return params
    },
    async updateQueueTemplateId_End(context, { old_id, new_id }) {
        var aPromises = []
        await db_registers_queue.iterate(function (answer, checklistId, iterationNumber) {
            console.log('key ->', checklistId, 'data ->', answer, 'tmp_id old ->', old_id, 'new_id ->', new_id)
            if (answer.checklist == old_id) {
                answer.checklist = new_id
                aPromises.push(
                    new Promise((resolve2, reject2) => {
                        db_registers_queue.removeItem(checklistId).then(() => {
                            console.log('LINE REMOVED')
                            db_registers_queue.setItem(checklistId, answer).then(() => {
                                console.log('TEMPLATE MODIFIED')
                                resolve2()
                            })
                        })
                    })
                )
            }
        })
        console.log('updateQueueTemplateId -> End iterate')
        return Promise.all(aPromises).then(() => {
            console.log('updateQueueTemplateId -> end all Promises')
            // resolve()
        })
    },
    async updateQueueTemplateId(context, { old_id, new_id }) {
        return new Promise(async (resolve, reject) => {
            console.log('updateQueueTemplateId -> init Promise')
            await context.dispatch('updateQueueTemplateId_End', { old_id: old_id, new_id: new_id })

            resolve()
        })
    },
    async updateStateOfSync(context, params) {
        log('>> updateStateOfSync', params)
        // Actualizar el estado de sincronización de los registros
        if (params && params.checklist_temporal) {
            const register = await db_registers.getItem(params.checklist_temporal)
            if (register) {
                register.requireSync = false
                await db_registers.setItem(params.checklist_temporal, register)
            }
            state.items[params.checklist_temporal].requireSync = false
        }
    },
    async updateOfflineDatabase(context, response) {
        if (response.data) {
            if (response.data.type && response.data.type !== 'add') return

            const checklists = response.data
            checklists.forEach(async (checklist) => {
                //Actualizar el estado de sincronización de los registros
                await context.dispatch('updateStateOfSync', checklist)

                log('>> updateOfflineDatabase', checklist)
                // Actualizar el id de sincronización de los registros
                if (checklist && checklist.checklist_temporal && checklist.checklist) {
                    const register = await db_registers.getItem(checklist.checklist_temporal)
                    if (register) {
                        register.id = checklist.checklist
                        await db_registers.removeItem(checklist.checklist_temporal)
                        await db_registers.setItem(checklist.checklist, register)
                    }
                    const check = context.state.items[checklist.checklist] ? checklist.checklist : context.state.items[checklist.checklist_temporal] ? checklist.checklist_temporal : false
                    if (check) {
                        //change the key of the item and id
                        const copy = { ...context.state.items }
                        copy[check].id = checklist.checklist
                        copy[check].temporal_id = checklist.checklist_temporal
                        const updateItem = { [checklist.checklist]: copy[check] }
                        delete copy[checklist.checklist_temporal]

                        const allItems = { ...updateItem, ...copy }
                        context.state.items = { ...copy }
                        await context.dispatch('format', { data: allItems })
                    }
                }
            })
        }
    },
    async clearOfflineQueue(context, params) {
        log('clearOfflineQueue', params)
        const data = params.data
        const action = params.action
        //['answer', 'reset', 'validate', 'unvalidate', 'complete', 'uncomplete']
        const mapActionsToRemove = {
            answer: ['answer', 'reset'],
            reset: ['answer', 'reset'],
            validate: ['validate', 'unvalidate'],
            unvalidate: ['validate', 'unvalidate'],
            complete: ['complete']
        }

        const actionsToRemove = mapActionsToRemove[action]

        await db_registers_queue.iterate(async function (answer, key, iterationNumber) {
            const isTheSameChecklist = answer.checklist === data.checklist && answer.task === data.task
            if (actionsToRemove.includes(answer.action) && isTheSameChecklist) {
                await db_registers_queue.removeItem(key)
            }
        })
    }
}

const mutations = {
    setItemsLoaded(state, payload) {
        state.itemsLoaded = payload
    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    setTemplates(state, payload) {
        var templates = {}

        if (payload.data != undefined) {
            Object.keys(payload.data).map(function (tpl_id, i) {
                var tpl = { ...payload.data[tpl_id] }

                tpl.last_update = tpl.last_update ? (moment.isMoment(tpl.last_update) ? tpl.last_update : moment(tpl.last_update * 1000)) : false

                templates[tpl.id] = tpl
            })
        }

        Vue.set(state, 'templates', { ...templates })
    },
    setAnswerProp(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var prop = payload.prop
        var value = payload.value

        state.items[check].items[answer][prop] = value

        Vue.set(state, 'items', { ...state.items })
    },
    setComment(state, payload) {
        // console.log("payload");
        // console.log(payload);
        let check = payload.check
        let answer = payload.answer
        let aux = state.items[check].items[answer]

        aux.message = payload.message
        aux.image = payload.image ? payload.image : false
        aux.requireSync = payload.requireSync

        Vue.set(state.items[check].items, answer, { ...aux })
        // console.log(state.items);
        Vue.set(state, 'items', { ...state.items })
    },
    setCategories(state, payload) {
        var categories = state.categories
        categories = payload
        Vue.set(state, 'categories', { ...categories })
    },
    setAnswer(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var value = payload.value
        var date = payload.date

        const copyState = { ...state.items }

        copyState[check].items[answer].value = value
        copyState[check].items[answer].requireSync = payload.requireSync

        if (date) {
            var item = copyState[check].items[answer]
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) copyState[check].items[answer].employee = emp

        Vue.set(state, 'items', { ...copyState })
        state.items = { ...copyState }
    },
    setApply(state, payload) {
        const check = payload.check
        const answer = payload.answer
        const emp = payload.emp
        const apply = payload.apply
        const date = payload.date

        state.items[check].items[answer].apply = apply

        if (date) {
            state.items[check].items[answer].complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
            state.items[check].items[answer].value = null
        } else {
            state.items[check].items[answer].complete_date = false
        }

        if (emp) {
            state.items[check].items[answer].employee = emp
        }

        Vue.set(state, 'items', { ...state.items })
    },
    setReset(state, payload) {
        var check = payload.check
        var answer = payload.answer

        state.items[check].items[answer].complete_date = false
        state.items[check].items[answer].employee = false
        state.items[check].items[answer].review = false
        state.items[check].items[answer].value = null

        Vue.set(state, 'items', { ...state.items })
    },
    setValidation(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var date = payload.date

        state.items[check].items[answer].review = {}
        if (date) {
            var item = state.items[check].items[answer].review
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) {
            var employee = store.getters['employee/getEmployee'](emp)
            state.items[check].items[answer].review.employee = employee
        }
        state.items[check].items[answer].review.value = 1

        Vue.set(state, 'items', { ...state.items })
    },
    setUnvalidation(state, payload) {
        var check = payload.check
        var answer = payload.answer

        state.items[check].items[answer].review = false

        Vue.set(state, 'items', { ...state.items })
    },
    resetStats(state, payload) {
        state.stats.alerts = {}
        state.stats.activity = {}
    },
    setStats(state, payload) {
        state.stats[payload.where] = {}
        state.stats[payload.where][payload.key_name] = payload.values
    },
    setFormat(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
    },
    setScore(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
    },
    setResume(state, payload) {
        state.headers = payload.data
        Vue.set(state, 'headers', { ...state.headers })
    },
    setNumberItems(state, payload) {
        state.number_items = payload
        Vue.set(state, 'number_items', state.number_items)
        db_templates.setItem('registers_items', payload)
    },
    setNumberPage(state, payload) {
        state.number_page = payload
        Vue.set(state, 'number_page', state.number_page)
        db_templates.setItem('registers_page', payload)
    },
    setRequireSync(state, payload) {
        let check = payload.check
        let answer = payload.answer
        let apiId = payload.apiId

        if (answer) {
            console.log('setRequireSync', check, answer, apiId)
            check = state.items[check] ? check : state.items[apiId] ? apiId : false
            console.log('setRequireSync 2', check)
            if (check) {
                state.items[check].items[answer].requireSync = payload.requireSync
                state.items[check].items[answer] = { ...state.items[check].items[answer] }
                Vue.set(state, 'items', { ...state.items })
            }
        }
    }
}

function loadTemplatesOffline(context) {
    let templates = []
    let count = 0

    return db_templates
        .iterate(function (value, key, iterationNumber) {
            value = typeof value === 'string' ? JSON.parse(value) : value
            if (value.type == state.type) {
                templates.push(value)
                count++
            }
        })
        .then(function () {
            if (count > 0) {
                context.commit('setTemplates', { data: templates })
            }
        })
}

async function loadItemsOffline(commit, dispatch, template = false) {
    let items = {}
    let count = 0
    let schema = false
    if (template) {
        schema = await db_registers_schemas.getItem(template)
    }

    return db_registers
        .iterate(function (value, key, iterationNumber) {
            items[value.id] = value
            count++
        })
        .then(function () {
            if (count > 0) {
                dispatch('mergeOffline', { data: items }).then((response) => {
                    dispatch('format', { data: response.data })
                    commit('setResume', { data: schema })
                    dispatch('calcScore')
                })
            }
        })
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
