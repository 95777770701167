// Import
import i18n from '@/i18n'
import Vue from 'vue'
import store from '@/store'
import { Api } from '@/core/api.js'

import { FORMAT } from '@/constants'

import localforage from 'localforage'
const api = new Api()
import { db_issues } from '@/database'

const db_templates = localforage.createInstance({
    name: 'alexdb',
    storeName: 'issues_templates'
})
const db_issues_queue = localforage.createInstance({
    name: 'alexdb',
    storeName: 'issues_queue'
})

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        stats: {
            alerts: {},
            activity: {}
        },
        items: {},
        allItems: {},
        templates: {},
        categories: {},
        date: false,
        type: 4,
        numberOpenIssues: {},
        config: {
            date_format: 'YYYY-MM-DD',
            hour_format: 'HH:mm',
            datetime_format: 'YYYY-MM-DD HH:mm',
            has_score: false,
            has_state: true,
            has_timing: false,
            has_mandatory: false,
            is_autosave: false,
            can_create: true,
            item_search: false,
            has_calendar: true,
            temporal_view: false,
            close_when_completed: false,
            auth_users: {
                pin: 2,
                review: 2,
                task: 1
            },
            can_edit: {
                title: true,
                state: true,
                inPast: true,
                inTime: true,
                inFuture: true
            },
            item: {
                itemsTitle: [FORMAT.TITLE, FORMAT.SUBTITLE],
                itemsImage: [FORMAT.PHOTO, FORMAT.SIGNATURE],
                itemsInfo: [FORMAT.DOCUMENT, FORMAT.MESSAGE],
                itemsExcluded: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.DOCUMENT, FORMAT.MESSAGE, FORMAT.LINK, FORMAT.RESOURCE],
                itemsVisibles: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.MESSAGE, FORMAT.LINK, FORMAT.RESOURCE],
                can_pospone: false,
                rate: false, // Esto es por si van a tener puntuaciones en funcion de lo que se responda
                can_review: true,
                has_periodicity: false,
                has_score: false
            }
        }
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    // Commons
    getAll: (state, getters, rootState) => {
        return state.items
    },
    getById: (state, getters, rootState) => (id) => {
        return id ? state.items[id] : false
    },
    getNumberOpenIssues: (state, getters, rootState) => {
        return state.numberOpenIssues
    },
    getAllItems: (state, getters, rootState) => {
        //Sort items by created_date
        let items = {}

        Object.entries(state.allItems).forEach(([key, value]) => {
            items[key] = Object.values(value).sort((a, b) => {
                return new Date(b.created_date) - new Date(a.created_date)
            })
        })

        return items
    },
    getConfig: (state, getters, rootState) => {
        return { ...state.config }
    },
    getTemplates: (state, getters, rootState) => (id) => {
        if (id) {
            return state.templates[id]
        }
        return state.templates
    },
    getTemplatesCopy: (state, getters, rootState) => {
        // if there is no 'all' template, add it
        let templates = { ...state.templates }
        if (!templates['all']) {
            templates['all'] = {
                id: 'all',
                name: i18n.t('issues.all_issues')
            }
        }
        return templates
    },
    getSubtasks: (state, getters, rootState) => (check, task) => {
        let tasks = check ? state.items[check].items : {}
        let subtask = []

        Object.keys(tasks).map(function (item_id, i) {
            var item = tasks[item_id]
            if (item.parent_id == task) {
                subtask.push(item.item_id)
            }
        })

        return subtask
    },
    getAllStats: (state, getters, rootState) => {
        return state.stats
    },
    getStats: (state, getters, rootState) => (wich) => {
        // console.log('ISSUES - getStats ->', state.stats[wich])
        return state.stats[wich]
    },
    getCategories: (state, getters, rootState) => (id) => {
        if (id) {
            return state.categories[id]
        }
        return state.categories
    },
    getItemsBySection: (state, getters, rootState) => (check, section) => {
        var newSectionFound = false
        var startLooking = false
        var resultItems = {}
        var items = check ? Object.values({ ...state.items[check].items }) : []

        // CLEAN OF SUBITEMS
        items = items.filter((obj) => {
            return !obj.parent_id
        })

        // FIRST SORT BY ORDER AND ADD ITEMS TO RESULT
        items
            .sort(function (l, r) {
                return l.order - r.order
            })
            .forEach(function (item) {
                if (item.type != 8 && startLooking && !newSectionFound) {
                    resultItems[item.item_id] = item
                } else {
                    if (!startLooking && item.item_id == section) {
                        startLooking = true
                    } else if (startLooking) {
                        newSectionFound = true
                        startLooking = false
                    }
                }
            })

        return resultItems
    },
    getItemsWithTemporalId(state, getters, rootState) {
        return Object.values(state.items).filter((item) => item.temporal_id)
    },
    getCustomTitle: (state) => (id) => {
        return id ? state.items[id].title : false
    }
}

// actions
const actions = {
    resetState({ commit }) {
        commit('resetState')
    },
    loadNumberOpenIssues(context) {
        const allItems = context.state.allItems
        log('ISSUES - getNumberOpenIssues ->', allItems)
        const numberOpenIssues = {}
        Object.keys(allItems).forEach((key) => {
            numberOpenIssues[key] = Object.keys(allItems[key]).length
        })

        context.commit('setNumberOpenIssues', numberOpenIssues)
    },
    loadCategories(context) {
        return api.get('checklist/4/categories').then(function (response) {
            var categories = response.data
            if (Object.values(response.data).length > 0) {
                categories['all'] = {
                    id: 'all',
                    name: i18n.t('issues.all_issues'),
                    highlight: true,
                    order: -1,
                    color: '#374673',
                    type: 3
                }
            }

            context.commit('setCategories', categories)
        })
    },
    loadAll({ state, context, commit, dispatch, rootState }, params) {
        if (fnCheckConnection()) {
            if (params.noRefresh) {
                return Promise.resolve()
            }
            return api.post('getIssues', params).then(function (response) {
                return dispatch('mergeOffline', response).then(function (response2) {
                    _.forEach(_.values(response2.data), function (value, key) {
                        db_issues.setItem(value.id, JSON.parse(JSON.stringify(value)))
                    })
                    dispatch('format', { data: response2.data, type: params.type })
                    dispatch('calcScore')
                    return response2
                })
            })
        } else {
            var items = {}
            var count = 0
            db_issues
                .iterate(function (value, key, iterationNumber) {
                    const item = typeof value == 'string' ? JSON.parse(value) : value
                    items[item.id] = item
                    count++
                })
                .then(function () {
                    if (count > 0) {
                        dispatch('format', { data: items })
                        dispatch('calcScore')
                    }
                })
        }
    },
    addIssue(context, params) {
        var oData = { template: params.tpl_id }
        if (params.checklist_ref) {
            context.dispatch('calcScore', params.checklist_ref)

            oData.checklist_ref = params.checklist_ref

            let check = context.getters.getById(params.checklist_ref)
            let resume = { it: 0, is: 0, a: 0, n: 0 }

            for (let id in check.items) {
                let item = check.items[id]

                if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                    if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }

            oData.resume = JSON.stringify(resume)
        }
        if (params.item_ref) {
            oData.item_ref = params.item_ref
        }

        return api.post('checklist/add', oData).then(function (response) {
            var items = { ...state.items }
            var reference = { checklist: Object.values(response.data)[0].id, type: Object.values(response.data)[0].type, status: Object.values(response.data)[0].status }
            var item = { ...items[params.checklist_ref].items[params.item_ref] }
            item.reference = reference
            items[params.checklist_ref].items[params.item_ref] = item
            Vue.set(state, 'items', items)
            return response.data
        })
    },
    comment(context, params) {
        const canWorkOffline = context.rootGetters.getOffline

        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let resume = { it: 0, is: 0, a: 0, n: 1 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        var sended = {
            action: 'comment',
            checklist: params.check,
            task: params.answer,
            message: params.message,
            resume: JSON.stringify(resume),
            requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
        }
        if (typeof params.file !== 'undefined') sended.file = params.file

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/comment', sended)
                .then(function (response) {
                    if (!response.status && context.rootGetters.getOffline) {
                        return context.dispatch('syncAction', sended)
                    } else if (!response.status) {
                        var error = {
                            check: params.check,
                            answer: params.answer
                        }
                        if (typeof params.file !== 'undefined') error.image = null
                        else error.message = ''

                        context.commit('setComment', error)
                        return { status: false }
                    } else {
                        var returned = {
                            check: params.check,
                            answer: params.answer,
                            message: response.data.message
                        }
                        if (typeof response.data.image !== 'undefined') {
                            returned.image = response.data.image
                        }

                        return context.commit('setComment', returned)
                    }
                })
                .catch(() => {
                    if (context.rootGetters.getOffline) {
                        let offlineMessage = {
                            check: params.check,
                            answer: params.answer,
                            message: params.message,
                            requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
                        }
                        if (typeof params.file !== 'undefined') offlineMessage.image = params.file
                        return context
                            .dispatch('syncAction', sended)
                            .then((response) => {
                                context.commit('setComment', offlineMessage)
                            })
                            .catch(() => {
                                context.commit('setComment', offlineMessage)
                            })
                    } else {
                        var error = {
                            check: params.check,
                            answer: params.answer
                        }
                        if (typeof params.file !== 'undefined') error.image = null
                        else error.message = ''

                        context.commit('setComment', error)
                        return { status: false }
                    }
                })
        } else if (context.rootGetters.getOffline) {
            console.error(sended)
            return context.dispatch('syncAction', sended)
        } else {
            var error = {
                check: params.check,
                answer: params.answer
            }
            if (typeof params.file !== 'undefined') error.image = null
            else error.message = ''

            context.commit('setComment', error)
            return { status: false }
        }
    },
    validate(context, params) {
        context.commit('setValidation', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.date
        })
        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let resume = { it: 0, is: 0, a: 0, n: 1 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/validate', {
                checklist: params.check,
                task: params.answer,
                employee: params.emp,
                complete_date: params.date,
                resume: JSON.stringify(resume)
            })
            .then(function (response) {})
    },
    unvalidate(context, params) {
        context.commit('setUnvalidation', {
            check: params.check,
            answer: params.answer
        })
        context.commit('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let resume = { it: 0, is: 0, a: 0, n: 1 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/unvalidate', {
                checklist: params.check,
                task: params.answer,
                resume: JSON.stringify(resume)
            })
            .then(function (response) {})
    },
    complete(context, params) {
        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.checklist)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/complete', {
                checklist: params.checklist,
                complete_date: params.complete_date,
                resume: JSON.stringify(resume)
            })
            .then(function (response) {
                context.commit('setCompleteDateItem', params.checklist, response)
                return response
            })
    },
    uncomplete(context, params) {
        return api
            .post('checklist/uncomplete', {
                checklist: params.checklist
            })
            .then(function (response) {
                // console.log(response)
                return response
            })
    },
    loadById(context, params) {
        if (!fnCheckConnection()) {
            return loadDetailOffline(context, params.id)
        }

        if (params.noRefresh) {
            const item = context.getters.getById(params.id)
            context.dispatch('format', { data: item })
            context.dispatch('calcScore', params.id)
            return Promise.resolve()
        }

        if (!params.noRefresh && fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api.get('checklist/id/' + params.id, {}).then(function (response) {
                context.dispatch('format', { data: response.data })
                context.dispatch('calcScore', params.id)
            })
        }
    },
    loadTemplates(context, params) {
        if (!fnCheckConnection()) {
            return loadOffline(context)
        }
        return api
            .get('checklist/templates/' + state.type, {})
            .then(function (response) {
                context.commit('setTemplates', { data: response.data })

                _.forEach(_.values(response.data), function (value, key) {
                    db_templates.setItem(value.id, JSON.stringify(value))
                })
            })
            .catch((error) => {
                logError(error)
                return loadOffline(context)
            })
    },
    reload() {
        store.dispatch('format', { data: checklist })
        store.dispatch('calcScore')
    },
    update(context, params) {
        var oData = { checklist: params.check }
        if (params.title) oData.title = params.title
        if (params.status) oData.status = params.status
        if (params.emp_ref) {
            oData.employee_ref = params.emp_ref
        }
        let newState = context.rootState.issue_states[params.status]
        let completeParams = {
            check: context.state.items[params.check].status,
            item: params.check,
            complete_date: newState.type == 3 ? moment().format('YYYY-MM-DD HH:mm:ss') : false,
            new_status: params.status
        }
        context.commit('setCompleteDateItem', completeParams)

        const canWorkOffline = context.rootGetters.getOffline

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api.post('checklist/update', oData).then(function (response) {
                if (params.title) {
                    context.commit('setChecklistProp', {
                        check: oData.checklist,
                        prop: 'title',
                        value: oData.title
                    })
                }
                if (params.status) {
                    context.commit('setChecklistProp', {
                        check: oData.checklist,
                        prop: 'status',
                        value: oData.status
                    })
                }
                if (params.emp_ref) {
                    context.commit('setChecklistProp', {
                        check: oData.checklist,
                        prop: 'employee_ref',
                        value: oData.employee_ref
                    })
                }
            })
        } else {
            if (canWorkOffline) {
                const oldState = context.state.items[params.check].status
                oData.action = 'update'
                if (params.title) {
                    context.commit('setChecklistProp', {
                        check: oData.checklist,
                        prop: 'title',
                        value: oData.title
                    })
                }
                if (params.status) {
                    context.commit('setChecklistProp', {
                        check: oData.checklist,
                        prop: 'status',
                        value: oData.status
                    })
                }
                if (params.emp_ref) {
                    context.commit('setChecklistProp', {
                        check: oData.checklist,
                        prop: 'employee_ref',
                        value: oData.employee_ref
                    })
                }

                //Update allItems
                const newIssueItem = context.state.items[params.check]
                //delete allItems[oldState][params.check]
                delete context.state.allItems[oldState][params.check]
                context.state.allItems[newIssueItem.status][params.check] = { ...newIssueItem }

                context
                    .dispatch('syncAction', oData)
                    .then(() => {
                        db_issues.iterate(function (value, key, iterationNumber) {
                            const item = typeof value == 'string' ? JSON.parse(value) : value
                            if (item.id == params.check) {
                                if (params.title) item.title = params.title
                                if (params.status) item.status = params.status
                                if (params.emp_ref) item.employee_ref = params.emp_ref
                                if (completeParams.complete_date) item.complete_date = completeParams.complete_date
                                db_issues.setItem(item.id, JSON.parse(JSON.stringify(item)))
                            }
                        })
                        const items = context.state.items
                        context.dispatch('format', { data: items })
                    })
                    .catch(() => {
                        db_issues.iterate(function (value, key, iterationNumber) {
                            const item = typeof value == 'string' ? JSON.parse(value) : value
                            if (item.id == params.check) {
                                if (params.title) item.title = params.title
                                if (params.status) item.status = params.status
                                if (params.emp_ref) item.employee_ref = params.emp_ref
                                if (completeParams.complete_date) item.complete_date = completeParams.complete_date
                                db_issues.setItem(item.id, JSON.parse(JSON.stringify(item)))
                            }
                        })
                        const items = context.state.items
                        context.dispatch('format', { data: items })
                    })
            }
        }
    },
    async craftCustomTitle(context, params) {
        //getTemplates
        const checklist = context.getters.getById(params.check)
        const template = context.getters.getTemplates(checklist.template_id)        

        // items del checklist
        let items = Object.entries(checklist.items)
        items.sort(([, a], [, b]) => a.order - b.order)

        // Crear título
        let customtitle = ''
        let foundCustomTitle = false

        items.forEach(([key, item]) => {
            if (item.config && item.config.custom_title === true && item.value != null && item.value != '') {
                switch (item.type) {
                    case 4:
                        if (item.value.trim().length > 0) {
                            customtitle += item.value + ' - '
                        }
                        break
                    case 6:
                    case 11:
                        customtitle += item.value + ' - '
                        break
                    case 15:
                        if (item.config.units) {
                            customtitle += item.value + item.config.units + ' - '
                        } else {
                            customtitle += item.value + 'º - '
                        }
                        break
                }
                foundCustomTitle = true
            }
        })

        // Setear titulo
        if (foundCustomTitle) {
            context.commit('setTitle', {
                check: params.check,
                title: customtitle + template.name
            })
        }
    },
    async answer(context, params) {
        let value = params.value
        let subtask = context.getters.getSubtasks(params.check, params.answer)
        const canWorkOffline = context.rootGetters.getOffline
        // In case of image or signature type, JSON-parse the value
        if (state.config.item.itemsImage.includes(params.answer_type)) {
            value = JSON.stringify(value)
        }

        if (params.value == '' && params.answer_type == 4) {
            context.commit('issues/reset', {
                check: this.cId,
                answer: this.aId
            })
        }

        context.commit('setAnswer', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.date,
            value: params.value,
            requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
        })
        // console.log(params)
        await context.dispatch('calcScore', params.check)
        let check = context.getters.getById(params.check)
        let resume = { it: 0, is: 0, a: 0, n: 0 }
        for (let id in check.items) {
            let item = check.items[id]
            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        const paramsApi = {
            checklist: params.check,
            task: params.answer,
            employee: params.emp,
            complete_date: params.date,
            value: value,
            resume: JSON.stringify(resume),
            type: params.answer_type
            // customTitleEnabled: params.customTitle //
        }

        if (canWorkOffline) {
            await context.dispatch('clearOfflineQueue', { data: paramsApi, action: 'answer' })
        }

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/answer', paramsApi)
                .then(function (response) {
                    if (!response.status) {
                        context.commit('setReset', { check: params.check, answer: params.answer })
                        context.dispatch('calcScore', params.check)
                    } else {
                        let value = response.data.value
                        // In case of image or signature type, JSON-parse the value
                        if (state.config.item.itemsImage.includes(params.answer_type)) {
                            value = splitCommasBase64(value)

                            // answer
                            context.commit('setAnswer', {
                                check: params.check,
                                answer: params.answer,
                                emp: params.emp,
                                date: params.date,
                                value: value
                            })
                        }
                        
                        let title = response.data.customized_title ? response.data.customized_title : false
                        // if set, update custom title:
                        if (title) {
                            context.commit('setTitle', {
                                check: params.check,
                                title: title
                            })
                        }
                    }

                    return response
                })
                .catch((error) => {
                    if (canWorkOffline) {
                        logError(error)
                        paramsApi.action = 'answer'
                        return context
                            .dispatch('syncAction', paramsApi)
                            .then(() => {
                                return context.dispatch('calcScore', params.check).then(function () {
                                    return { status: false }
                                })
                            })
                            .catch(() => {
                                return context.dispatch('calcScore', params.check).then(function () {
                                    return { status: false }
                                })
                            })
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer })
                        return context.dispatch('calcScore', params.check).then(function () {
                            return { status: false }
                        })
                    }
                })
        } else {
            if (canWorkOffline) {
                paramsApi.action = 'answer'
                const checklist = context.getters.getById(params.check)
                if (checklist.toolQueue) {
                    paramsApi.toolQueue = checklist.toolQueue
                }

                context.dispatch('craftCustomTitle', params)

                return context
                    .dispatch('syncAction', paramsApi)
                    .then(() => {
                        return context.dispatch('calcScore', params.check).then(function () {
                            return { status: false }
                        })
                    })
                    .catch(() => {
                        return context.dispatch('calcScore', params.check).then(function () {
                            return { status: false }
                        })
                    })
            } else {
                context.commit('setReset', { check: params.check, answer: params.answer })
                return context.dispatch('calcScore', params.check).then(function () {
                    return { status: false }
                })
            }
        }
    },
    async apply(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)
        let item = state.items[params.check].items[params.answer]
        let items = context.getters.getItemsBySection(params.check, params.answer)

        context.commit('setApply', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.date == 0 ? params.date : false,
            apply: params.apply
        })

        if (item.type == FORMAT.TITLE) {
            Object.keys(items).map(function (item_id, i) {
                var it = items[item_id]
                context.commit('setApply', { check: params.check, answer: it.item_id, date: params.apply == 0 ? params.date : false, apply: params.apply })
            })
        }

        await context.dispatch('calcScore', params.check)

        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item })
            })
        }

        let check = context.getters.getById(params.check)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        let paramsApi = {
            checklist: params.check,
            task: params.answer,
            employee: params.emp,
            complete_date: params.date,
            apply: params.apply,
            resume: JSON.stringify(resume)
        }

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/apply', paramsApi)
                .then(function (response) {
                    if (!response.status && context.rootGetters.getOffline) {
                        paramsApi.action = 'apply'
                        return context.dispatch('syncAction', paramsApi)
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
                    }
                })
                .catch(() => {
                    if (context.rootGetters.getOffline) {
                        paramsApi.action = 'apply'
                        return context.dispatch('syncAction', paramsApi)
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
                    }
                })
        } else {
            if (context.rootGetters.getOffline) {
                paramsApi.action = 'apply'
                return context.dispatch('syncAction', paramsApi)
            } else {
                context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
            }
        }
    },
    async applyrecurrent(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)

        // apply
        context.commit('setApply', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.apply == 0 ? params.date : false,
            apply: params.apply
        })

        var item = state.items[params.check].items[params.answer]
        if (item.type == FORMAT.TITLE) {
            let items = context.getters.getItemsBySection(params.check, params.answer)

            Object.keys(items).map(function (item_id, i) {
                var it = items[item_id]
                context.commit('setApply', { check: params.check, answer: it.item_id, date: params.apply == 0 ? params.date : false, apply: params.apply })
            })
        }

        // calculate score
        await context.dispatch('calcScore', params.check)

        // reset child items
        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item })
            })
        }

        // calculate score

        let check = context.getters.getById(params.check)
        let total = check.total
        let answered = check.completed
        let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        }

        return api
            .post('checklist/task/applyrecurrent', {
                checklist: params.check,
                task: params.answer,
                employee: params.emp,
                complete_date: params.date,
                apply: params.apply,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            })
            .then(function (response) {
                if (!response.status) {
                    context.commit('setReset', { check: params.check, answer: params.answer })
                }
            })
    },
    async reset(context, params) {
        const canWorkOffline = context.rootGetters.getOffline
        if (context.rootGetters.getOffline || fnCheckConnection()) {
            let subtask = context.getters.getSubtasks(params.check, params.answer)

            context.commit('setReset', {
                check: params.check,
                answer: params.answer
            })
            await context.dispatch('calcScore', params.check)

            if (subtask.length > 0) {
                Object.keys(subtask).map(function (item_id, i) {
                    var item = subtask[item_id]
                    context.commit('setReset', { check: params.check, answer: item })
                })
            }

            let check = context.getters.getById(params.check)
            let total = check.total
            let answered = check.completed
            let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
            let resume = { it: 0, is: 0, a: 0, n: 0 }

            for (let id in check.items) {
                let item = check.items[id]

                if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                    if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }

            let paramsApi = {
                checklist: params.check,
                task: params.answer,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            }

            if (canWorkOffline) {
                await context.dispatch('clearOfflineQueue', { data: paramsApi, action: 'reset' })
            }

            if (fnCheckConnection() && context.rootGetters.getFastConnection) {
                return api
                    .post('checklist/task/reset', paramsApi)
                    .then(function (response) {
                        if (response.customized_title) {
                            title = response.customized_title ? response.customized_title : false
                        } else if (response.title) {
                            title = response.title ? response.title : false
                        }
                        if (title) {
                            context.commit('setTitle', {
                                check: params.check,
                                title: title.trim()
                            })
                        }
                        if (!response.status) {
                            paramsApi.action = 'reset'
                            return context
                                .dispatch('syncAction', paramsApi)
                                .then(function () {
                                    return { status: true }
                                })
                                .catch(() => {
                                    return { status: true }
                                })
                        } else {
                            return { status: true }
                        }
                    })
                    .catch(() => {
                        paramsApi.action = 'reset'
                        return context
                            .dispatch('syncAction', paramsApi)
                            .then(function () {
                                return { status: true }
                            })
                            .catch(() => {
                                return { status: true }
                            })
                    })
            } else {
                paramsApi.action = 'reset'
                context.dispatch('craftCustomTitle', params)
                return context
                    .dispatch('syncAction', paramsApi)
                    .then(function () {
                        return { status: true }
                    })
                    .catch(() => {
                        return { status: true }
                    });
                
            }
        } else {
            return { status: false }
        }
    },
    async add(context, params) {
        var type = context.state.type
        var oData = { template: params.tpl_id }
        if (params.emp_ref) {
            oData.employee_ref = params.emp_ref
        }
        if (params.checklist_ref) {
            oData.checklist_ref = params.checklist_ref
        }
        if (params.item_ref) {
            oData.item_ref = params.item_ref
        }

        /*  await context.dispatch('calcScore', params.checklist_ref)

        let check = context.getters.getById(params.checklist_ref) */
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        /* for (let id in check.items) {
            let item = check.items[id]

            if (item.is_visible) {
                if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                if (item.message) resume.n++ // if item has notes
                if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
            }
        } */

        oData.resume = JSON.stringify(resume)
        const canWorkOffline = context.rootGetters.getOffline
        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            context.commit('resetAllItems')
            return api
                .post('checklist/add', oData)
                .then(function (response) {
                    context.dispatch('format', { data: response.data })
                    context.dispatch('calcScore', Object.keys(response.data)[0])
                    return response.data[Object.keys(response.data)[0]]
                })
                .catch(() => {
                    //POR SI OCURRE ALGUN FALLO (EJ. ENVIAR UN MAIL CON VARIABLES EQUIVOCADAS)
                })
        } else {
            if (canWorkOffline) {
                if (params.isOfflineCreated) params.tpl_id = params.template
                const items = context.state.templates[params.tpl_id].items
                const itemsWithTempIds = items.map((item) => {
                    if (parseInt(item.type) === FORMAT.MULTIPLE && item.config && item.config.values) {
                        item.config.values = item.config.values.map((option) => {
                            if (option.c) {
                                return { v: option.v, c: option.c }
                            } else if (option.v && !option.c) {
                                return { v: option.v, c: false }
                            } else {
                                return { v: option, c: false }
                            }
                        })
                    }
                    return {
                        ...item,
                        type: parseInt(item.type),
                        item_id: item.id,
                        complete_date: false,
                        value: null,
                        review: false,
                        alerts: items.alerts ? items.alerts : false,
                        alerts_triggers: items.alerts_triggers ? items.alerts_triggers : false,
                        parent_id: item.parent_id ? item.parent_id : false,
                        apply: item.apply ? item.apply : '1', //"1"
                        reference: false,
                        conditions: false,
                        employee: false,
                        image: false,
                        is_visible: true,
                        mandatory: item.mandatory ? parseInt(item.mandatory) : false,
                        margin: 0,
                        message: false,
                        parent_id: false,
                        periodicity: 1,
                        reference: false,
                        require_pin: false,
                        //require_pin_review:false,
                        require_review: false,
                        resource: false
                    }
                })

                const oTemplateItems = {}
                itemsWithTempIds.forEach((item) => {
                    oTemplateItems[item.id] = item
                })

                const issueStatusId = Object.entries(store.getters['getIssueStates'])
                    .filter(([key, value]) => value.type === 1)
                    .map(([key, value]) => value.id)

                let reference = false
                const tool = {
                    tasks: '1',
                    register: '3',
                    audit: '2',
                    issues: '4'
                }

                if (params.checklist_ref && params.item_ref && params.toolQueue) {
                    reference = {
                        ref: {
                            checklist: params.checklist_ref,
                            item: params.item_ref,
                            type: tool[params.toolQueue]
                        }
                    }
                }

                const newIssues = {
                    business_date: moment(),
                    category_id: false,
                    complete_date: false,
                    completed: false,
                    config: context.state.templates[params.tpl_id].config,
                    created_date: moment(),
                    data: reference,
                    def: context.state.config,
                    default: false,
                    description: context.state.templates[params.tpl_id].description,
                    employee: store.getters['loginUser/getUserInformation'],
                    employee_ref: store.getters['loginUser/getUserInformation'],
                    employees: [],
                    id: 'tmpitw' + Math.floor(Date.now() / 1000),
                    issue_id: null,
                    is_visible: true,
                    items: oTemplateItems,
                    justify_id: false,
                    recover: false,
                    score: 0,
                    status: issueStatusId[0],
                    template: params.tpl_id,
                    title: context.state.templates[params.tpl_id].name,
                    total: items.length,
                    type: context.state.templates[params.tpl_id].type,
                    unanswered: items.length,
                    requireSync: true
                    //uncompleted: 0
                }

                const syncData = {
                    action: 'add',
                    checklist: newIssues.id,
                    employee: newIssues.employee.id,
                    template_ref: params.tpl_id,
                    type: newIssues.type,
                    issue_id: newIssues.issue_id,
                    issue_status: newIssues.status
                }

                if (params.isOfflineCreated) {
                    syncData.item_ref = params.item_ref
                    syncData.checklist_ref = params.checklist_ref
                }

                if (params.toolQueue) {
                    syncData.toolQueue = params.toolQueue
                    newIssues['toolQueue'] = params.toolQueue
                }

                store.dispatch('issues/syncAction', syncData)
                const allItems = { [newIssues.id]: newIssues, ...context.state.items }
                await db_issues.setItem(newIssues.id, JSON.parse(JSON.stringify(newIssues)))

                context.dispatch('format', { data: allItems })
                //allItems variable update
                const newIssueItem = context.state.items[newIssues.id]
                context.state.allItems[newIssues.status] = { [newIssues.id]: newIssueItem, ...context.state.allItems[newIssues.status] }

                return newIssues
            }
        }
    },
    delete(context, params) {
        var oData = {
            checklist: params.check,
            employee: params.emp,
            status: -1
        }
        if (params.ref_item) {
            oData['ref_item'] = params.ref_item
        }

        db_issues.removeItem(params.check)

        return api.post('checklist/update', oData).then(function (response) {
            return response
        })
    },
    loadStats(context, params) {
        context.commit('resetStats', {})
        let config = state.config
        var currentDate = moment(params.date)
        var items = state.items
        var states = params.states
        var left_states = {}
        var new_issues = 0

        // SET ALERTS --------------------------------------------------------------
        // --> Left state
        // Si tiene ESTADOS hacer la alerta, sino no
        if (config.has_state && states) {
            let limitDate = moment(currentDate).subtract(30, 'days')
            let state_searched = []

            // Get searched states
            Object.keys(states).map(function (state_id, i) {
                var state = states[state_id]

                if (state.alert) {
                    state_searched.push(state.id)
                    left_states[state.id] = { value: 0, name: state.name }
                }
            })

            // Search issues with those states
            Object.keys(items).map(function (item_id, i) {
                var item = items[item_id]

                if (state_searched.includes(item.status) && moment(item.business_date).isBetween(limitDate, currentDate, 'day', [])) {
                    left_states[item.status].value++
                }
            })

            Object.keys(left_states).map(function (item_id, i) {
                var st = left_states[item_id]
                log(st)
                if (parseInt(st.value) > 0) {
                    st.state = 'info'
                    st.name = i18n.tc('home.issues.left_state', st.value) + " '" + st.name + "'"
                    st.route = 'Issues'
                    context.commit('setStats', {
                        where: 'alerts',
                        key_name: st.name.trim().toLowerCase(),
                        values: st
                    })
                }
            })
        }

        // SET ACTIVITY ------------------------------------------------------------

        // -- Created Issues
        Object.keys(items).map(function (item_id, i) {
            var item = items[item_id]

            if (item.business_date == currentDate.format('YYYY-MM-DD')) {
                new_issues++
            }
        })

        if (new_issues > 0) {
            context.commit('setStats', {
                where: 'activity',
                key_name: 'created',
                values: {
                    name: i18n.tc('home.activity.issues_generated', new_issues),
                    value: new_issues
                }
            })
        }
    },
    format({ state, context, commit, dispatch, rootState }, params = {}) {
        // var append = typeof payload.append != "undefined" ? payload.append : false;

        var aItems = {}
        // if (append) {
        //   var aItems = state.items;
        // }

        if (!params || !params.data) {
            return
        }

        Object.keys(params.data).map(function (item_id, i) {
            var list = params.data[item_id]

            // Timezone set
            if (typeof list.business_date === 'string') {
                list.business_date = moment(list.business_date)
            } else {
                list.business_date = list.business_date ? (moment.isMoment(list.business_date) ? list.business_date : moment(list.business_date * 1000)) : false
            }

            if (typeof list.created_date === 'string') {
                list.created_date = moment(list.created_date)
            } else {
                list.created_date = list.created_date ? (moment.isMoment(list.created_date) ? list.created_date : moment(list.created_date * 1000)) : false
            }

            if (typeof list.complete_date === 'string') {
                list.complete_date = moment(list.complete_date)
            } else {
                list.complete_date = list.complete_date ? (moment.isMoment(list.complete_date) ? list.complete_date : moment(list.complete_date * 1000)) : false
            }

            list.def = state.config
            list.is_visible = true

            // Loop for all items
            Object.keys(list.items).map(function (item_id, i) {
                var item = list.items[item_id]

                // Timezone set
                item.complete_date = item.complete_date ? (moment.isMoment(item.complete_date) ? item.complete_date : moment(item.complete_date * 1000)) : false
                if (item.review) {
                    item.review['complete_date'] = item.review.complete_date ? (moment.isMoment(item.review.complete_date) ? item.review.complete_date : moment(item.review.complete_date * 1000)) : false
                }

                // If type image - Split value to array
                if (state.config.item.itemsImage.includes(item.type)) {
                    item.value = item.value && typeof item.value === 'string' ? splitCommasBase64(item.value) : item.value
                }

                // console.log(item.config)
                item.config = typeof item.config === 'string' ? JSON.parse(item.config) : item.config

                list.items[item_id] = { ...item }
                Vue.set(list.items, item_id, { ...item })
            })

            aItems[list.id] = list
        })
        commit('setAllItems', { items: aItems, type: params.type })
        commit('setFormat', { items: aItems })
    },
    calcScore({ state, context, commit, dispatch, rootState }, id) {
        var aItems = state.items

        Object.keys(aItems).map(function (item_id, i) {
            var list = aItems[item_id]
            if (typeof id === 'undefined' || item_id == id) {
                var past_score = list.score ? list.score : 0
                if (typeof id !== 'undefined') {
                    list.def = state.config
                    list.uncompleted = 0
                    list.completed = 0
                    list.unanswered = 0
                    list.total = 0
                    list.score = 0
                    list.justify_id = list.justify_id ? list.justify_id : list.justify_msg ? '0' : false
                }

                // Loop for all items
                Object.keys(list.items).map(function (item_id, i) {
                    var item = list.items[item_id]
                    item.is_visible = true
                    item.alerts = false

                    // Subtasks
                    if (typeof item.conditions !== 'undefined' && item.conditions != null && item.conditions) {
                        var conditions = item.conditions
                        var current_show = null
                        var current_order = -1
                        var cond_operator = typeof conditions.operator !== 'undefined' ? conditions.operator : 'AND'
                        for (var key in conditions.values) {
                            var show = false
                            let condition = conditions.values[key]
                            let value = list.items[key] ? list.items[key].value : false
                            item.is_visible = false

                            var operator = condition && condition.operator ? condition.operator : '='
                            var val = condition ? condition.value : false

                            if ((value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(list.items[key].type)) && list.items[key] && list.items[key].is_visible) {
                                if (operator == 'true' && val == 'true') {
                                    show = true
                                } else {
                                    // Get task type from parent
                                    switch (list.items[key].type) {
                                        //   switch (item.type) {
                                        case FORMAT.EMAIL:
                                        case FORMAT.DOCUMENT:
                                        case FORMAT.CHECK:
                                        case FORMAT.YESNO:
                                            // console.log(val, value, operator, parseInt(val) == 1 || (val == '' && operator == '!empty'))
                                            show = compare(parseInt(val) == 1 || (val == '' && operator == '!empty'), value == true || value == 'true' || (value != null && val == '' && operator == '!empty'), operator)
                                            break
                                        case FORMAT.YESNONC:
                                            show = compare(parseInt(val) || (val == '' && operator == '!empty'), value || (val == '' && operator == '!empty'), operator)
                                            break
                                        case FORMAT.MULTIPLE:
                                            show = compare(val || (val == '' && operator == '!empty'), value, operator)
                                            break
                                        case FORMAT.TEXT:
                                        case FORMAT.TEXTAREA:
                                        case FORMAT.PHOTO:
                                        case FORMAT.DATE:
                                        case FORMAT.TIME:
                                        case FORMAT.DATETIME:
                                        case FORMAT.SIGNATURE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.NUMBER:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (val == '' && operator == '!empty')
                                            break
                                        case FORMAT.TEMPERATURE:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (val == '' && operator == '!empty')
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            if (current_show == null) current_show = show
                            else {
                                switch (cond_operator) {
                                    case 'AND':
                                        current_show = current_show && show
                                        break
                                    case 'OR':
                                        current_show = current_show || show
                                        break
                                }
                            }
                            if (show) {
                                current_order = list.items[key].order
                            }
                        }

                        item.is_visible = current_show
                        item.order = parseInt(current_order) + 0.5
                    }

                    // Alerts TODO
                    if (item.alerts_triggers) {
                        let alerts = item.alerts_triggers
                        let value = item.value

                        let oAlert = {}
                        let message = ''
                        let preview = ''
                        let issues = []
                        let classes = []

                        for (var i in alerts) {
                            let alert = alerts[i]
                            let operator = alert.operator ? alert.operator : '='
                            let val = alert.value

                            let show = false

                            if ((value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(item.type)) && item.is_visible) {
                                if (operator == 'true' && val == 'true') {
                                    show = true
                                } else {
                                    switch (item.type) {
                                        case FORMAT.EMAIL:
                                        case FORMAT.DOCUMENT:
                                        case FORMAT.CHECK:
                                        case FORMAT.YESNO:
                                            show = compare(parseInt(val) == 1 || (val == '' && operator == '!empty'), value == true || value == 'true', operator)
                                            break
                                        case FORMAT.YESNONC:
                                            show = compare(parseInt(val) || (val == '' && operator == '!empty'), value, operator)
                                            break
                                        case FORMAT.MULTIPLE:
                                            show = compare(val || (val == '' && operator == '!empty'), value, operator)
                                            break
                                        case FORMAT.TEXT:
                                        case FORMAT.TEXTAREA:
                                        case FORMAT.PHOTO:
                                        case FORMAT.DATE:
                                        case FORMAT.TIME:
                                        case FORMAT.DATETIME:
                                        case FORMAT.SIGNATURE:
                                            show = compare(val, value, operator)
                                            break
                                        case FORMAT.NUMBER:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (value != '-' && operator == '!empty')
                                            break
                                        case FORMAT.TEMPERATURE:
                                            var aux = value.toString().replace(',', '.')
                                            show = compare(parseFloat(aux), val, operator) || (value != '-' && operator == '!empty')
                                            break
                                        default:
                                            break
                                    }
                                }
                            }

                            if (show && alert.msg) {
                                if (message == '') message = alert.msg
                                else message = message + '<br/>' + alert.msg

                                if (preview == '') preview = alert.msg
                                else preview = preview + ', ' + alert.msg
                            }

                            if (show && alert.tpl) {
                                issues.push(alert.tpl)
                            }
                            if (show && alert.class) {
                                if (alert.class == 'alert') {
                                    alert.class = 'warning'
                                }
                                classes.push(alert.class)
                            }
                        }

                        oAlert = {
                            preview: preview,
                            message: message,
                            issues: issues,
                            class: classes
                        }

                        if (oAlert.message.length > 0 || oAlert.issues.length > 0) {
                            item.alerts = oAlert
                            Vue.set(item, 'alerts', oAlert)
                        }
                    }

                    // Scoring
                    // console.log('item - apply:', item.apply, list.total, list.completed)
                    if (((!item.parent_id && list.def.item.itemsExcluded.indexOf(item.type) == -1) || (item.parent_id && item.is_visible)) && !item.planned_to_date) {
                        switch (item.type) {
                            case FORMAT.YESNO: // SI/NO
                            case FORMAT.YESNONC: // SI/NO/NSNC
                            case FORMAT.CHECK: // CHECKS
                            case FORMAT.TEXT: // TEXTO
                            case FORMAT.PHOTO: // FOTO
                            case FORMAT.NUMBER: // NUMBER
                            case FORMAT.DATE: // DATE
                            case FORMAT.TEXTAREA: // TEXTAREA
                            case FORMAT.MULTIPLE: // MULTIPLE SELECT
                            case FORMAT.TIME: // TIME
                            case FORMAT.DATETIME: // DATETIME
                            case FORMAT.SIGNATURE: // SIGNATURE
                            case FORMAT.TEMPERATURE: // TEMPERATURE
                            case FORMAT.EMAIL: // EMAIL
                            case FORMAT.DOCUMENT: // DOCUMENT
                                if (item.complete_date == false && item.apply) list.unanswered++
                                else {
                                    // Required to be reviewed
                                    if (item.require_review) {
                                        list.completed += item.review ? 1 : 0.5
                                    } else list.completed += 1 // Not required to be reviewed
                                }

                                break

                            default:
                                break
                        }
                        if (![FORMAT.LINK, FORMAT.RESOURCE].includes(item.type)) {
                            list.total++
                        }
                    }
                })
                if (typeof id !== 'undefined') {
                    list.score = Math.round((parseFloat(list.completed) * 100) / parseInt(list.total))
                    // if (past_score != -1 && past_score < 100 && list.score >= 100) {
                    //     dispatch('complete', { checklist: list.id, complete_date: moment().unix(), values: [] })
                    //     list.complete_date = moment()
                    // } else if (past_score != -1 && past_score >= 100 && list.score < 100) {
                    //     dispatch('uncomplete', { checklist: list.id })
                    //     list.complete_date = false
                    // }
                }

                aItems[list.id] = { ...list }
            }
        })

        // ORDER
        Object.keys(aItems).map(function (item_id, i) {
            let list = JSON.parse(JSON.stringify(aItems[item_id]))
            var items = Object.keys(list.items)
                .sort((a, b) => {
                    return parseFloat(list.items[a].order) - parseFloat(list.items[b].order)
                })
                .reduce((prev, curr, i) => {
                    prev[i] = list.items[curr]
                    return prev
                }, {})

            let aux2 = {}
            for (var k in items) {
                aux2[items[k].item_id] = items[k]
            }

            list.items = aux2
            aItems[item_id] = list
        })

        // Vue.set(state, "items", { ...state.items });
        commit('setScore', { items: aItems })
    },
    loadAllByState({ state, commit, rootState }, params) {
        // console.log("TO DO VUE FILTER");
        // TODO RETURN ISSUES BY STATE (WITHOUT CALLING THE API, ONLY VUE)
        // if (fnCheckConnection()) {
        //   // If Internet, call API
        //   let url = "issues/" + params.state;
        //
        //   return api.get(url, {}).then(function(response) {
        //     _.forEach(_.values(response.data), function(value, key) {
        //       db_issues.setItem(value.id, JSON.stringify(value));
        //     });
        //     dispatch("format", { data: response.data });
        //     dispatch("calcScore");
        //   });
        // } else {
        //   var items = [];
        //   var count = 0;
        //   db_issues
        //     .iterate(function(value, key, iterationNumber) {
        //       items.push(JSON.parse(value));
        //       count++;
        //     })
        //     .then(function() {
        //       if (count > 0) {
        //         dispatch("format", { data: items });
        //         dispatch("calcScore");
        //       }
        //     });
        // }
    },
    loadCheckById(context, params) {
        return api.get('checklist/id/' + params.id, {}).then(function (response) {
            let result = {}

            Object.keys(response.data).map(function (tframe_id, i) {
                var tframe = response.data[tframe_id]

                Object.keys(tframe.templates).map(function (item_id, i) {
                    result = tframe.templates[item_id]

                    // Timezone set
                    result.business_date = result.business_date ? (moment.isMoment(result.business_date) ? result.business_date : moment(result.business_date * 1000)) : false
                    result.created_date = result.created_date ? (moment.isMoment(result.created_date) ? result.created_date : moment(result.created_date * 1000)) : false
                    result.complete_date = result.complete_date ? (moment.isMoment(result.complete_date) ? result.complete_date : moment(result.complete_date * 1000)) : false
                })
            })
            return result
        })
    },
    recoverValues(context, params) {
        // TODO APPEND
        return api.post('checklist/recover/' + params.check, {}).then(function (response) {
            if (response.status) {
                context.dispatch('format', { data: response.data })
                context.dispatch('calcScore', params.id)
            } else {
                router.go()
            }

            return response.data
        })
    },
    syncAction(context, params) {
        params.queued_date = moment().valueOf()
        params.id = moment().valueOf()
        let queue = 'addIssueToQueue'

        if (params.toolQueue) {
            const options = {
                tasks: 'addTaskToQueue',
                audit: 'addAuditToQueue',
                register: 'addRegisterToQueue'
            }

            queue = options[params.toolQueue]
        }

        return context.dispatch(`checklist/${queue}`, params, { root: true }).then(() => {
            return { status: true }
        })
    },
    sync(context, queue) {
        let checklists = {}
        for (let key in queue) {
            let queueElement = queue[key]
            if (typeof checklists[queueElement.checklist] === 'undefined') {
                checklists[queueElement.checklist] = {
                    items: [],
                    resume: { it: 0, is: 0, a: 0, n: 0 },
                    total: 0,
                    answered: 0,
                    score: 0
                }
            }

            checklists[queueElement.checklist].resume = queueElement.resume
            checklists[queueElement.checklist].total = queueElement.total
            checklists[queueElement.checklist].answered = queueElement.answered
            checklists[queueElement.checklist].score = queueElement.score
            checklists[queueElement.checklist].items.push(queueElement)
        }
        return api
            .post(`checklist/${state.type}/sync`, { checklists: JSON.stringify(checklists) })
            .then(function (response) {
                let response2 = JSON.parse(JSON.stringify(response.data)) ? JSON.parse(JSON.stringify(response.data)) : false
                let title = response2 ? response2.at(-1).customized_title : false
                let check = response2 ? response2.at(-1).checklist : false
                // if set, update custom title:
                if (title) {
                    context.commit('setTitle', {
                        check: check,
                        title: title
                    })
                }
                if (!response.status) {
                    throw new Error('API error') //Por si falla algo de email
                }
                return context
                    .dispatch('updateOfflineDatabase', response)
                    .then(() => {
                        return response
                    })
                    .catch(function (error) {
                        logError(error)
                        return false
                    })
            })
            .catch(function (error) {
                logError(error)
                return false
            })
    },
    async mergeOffline({ state, context, commit, dispatch, rootState }, params = {}) {
        await db_issues_queue.iterate(function (answer, checklistId) {
            if (typeof params.data[answer.checklist] !== 'undefined' && typeof params.data[answer.checklist].items[answer.task] !== 'undefined') {
                params.data[answer.checklist].items[answer.task].employee = answer.employee
                params.data[answer.checklist].items[answer.task].complete_date = answer.complete_date
                params.data[answer.checklist].items[answer.task].value = answer.value ? answer.value.toString() : ""
                params.data[answer.checklist].items[answer.task].requireSync = true
            }
        })
        return params
    },
    async clearOfflineQueue(context, params) {
        log('clearOfflineQueue', params)
        const data = params.data
        const action = params.action
        //['answer', 'reset', 'validate', 'unvalidate', 'complete', 'uncomplete']
        const mapActionsToRemove = {
            answer: ['answer', 'reset'],
            reset: ['answer', 'reset'],
            validate: ['validate', 'unvalidate'],
            unvalidate: ['validate', 'unvalidate']
        }

        const actionsToRemove = mapActionsToRemove[action]

        await db_issues_queue.iterate(async function (answer, key, iterationNumber) {
            const isTheSameChecklist = answer.checklist === data.checklist && answer.task === data.task
            if (actionsToRemove.includes(answer.action) && isTheSameChecklist) {
                await db_issues_queue.removeItem(key)
            }
        })
    },
    async updateOfflineDatabase(context, response) {
        if (response.data) {
            //if(response.data.type && response.data.type === 'complete') return

            const checklists = response.data
            checklists.forEach(async (checklist) => {
                //Actualizar el estado de sincronización de los registros
                await context.dispatch('updateStateOfSync', checklist)

                log('>> updateOfflineDatabase', checklist)
                // Actualizar el id de sincronización de los registros
                if (checklist && checklist.checklist_temporal && checklist.checklist) {
                    const register = await db_issues.getItem(checklist.checklist_temporal)
                    if (register) {
                        register.id = checklist.checklist
                        register.issue_id = checklist.issue_id
                        await db_issues.removeItem(checklist.checklist_temporal)
                        await db_issues.setItem(checklist.checklist, register)
                    }
                    //change the key of the item and id
                    const copy = { ...context.state.items }
                    copy[checklist.checklist_temporal].id = checklist.checklist
                    copy[checklist.checklist_temporal].issue_id = checklist.issue_id
                    copy[checklist.checklist_temporal].temporal_id = checklist.checklist_temporal
                    const updateItem = { [checklist.checklist]: copy[checklist.checklist_temporal] }
                    delete copy[checklist.checklist_temporal]

                    const allItems = { ...updateItem, ...copy }
                    context.state.items = { ...copy }
                    await context.dispatch('format', { data: allItems })

                    //update allItems
                    const newIssueItem = context.state.items[checklist.checklist]
                    delete context.state.allItems[newIssueItem.status][checklist.checklist_temporal]
                    context.state.allItems[newIssueItem.status] = { [checklist.checklist]: newIssueItem, ...context.state.allItems[newIssueItem.status] }
                }
            })
        }
    },

    async updateStateOfSync(context, params) {
        log('>> updateStateOfSync', params)
        // Actualizar el estado de sincronización de los registros
        if (params && params.checklist_temporal) {
            const register = await db_issues.getItem(params.checklist_temporal)
            if (register) {
                register.requireSync = false
                await db_issues.setItem(params.checklist_temporal, register)
            }
            state.items[params.checklist_temporal].requireSync = false
        }
    }
}

// mutations
const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    setNumberOpenIssues(state, payload) {
        Vue.set(state, 'numberOpenIssues', payload)
    },
    setCompleteDateItem(state, payload) {
        var issueObject = { ...state.allItems[payload.check][payload.item] }
        let allItems = { ...state.allItems }
        if (!allItems[payload.new_status]) {
            allItems[payload.new_status] = {}
        }
        issueObject.complete_date = payload.complete_date
        allItems[payload.new_status][payload.item] = { ...issueObject }
        Vue.set(state, 'allItems', { ...allItems })

        allItems = { ...state.allItems }
        delete allItems[payload.check][payload.item]
        console.log(payload.item)
        Vue.set(state, 'allItems', { ...allItems })
        issueObject = { ...state.items[payload.item] }
        issueObject.complete_date = payload.complete_date

        Vue.set(state.items, payload.item, { ...issueObject })
    },
    resetAllItems(state) {
        state.allItems = {}
        Vue.set(state, 'allItems', {})
    },
    setCategories(state, payload) {
        var categories = state.categories
        categories = payload
        Vue.set(state, 'categories', { ...categories })
    },
    setDate(state, date) {
        Vue.set(state, 'date', date)
    },
    setTemplates(state, payload) {
        var templates = {}

        if (payload.data != undefined) {
            Object.keys(payload.data).map(function (tpl_id, i) {
                var tpl = { ...payload.data[tpl_id] }

                tpl.last_update = tpl.last_update ? (moment.isMoment(tpl.last_update) ? tpl.last_update : moment(tpl.last_update * 1000)) : false

                templates[tpl.id] = tpl
            })
        }

        Vue.set(state, 'templates', { ...templates })
    },
    setChecklistProp(state, payload) {
        var check = payload.check
        var prop = payload.prop
        var value = payload.value

        state.items[check][prop] = value
        Vue.set(state, 'items', { ...state.items })
    },
    setAnswerProp(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var prop = payload.prop
        var value = payload.value

        state.items[check].items[answer][prop] = value

        Vue.set(state, 'items', { ...state.items })
    },
    setComment(state, payload) {
        let check = payload.check
        let answer = payload.answer
        let aux = state.items[check].items[answer]

        aux.message = payload.message
        aux.image = payload.image ? payload.image : false
        aux.requireSync = payload.requireSync

        Vue.set(state.items[check].items, answer, { ...aux })
        Vue.set(state, 'items', { ...state.items })
    },
    setAnswer(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var value = payload.value
        var date = payload.date

        state.items[check].items[answer].value = value
        state.items[check].items[answer].requireSync = payload.requireSync

        if (date) {
            var item = state.items[check].items[answer]
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) state.items[check].items[answer].employee = emp

        Vue.set(state, 'items', { ...state.items })
    },
    setTitle(state, payload) {
        state.items[payload.check].title = payload.title
        Vue.set(state, 'items', { ...state.items })
    },
    setApply(state, payload) {
        const check = payload.check
        const answer = payload.answer
        const emp = payload.emp
        const apply = payload.apply
        const date = payload.date

        state.items[check].items[answer].apply = apply

        if (date) {
            state.items[check].items[answer].complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
            state.items[check].items[answer].value = null
        } else {
            state.items[check].items[answer].complete_date = false
        }

        if (emp) {
            state.items[check].items[answer].employee = emp
        }

        Vue.set(state, 'items', { ...state.items })
    },
    setReset(state, payload) {
        var check = payload.check
        var answer = payload.answer

        state.items[check].items[answer].complete_date = false
        state.items[check].items[answer].employee = false
        state.items[check].items[answer].review = false
        state.items[check].items[answer].value = null

        Vue.set(state, 'items', { ...state.items })
    },
    setValidation(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var date = payload.date

        state.items[check].items[answer].review = {}
        if (date) {
            var item = state.items[check].items[answer].review
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) {
            var employee = store.getters['employee/getEmployee'](emp)
            state.items[check].items[answer].review.employee = employee
        }
        state.items[check].items[answer].review.value = 1

        Vue.set(state, 'items', { ...state.items })
    },
    setUnvalidation(state, payload) {
        var check = payload.check
        var answer = payload.answer

        state.items[check].items[answer].review = false

        Vue.set(state, 'items', { ...state.items })
    },
    resetStats(state, payload) {
        state.stats.alerts = {}
        state.stats.activity = {}
    },
    setStats(state, payload) {
        var stats = state.stats
        if (typeof stats[payload.where] === 'undefined') stats[payload.where] = {}
        stats[payload.where][payload.key_name] = payload.values
        state.stats = stats
    },
    setFormat(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
    },
    setAllItems(state, payload) {
        let items = { ...state.allItems }
        if (payload.type) {
            items[payload.type] = payload.items
        } else {
            for (var key in payload.items) {
                const item = payload.items[key]
                if (!items[item.status]) {
                    items[item.status] = {}
                }
                items[item.status][key] = item
            }
        }

        Vue.set(state, 'allItems', { ...items })
    },
    setScore(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
    },
    filterByState(state, payload) {
        let aItems = state.items

        Object.keys(aItems).map(function (item_id, i) {
            var item = aItems[item_id]

            if (payload.states.includes(item.status) || !payload.states.length) {
                item.is_visible = true
            } else {
                item.is_visible = false
            }
        })

        state.items = aItems
        Vue.set(state, 'items', { ...aItems })
    },
    setRequireSync(state, payload) {
        let check = payload.check
        let answer = payload.answer
        let apiId = payload.apiId

        console.log('setRequireSync', check, answer, apiId)
        check = state.items[check] ? check : state.items[apiId] ? apiId : false
        console.log('setRequireSync 2', check)
        if (answer && typeof state.items[check] !== 'undefined' && typeof state.items[check].items[answer] !== 'undefined') {
            const copyOfItem = { ...state.items }
            copyOfItem[check].items[answer].requireSync = payload.requireSync
            copyOfItem[check].items[answer] = { ...copyOfItem[check].items[answer] }
            Vue.set(state, 'items', { ...copyOfItem })
            state.items = { ...copyOfItem }
        }
    }
}

function loadOffline(context) {
    let templates = []
    let count = 0

    return db_templates
        .iterate(function (value, key, iterationNumber) {
            value = typeof value === 'string' ? JSON.parse(value) : value
            if (value.type == state.type) {
                templates.push(value)
                count++
            }
        })
        .then(function () {
            if (count > 0) {
                context.commit('setTemplates', { data: templates })
            }
        })
}

function loadDetailOffline(context, id) {
    return db_issues.getItem(id).then(function (value) {
        if (value) {
            log('loadDetailOffline', value, id)

            context.dispatch('format', { data: { [id]: { ...value } } })
            context.dispatch('calcScore', id)
        }
    })
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
