// Import
import i18n from '@/i18n'
import Vue from 'vue'
import store from '@/store'
import { Api } from '@/core/api.js'
import { FORMAT } from '@/constants'
import localforage from 'localforage'
import { db_issues} from '@/database'

const api = new Api()

const db_templates = localforage.createInstance({
    name: 'alexdb',
    storeName: 'templates'
})

const db_audits_queue = localforage.createInstance({
    name: 'alexdb',
    storeName: 'audits_queue'
})

const db_audits = localforage.createInstance({
    name: 'alexdb',
    storeName: 'audits'
})

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        stats: {
            alerts: {},
            activity: {}
        },
        items: {},
        supervisor: false,
        templates: {},
        headers: {},
        type: 2,
        number_items: 0,
        number_page: 1,
        config: {
            date_format: 'YYYY-MM-DD',
            hour_format: 'HH:mm',
            datetime_format: 'YYYY-MM-DD HH:mm',
            has_score: true,
            has_state: false,
            has_timing: false,
            has_mandatory: false,
            is_autosave: true,
            can_create: true,
            item_search: false,
            has_calendar: false,
            temporal_view: false,
            close_when_completed: false,
            auth_users: {
                pin: 2,
                review: 2,
                task: 1
            },
            can_edit: {
                title: false,
                state: false,
                inPast: false,
                inTime: true,
                inFuture: true
            },
            item: {
                itemsTitle: [FORMAT.TITLE, FORMAT.SUBTITLE],
                itemsImage: [FORMAT.PHOTO, FORMAT.SIGNATURE],
                itemsInfo: [FORMAT.DOCUMENT, FORMAT.MESSAGE],
                itemsExcluded: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.DOCUMENT, FORMAT.MESSAGE, FORMAT.LINK, FORMAT.RESOURCE],
                itemsVisibles: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.MESSAGE],
                can_pospone: false,
                rate: true, // Esto es por si van a tener puntuaciones en funcion de lo que se responda
                can_review: false,
                has_periodicity: false,
                has_score: true
            }
        }
    }
}

const state = getDefaultState()

const getters = {
    getAll: (state, getters, rootState) => {
        return state.items
    },
    getItemsWithTemporalId(state, getters, rootState){
        return Object.values(state.items).filter((item) => item.temporal_id)
    },
    getById: (state, getters, rootState) => (id) => {
        return id ? state.items[id] : false
    },
    getConfig: (state, getters, rootState) => {
        return { ...state.config }
    },
    getTemplates: (state, getters, rootState) => {
        return state.templates
    },
    getTemplateById: (state, getters, rootState) => (id) => {
        return state.templates[id] ? state.templates[id] : {}
    },
    getSubtasks: (state, getters, rootState) => (check, task) => {
        let tasks = check ? state.items[check] : {}
        let subtask = []

        if (tasks.sections) {
            Object.keys(tasks.sections).map(function (sec_id, i) {
                var section = tasks.sections[sec_id]

                Object.keys(section.items).map(function (item_id, i) {
                    var item = section.items[item_id]

                    if (item.parent_id == task) {
                        subtask.push(item.item_id)
                    }
                })
            })
        }

        return subtask
    },
    getNumberItems: (state, getters) => {
        return state.number_items
    },
    getNumberPage: (state, getters) => {
        return state.number_page
    },
    getAllStats: (state, getters, rootState) => {
        return state.stats
    },
    getStats: (state, getters, rootState) => (wich) => {
        return state.stats[wich]
    },
    getSectionFromItem: (state, getters, rootState) => (check, task) => {
        var checklist = state.items[check] ? state.items[check] : {}
        var result = false

        Object.keys(checklist.sections).map(function (sec_id, i) {
            var section = checklist.sections[sec_id]

            Object.keys(section.items).map(function (item_id, o) {
                var item = section.items[item_id]
                if (item.item_id == task) {
                    result = section.section_id
                }
            })
        })

        return result
    },
    getItems: (state, getters, rootState) => {
        return state.items
    },
    getHeaders: (state, getters, rootState) => {
        return state.headers
    },
    getSupervisor: (state, getters, rootState) => {
        return state.supervisor ? state.supervisor : false
    },
    getItemsBySection: (state, getters, rootState) => (check, section) => {
        var newSectionFound = false
        var startLooking = false
        var resultItems = {}
        var items = check ? Object.values({ ...state.items[check].items }) : []

        // CLEAN OF SUBITEMS
        items = items.filter((obj) => {
            return !obj.parent_id
        })

        // FIRST SORT BY ORDER AND ADD ITEMS TO RESULT
        items
            .sort(function (l, r) {
                return l.order - r.order
            })
            .forEach(function (item) {
                if (item.type != 8 && startLooking && !newSectionFound) {
                    resultItems[item.item_id] = item
                } else {
                    if (!startLooking && item.item_id == section) {
                        startLooking = true
                    } else if (startLooking) {
                        newSectionFound = true
                        startLooking = false
                    }
                }
            })

        return resultItems
    }
}

const actions = {
    resetState({ commit }) {
        commit('resetState')
    },
    async addIssue(context, params) {
        context.dispatch('calcScore', params.checklist_ref)

        var oData = { template: params.tpl_id }
        if (params.checklist_ref) {
            oData.checklist_ref = params.checklist_ref

            let check = context.getters.getById(params.checklist_ref)
            let total = check.total_score
            let answered = check.completed
            let score = check.score
            let resume = { it: 0, is: 0, a: 0, n: 0 }

            for (let section_id in check.sections) {
                let section = check.sections[section_id]

                for (let item_id in section.items) {
                    let item = section.items[item_id]

                    if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                        if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                        if (item.message) resume.n++ // if item has notes
                        if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                        if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                    }
                }
            }

            oData.total = total
            oData.answered = answered
            oData.score = score
            oData.resume = JSON.stringify(resume)
        }

        if (params.item_ref) {
            oData.item_ref = params.item_ref
        }

        if(params.isOfflineCreated){
            oData.isOfflineCreated = true
        }

        if(params.toolQueue) {
            oData.toolQueue = params.toolQueue
        }

        const canWorkOffline = context.rootGetters.getOffline

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api.post('checklist/add', oData).then(function (response) {
                var items = { ...state.items }
                var reference = { checklist: Object.values(response.data)[0].id, type: Object.values(response.data)[0].type, status: Object.values(response.data)[0].status }

                let audit = { ...items[params.checklist_ref] }
                for (var s in audit.sections) {
                    let section = audit.sections[s]
                    if (typeof section.items[params.item_ref] !== 'undefined') {
                        let item = { ...section.items[params.item_ref] }
                        item.reference = reference
                        items[params.checklist_ref].sections[s].items[params.item_ref] = item
                    }
                }

                Vue.set(state, 'items', items)
                return response.data
            })
        }else{
            if(canWorkOffline){
                log('> audit addIssue', oData)
                const issue = await store.dispatch('issues/add', oData)
                log(">> audit-ISSUE ITEM-ID", issue)
                const reference = { 
                    checklist :issue.id,
                    type:issue.type,
                    status:issue.status,
                }
                var items = { ...state.items }
                let audit = { ...items[params.checklist_ref] }
                for (var s in audit.sections) {
                    let section = audit.sections[s]
                    if (typeof section.items[params.item_ref] !== 'undefined') {
                        let item = { ...section.items[params.item_ref] }
                        item.reference = reference
                        items[params.checklist_ref].sections[s].items[params.item_ref] = item
                    }
                }
                
                db_audits.getItem(params.checklist_ref).then((audit) => {
                    if(audit){
                        for (var s in audit.sections) {
                            let section = audit.sections[s]
                            if (typeof section.items[params.item_ref] !== 'undefined') {
                                let item = { ...section.items[params.item_ref] }
                                item.reference = reference
                                audit.sections[s].items[params.item_ref] = item
                            }
                        }
                        db_audits.setItem(params.checklist_ref, audit)
                    }
                })

                return issue
            }
        }
    },
    comment(context, params) {
        const canWorkOffline = context.rootGetters.getOffline

        context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let total = check.total_score
        let answered = check.completed
        let score = check.score
        let resume = { it: 0, is: 0, a: 0, n: 1 }

        for (let section_id in check.sections) {
            let section = check.sections[section_id]

            for (let item_id in section.items) {
                let item = section.items[item_id]

                if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                    if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }
        }

        var sended = {
            action: 'comment',
            checklist: params.check,
            task: params.answer,
            message: params.message,
            total: total,
            answered: answered,
            score: score,
            resume: JSON.stringify(resume),
            requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
        }

        if (typeof params.file !== 'undefined') sended.file = params.file

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/comment', sended)
                .then(function (response) {
                    if (!response.status && context.rootGetters.getOffline) {
                        return context.dispatch('syncAction', sended)
                    } else if (!response.status) {
                        let error = {
                            check: params.check,
                            answer: params.answer,
                            getters: context.getters
                        }
                        if (typeof params.file !== 'undefined') error.image = null
                        else error.message = ''

                        context.commit('setComment', error)
                        return { status: false }
                    } else {
                        let returned = {
                            check: params.check,
                            answer: params.answer,
                            message: response.data.message,
                            getters: context.getters
                        }
                        if (typeof response.data.image !== 'undefined') {
                            returned.image = response.data.image
                        }
                        return context.commit('setComment', returned)
                    }
                })
                .catch((error) => {
                    if (context.rootGetters.getOffline) {
                        return context.dispatch('syncAction', sended)
                    } else {
                        let error = {
                            check: params.check,
                            answer: params.answer,
                            getters: context.getters
                        }
                        if (typeof params.file !== 'undefined') error.image = null
                        else error.message = ''

                        context.commit('setComment', error)
                        return { status: false }
                    }
                })
        } else if (context.rootGetters.getOffline) {
            let offlineMessage = {
                check: params.check,
                answer: params.answer,
                message: params.message,
                requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline,
                getters: context.getters
            }
            if (typeof params.file !== 'undefined') offlineMessage.image = params.file
            return context
                .dispatch('syncAction', sended)
                .then((response) => {
                    return context.commit('setComment', offlineMessage)
                })
                .catch(() => {
                    return context.commit('setComment', offlineMessage)
                })
        } else {
            var error = {
                check: params.check,
                answer: params.answer,
                getters: context.getters
            }
            if (typeof params.file !== 'undefined') error.image = null
            else error.message = ''

            context.commit('setComment', error)
            return { status: false }
        }
    },
    loadItemsPagination(context, params) {
        params.type = state.type
        if (!fnCheckConnection()) {
            return loadItemsOffline(context.commit, context.dispatch)
        }
        return api.post('checklist/resume/' + params.id, params).then(function (response) {
            context.dispatch('format', { data: response.data.values })
            context.dispatch('calcScore')
            context.commit('setResume', { data: response.data.schema })
            context.commit('setNumberItems', response.data.number_items)
            context.commit('setNumberPage', params.number_pages)
        })
        .catch((error) => {
            return loadItemsOffline(context.commit, context.dispatch)
        })
    },

    defineFinalScore(context, params) {
        const { finalScores, auditScore } = params

        if (!finalScores) {
            return null
        }

        for (let i = 0; i < finalScores.length; i++) {
            const finalScoreData = {
                name: finalScores[i].n,
                color: finalScores[i].c
            }
            const finalScoreOperator = finalScores[i].p.cond
            const finalScoreValue = finalScores[i].p.v

            if (finalScoreOperator === '<>' && auditScore >= finalScoreValue[0] && auditScore <= finalScoreValue[1]) {
                return finalScoreData
            }

            if (finalScoreOperator === '=' && auditScore === parseInt(finalScoreValue)) {
                return finalScoreData
            }

            if (finalScoreOperator === '>' && auditScore > parseInt(finalScoreValue)) {
                return finalScoreData
            }

            if (finalScoreOperator === '>=' && auditScore >= parseInt(finalScoreValue)) {
                return finalScoreData
            }

            if (finalScoreOperator === '<' && auditScore < parseInt(finalScoreValue)) {
                return finalScoreData
            }

            if (finalScoreOperator === '<=' && auditScore <= parseInt(finalScoreValue)) {
                return finalScoreData
            }
        }
    },

    // COMPLETE ACTION
    async complete(context, params) {
        await context.dispatch('calcScore', params.check)

        var supervisor = context.getters['getSupervisor']
        supervisor = supervisor || params.emp

        // AUDIT
        let check = context.getters.getById(params.check)

        const defineFinalScoreParams = {
            auditScore: check.score,
            finalScores: params.finalScores
        }

        const finalAuditScore = await context.dispatch('defineFinalScore', defineFinalScoreParams)

        let resume = {
            it: 0,
            is: 0,
            a: 0,
            n: 0,
            ev: finalAuditScore ? { n: finalAuditScore.name, c: finalAuditScore.color } : null
        }

        for (let section_id in check.sections) {
            let section = check.sections[section_id]

            for (let item_id in section.items) {
                let item = section.items[item_id]

                if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                    if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }
        }
        const canWorkOffline = context.rootGetters.getOffline
        const paramsApi = {
            checklist: params.check,
            employee: supervisor,
            complete_date: params.date,
            resume: JSON.stringify(resume)
            // type: context.state.type, 
        }
        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/complete', paramsApi)
                .then(function (response) {
                    check.complete_date = moment()

                    Object.keys(state.templates).map(function (tpl_id, i) {
                        var tpl = state.templates[tpl_id]

                        if (tpl.id == check.template_id) {
                            tpl.last_audit = check
                            tpl.last_update = moment()
                            tpl.num_progress--
                        }
                    })

                    // TODO
                    params.values = JSON.parse(params.values)
                    Object.keys(params.values).map(function (id, i) {
                        var val = params.values[id]
                        context.commit('setAnswer', {
                            check: params.check,
                            answer: val.item_id,
                            emp: supervisor,
                            date: params.date,
                            value: val.value,
                            getters: context.getters
                        })
                    })

                    context.dispatch('calcScore', params.check)
                    return response
                })
            } else {
                if (canWorkOffline) {
                    paramsApi.action = 'complete'
                    context.dispatch('syncAction',paramsApi).catch(async () => {
                        log('>> Audit completed')
                        check.complete_date = moment()
                        Object.keys(state.templates).map(function (tpl_id, i) {
                            var tpl = state.templates[tpl_id]
                            if (tpl.id == check.template_id) {
                                tpl.last_audit = check
                                tpl.last_update = moment()
                                tpl.num_progress--
                            }
                        })
                        log('>> Audit completed 2')

                        // TODO
                        params.values = JSON.parse(params.values)
                        Object.keys(params.values).map(function (id, i) {
                            var val = params.values[id]
                            context.commit('setAnswer', {
                                check: params.check,
                                answer: val.item_id,
                                emp: supervisor,
                                date: params.date,
                                value: val.value,
                                getters: context.getters
                            })
                        })
                        log('>> Audit completed 3')

                        context.dispatch('calcScore', params.check)
                        log('>> Audit completed 4')

                        check.resume = resume

                        await db_audits.setItem(params.check, JSON.parse(JSON.stringify(check)))
                        const templates = JSON.parse(JSON.stringify(state.templates))
                        await db_templates.setItem(check.template_id, JSON.stringify(templates[check.template_id]))

                        return { status: true, msg: 'Audit completed' }
                    })
                }
            }
    },
    loadById(context, params) {
        if(!fnCheckConnection()){
            return loadDetailOffline(context, params.id)
        }

        if (params.noRefresh && fnCheckConnection() && context.rootGetters.getFastConnection) {
            const item = context.getters.getById(params.id)
            context.dispatch('format', { data: item })
            context.dispatch('calcScore', params.id)
            return
        }

        return api.get('checklist/id/' + params.id, {}).then(function (response) {
            return context.dispatch('mergeOffline', response).then(function (response2) {
                context.dispatch('format', { data: response2.data })
                context.dispatch('calcScore', params.id)
            })
        })
    },
    loadTemplates(context, params) {
        if (!fnCheckConnection()) {
            return loadTemplatesOffline(context)
        }

        return api
            .get('checklist/templates/' + state.type, {})
            .then(function (response) {
                context.commit('setTemplates', { data: response.data })

                _.forEach(_.values(response.data), async function (value, key) {
                    await db_templates.setItem(value.id, JSON.stringify(value))
                })

                var lastAudits = {}

                Object.keys(response.data).map(function (tpl_id, i) {
                    var tpl = { ...response.data[tpl_id] }
                    if (tpl.last_audit) {
                        lastAudits[tpl.last_audit.id] = tpl.last_audit
                    }
                })

                context.dispatch('format', { data: lastAudits })
                context.dispatch('calcScore')
            })
            .catch((error) => {
                logError(error)
                return loadTemplatesOffline(context)
            })
    },
    reload(context, params) {
        context.dispatch('format', { data: checklist })
        context.dispatch('calcScore')
    },
    async answer(context, params) {
        let value = params.value
        let subtask = context.getters.getSubtasks(params.check, params.answer)
        let supervisor = context.getters['getSupervisor']
        supervisor = supervisor || params.emp

        const canWorkOffline = context.rootGetters.getOffline

        // In case of image or signature type, JSON-parse the value
        if (state.config.item.itemsImage.includes(params.answer_type)) {
            value = JSON.stringify(value)
        }

        context.commit('setAnswer', {
            check: params.check,
            answer: params.answer,
            emp: supervisor,
            date: params.date,
            value: params.value,
            getters: context.getters,
            requireSync: !(fnCheckConnection() && context.rootGetters.getFastConnection) && canWorkOffline
        })

        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item, getters: context.getters })
            })
        }

        await context.dispatch('calcScore', params.check)

        let check = context.getters.getById(params.check)
        let total = check.total_score
        let answered = check.completed
        let score = check.score
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let section_id in check.sections) {
            let section = check.sections[section_id]

            for (let item_id in section.items) {
                let item = section.items[item_id]

                if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                    if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }
        }

        const paramsApi = {
            checklist: params.check,
            task: params.answer,
            employee: supervisor,
            complete_date: params.date,
            value: value,
            total: total,
            answered: answered,
            score: score,
            resume: JSON.stringify(resume),
            employee_ref: supervisor,
            type: params.answer_type
        }
        if (canWorkOffline) {
            await context.dispatch('clearOfflineQueue', { data: paramsApi, action: 'answer' })
        }

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/answer', paramsApi)
                .then(function (response) {
                    if (!response.status) {
                        context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
                        context.dispatch('calcScore', params.check)
                    } else {
                        let value = response.data.value
                        // In case of image or signature type, JSON-parse the value
                        if (state.config.item.itemsImage.includes(params.answer_type)) {
                            value = splitCommasBase64(value)
                            // answer
                            context.commit('setAnswer', {
                                check: params.check,
                                answer: params.answer,
                                emp: supervisor,
                                date: params.date,
                                value: value,
                                getters: context.getters
                            })
                        }
                    }
                    return response
                })
                .catch((error) => {
                    if (canWorkOffline) {
                        logError(error)
                        paramsApi.action = 'answer'
                        return context
                            .dispatch('syncAction', paramsApi)
                            .then(() => {
                                return context.dispatch('calcScore', params.check).then(function () {
                                    return { status: false }
                                })
                            })
                            .catch(() => {
                                return context.dispatch('calcScore', params.check).then(function () {
                                    return { status: false }
                                })
                            })
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
                        return context.dispatch('calcScore', params.check).then(function () {
                            return { status: false }
                        })
                    }
                })
        } else {
            if (canWorkOffline) {
                paramsApi.action = 'answer'
                paramsApi.value = JSON.stringify(params.value)
                return context
                    .dispatch('syncAction', paramsApi)
                    .then(() => {
                        db_audits.setItem(params.check, JSON.parse(JSON.stringify(check)))
                        return context.dispatch('calcScore', params.check).then(function () {
                            return { status: false }
                        })
                    })
                    .catch(() => {
                        db_audits.setItem(params.check, JSON.parse(JSON.stringify(check)))
                        return context.dispatch('calcScore', params.check).then(function () {
                            return { status: false }
                        })
                    })
            } else {
                context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
                return context.dispatch('calcScore', params.check).then(function () {
                    return { status: false }
                })
            }
        }
    },
    async apply(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)
        let supervisor = context.getters['getSupervisor']
        supervisor = supervisor || params.emp
        let check = context.getters.getById(params.check)
        let items = context.getters.getItemsBySection(params.check, params.answer)

        context.commit('setApply', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.date == 0 ? params.date : false,
            apply: params.apply,
            getters: context.getters
        })

        if (check.sections[params.answer]) {
            Object.keys(items).map(function (item_id, i) {
                let it = items[item_id]
                context.commit('setApply', { check: params.check, answer: it.item_id, date: params.apply == 0 ? params.date : false, apply: params.apply, getters: context.getters })
            })
        }

        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                let item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item, getters: context.getters })
            })
        }

        await context.dispatch('calcScore', params.check)

        check = context.getters.getById(params.check)
        let total = check.total_score
        let answered = check.completed
        let score = check.score
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let section_id in check.sections) {
            let section = check.sections[section_id]

            for (let item_id in section.items) {
                let item = section.items[item_id]

                if (item.is_visible) {
                    if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }
        }

        let paramsApi = {
            checklist: params.check,
            task: params.answer,
            employee: supervisor,
            complete_date: params.date,
            apply: params.apply,
            total: total,
            answered: answered,
            score: score,
            resume: JSON.stringify(resume)
        }

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api
                .post('checklist/task/apply', paramsApi)
                .then(function (response) {
                    if (!response.status && context.rootGetters.getOffline) {
                        paramsApi.action = 'apply'
                        return context.dispatch('syncAction', paramsApi)
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
                    }
                })
                .catch(() => {
                    if (context.rootGetters.getOffline) {
                        paramsApi.action = 'apply'
                        return context.dispatch('syncAction', paramsApi)
                    } else {
                        context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
                    }
                })
        } else {
            if (context.rootGetters.getOffline) {
                paramsApi.action = 'apply'
                return context.dispatch('syncAction', paramsApi)
            } else {
                context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
            }
        }
    },
    async applyrecurrent(context, params) {
        let subtask = context.getters.getSubtasks(params.check, params.answer)
        let supervisor = context.getters['getSupervisor']
        supervisor = supervisor || params.emp

        context.commit('setApply', {
            check: params.check,
            answer: params.answer,
            emp: params.emp,
            date: params.date == 0 ? params.date : false,
            apply: params.apply,
            getters: context.getters
        })

        let check = context.getters.getById(params.check)
        if (check.sections[params.answer]) {
            let items = context.getters.getItemsBySection(params.check, params.answer)

            Object.keys(items).map(function (item_id, i) {
                var it = items[item_id]
                context.commit('setApply', { check: params.check, answer: it.item_id, date: params.apply == 0 ? params.date : false, apply: params.apply, getters: context.getters })
            })
        }

        await context.dispatch('calcScore', params.check)

        if (subtask.length > 0) {
            Object.keys(subtask).map(function (item_id, i) {
                var item = subtask[item_id]
                context.commit('setReset', { check: params.check, answer: item, getters: context.getters })
            })
        }

        check = context.getters.getById(params.check)
        let total = check.total_score
        let answered = check.completed
        let score = check.score
        let resume = { it: 0, is: 0, a: 0, n: 0 }

        for (let section_id in check.sections) {
            let section = check.sections[section_id]

            for (let item_id in section.items) {
                let item = section.items[item_id]

                if (item.is_visible) {
                    if (!item.complete_date && item.apply) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }
        }

        return api
            .post('checklist/task/applyrecurrent', {
                checklist: params.check,
                task: params.answer,
                employee: supervisor,
                complete_date: params.date,
                apply: params.apply,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            })
            .then(function (response) {
                if (!response.status) {
                    context.commit('setReset', { check: params.check, answer: params.answer, getters: context.getters })
                }
            })
    },
    async reset(context, params) {
        const canWorkOffline = context.rootGetters.getOffline

        if (context.rootGetters.getOffline || fnCheckConnection()) {
            let subtask = context.getters.getSubtasks(params.check, params.answer)

            context.commit('setReset', {
                check: params.check,
                answer: params.answer,
                getters: context.getters
            })
            await context.dispatch('calcScore', params.check)

            if (subtask.length > 0) {
                Object.keys(subtask).map(function (item_id, i) {
                    var item = subtask[item_id]
                    context.commit('setReset', { check: params.check, answer: item, getters: context.getters })
                })
            }

            let check = context.getters.getById(params.check)
            let total = check.total
            let answered = check.completed
            let score = ((parseFloat(answered) * 100) / parseFloat(total)).toFixed(2)
            let resume = { it: 0, is: 0, a: 0, n: 0 }

            for (let id in check.items) {
                let item = check.items[id]

                if (item.is_visible && !state.config.item.itemsExcluded.includes(item.type)) {
                    if (!item.complete_date && item.apply && !item.planned_to_date) resume.it++ // if item does not have completed_date
                    if (item.message) resume.n++ // if item has notes
                    if (item.alerts && item.alerts.issues.length > 0) resume.is++ // if item has alerts and has issue
                    if (item.alerts && item.alerts.issues.length == 0) resume.a++ // if item has alerts and does not have issue
                }
            }

            let paramsApi = {
                checklist: params.check,
                task: params.answer,
                total: total,
                answered: answered,
                score: score,
                resume: JSON.stringify(resume)
            }

            if (canWorkOffline) {
                await context.dispatch('clearOfflineQueue', { data: paramsApi, action: 'reset' })
            }

            if (fnCheckConnection() && context.rootGetters.getFastConnection) {
                return api
                    .post('checklist/task/reset', paramsApi)
                    .then(function (response) {
                        if (!response.status) {
                            paramsApi.action = 'reset'
                            return context
                                .dispatch('syncAction', paramsApi)
                                .then(function () {
                                    return { status: true }
                                })
                                .catch(() => {
                                    return { status: true }
                                })
                        } else {
                            return { status: true }
                        }
                    })
                    .catch(() => {
                        paramsApi.action = 'reset'
                        return context
                            .dispatch('syncAction', paramsApi)
                            .then(function () {
                                return { status: true }
                            })
                            .catch(() => {
                                return { status: true }
                            })
                    })
            } else {
                paramsApi.action = 'reset'
                return context
                    .dispatch('syncAction', paramsApi)
                    .then(function () {
                        return { status: true }
                    })
                    .catch(() => {
                        return { status: true }
                    })
            }
        } else {
            return { status: false }
        }
    },
    async add(context, params) {
        var type = context.state.type
        var oData = { template: params.tpl_id }
        if (params.emp_ref) {
            oData.employee_ref = params.emp_ref
        }
        if (params.checklist_ref) {
            oData.checklist_ref = params.checklist_ref
        }
        if (params.item_ref) {
            oData.item_ref = params.item_ref
        }
        const canWorkOffline = context.rootGetters.getOffline

        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            return api.post('checklist/add', oData).then(function (response) {
                context.dispatch('format', { data: response.data })
                context.dispatch('calcScore', Object.keys(response.data)[0])

                Object.keys(state.templates).map(function (tpl_id, i) {
                    var tpl = state.templates[tpl_id]

                    if (tpl.id == params.tpl_id) {
                        tpl.num_progress++
                    }
                })
                log('>> ADD RESPONSE ', response.data[Object.keys(response.data)[0]])
                return response.data[Object.keys(response.data)[0]]
            })
        } else {
            if(canWorkOffline){
                const sections = JSON.parse(JSON.stringify(context.state.templates[params.tpl_id].sections)) //{ id: { id: 'xx', items: { xx: {item_id:xx , value: null}, xxx:{item_id:xx , value: null} } }
                const items = {...context.state.templates[params.tpl_id].items}
                //Add to items review= 0, complete_date = false, value = null of each section
                Object.keys(sections).map((section_id) => {
                    const section = sections[section_id]
                    Object.keys(section.items).map((item_id) => {
                        const item = section.items[item_id]
                        item.item_id = item_id
                        item.apply = item.apply ? item.apply : 1
                        item.review = false
                        item.complete_date = false
                        item.value = null
                        item.score = 0
                        item.alerts = JSON.parse(JSON.stringify(item.alerts))
                        item.alerts_triggers = JSON.parse(JSON.stringify(item.alerts))
                        if(parseInt(item.type) === FORMAT.MULTIPLE && item.config && item.config.values){
                            item.config.values = item.config.values.map((option) => {
                                if(option.c){
                                    return { v: option.v, c: option.c }
                                }else if(option.v && !option.c){
                                    return { v: option.v, c: false }
                                }
                                else{
                                    return { v: option, c: false }
                                }
                            })
                        }
                        item.config = item.config ? JSON.stringify(item.config) : false
                        if(item.conditions){
                            const key = Object.keys(item.conditions.values)
                            item.parent_id = key[0]
                        }
                    })
                    section.score = 0
                    section.total = Object.keys(section.items).length
                })

                log('>> SECTIONS ',sections)

                const user = store.getters['loginUser/getUserInformation']
                const newAudit = {
                    business_date: moment(), 
                    complete_date: false, 
                    completed: 0, 
                    config: context.state.templates[params.tpl_id].config, 
                    created_date: moment(), 
                    data: false, 
                    def: state.config, 
                    employee: { ...user }, 
                    employee_ref: { ...user }, 
                    employees: [], 
                    id: 'tmpitw' + Math.floor(Date.now() / 1000), 
                    sections: JSON.parse(JSON.stringify(sections)), 
                    recover: false, 
                    score: 0,
                    status: 'progress', 	
                    template_id: params.tpl_id, 
                    title: context.state.templates[params.tpl_id].name, 
                    total: items.length, 
                    type: context.state.templates[params.tpl_id].type, 
                    unanswered: items.length, 
                    uncompleted: 0, 
                    requireSync: true 
                }
                const syncData = {
                    action: 'add',
                    checklist: newAudit.id,
                    employee: newAudit.employee.id,
                    template_ref: params.tpl_id,
                    type: newAudit.type
                }

                store.dispatch('audit/syncAction', syncData)
                const copyOfItems = JSON.parse(JSON.stringify(context.state.items))
                log('>> COPY OF ITEMS ', copyOfItems)
                const allItems = { [newAudit.id]: newAudit, ...copyOfItems}

                await context.dispatch('format', { data: allItems })
                await context.dispatch('calcScore', newAudit.id)
                //context.commit('setNumberPage', 1)

                db_audits.setItem(newAudit.id, JSON.parse(JSON.stringify(newAudit))).then(() => {
                    log('Register added to localForage')
                }).catch((err) => {
                    log('Error adding register to localForage', err)
                })

                const itemToReturn = context.getters.getById(newAudit.id)

                return itemToReturn
            }
        }
    },
    delete(context, params) {
        log(params)
        var oData = {
            checklist: params.check,
            employee: params.emp,
            status: -1
        }
        if (params.ref_item) {
            oData['ref_item'] = params.ref_item
        }

        return api.post('checklist/update', oData).then(function (response) {
            var audit = { ...context.state.items[params.check] }
            var tpl = { ...context.state.templates[params.template] }

            db_audits.removeItem(params.check)
            log(tpl)
            log(audit)

            //   context.commit('removeItem', audit.id)
            // TODO REMOVE LOCAL AUDIT AND NUMBER OF CURRENT AUDITS IF STATUS == progress
            if (context.state.items[params.check].status == 'progress') {
                tpl.num_progress--
                context.commit('setTemplate', tpl)
            }
            return response
        })
    },
    loadStats(context, params) {
        context.commit('resetStats', {})
        var currentDate = moment(params.date)

        // SET ALERTS --------------------------------------------------------------

        // SET ACTIVITY ------------------------------------------------------------

        // -- Created Audits
        context.dispatch('loadFromDate', { date: currentDate.format('YYYY-MM-DD') }).then(function (response) {
            let result = response.data ? Object.keys(response.data).length : 0
            if (response.data && result > 0) {
                context.commit('setStats', {
                    where: 'activity',
                    key_name: 'created',
                    values: {
                        name: i18n.tc('home.activity.registers_generated', result),
                        value: result
                    }
                })
            }
        })
    },
    // FORMAT ACTION
    format({ state, context, commit, dispatch, rootState }, params = {}) {
        var aItems = {}

        if (typeof params.data !== 'undefined') {
            Object.keys(params.data).map((list_id, e) => {
                var list = params.data[list_id]

                if (list.resume && list.resume.ev) {
                    list.finalScore = {
                        color: list.resume.ev.c,
                        name: list.resume.ev.n
                    }
                }

                delete list.resume
                
                //Offline cases
                if(list.created_date && typeof list.created_date === 'string'){
                    list.created_date = moment(list.created_date)
                }

                if(list.business_date && typeof list.business_date === 'string'){
                    list.business_date = moment(list.business_date)
                }

                if(list.complete_date && typeof list.complete_date === 'string'){
                    list.complete_date = moment(list.complete_date)
                }

                // Timezone set
                list.business_date = list.business_date ? (moment.isMoment(list.business_date) ? list.business_date : moment(list.business_date * 1000)) : false
                list.created_date = list.created_date ? (moment.isMoment(list.created_date) ? list.created_date : moment(list.created_date * 1000)) : false
                list.complete_date = list.complete_date ? (moment.isMoment(list.complete_date) ? list.complete_date : moment(list.complete_date * 1000)) : false

                if (list.business_date.valueOf() < moment().valueOf() && list.started == 0) list.state = 'past'
                else if (list.business_date.valueOf() > moment().valueOf()) list.state = 'future'
                else list.state = 'intime'

                list.def = state.config
                list.uncompleted = 0
                list.completed = 0
                list.unanswered = 0
                list.total = 0
                list.score = -1
                list.grade = false

                // Loop for all sections
                Object.keys(list.sections).map(function (sec_id, i) {
                    var section = list.sections[sec_id]

                    section.completed = 0
                    section.total = 0
                    section.score = -1

                    // Loop for all items
                    Object.keys(section.items).map(function (item_id, o) {
                        var item = section.items[item_id]

                        // Timezone set
                        item.complete_date = item.complete_date ? (moment.isMoment(item.complete_date) ? item.complete_date : moment(item.complete_date * 1000)) : false
                        if (item.review) {
                            item.review['complete_date'] = item.review.complete_date ? (moment.isMoment(item.review.complete_date) ? item.review.complete_date : moment(item.review.complete_date * 1000)) : false
                        }

                        // If type image - Split value to array
                        if (state.config.item.itemsImage.includes(item.type)) {
                            item.value = item.value && typeof item.value === 'string' ? splitCommasBase64(item.value) : item.value
                        }

                        item.complete_date = item.complete_date
                        item.available = 0

                        if(isNaN(parseInt(item.apply))){
                            item.apply = item.apply ? 1 : 0
                        }

                        item.apply = parseInt(item.apply) == 1
                        //Is object item.config
                        if(item.config && Object.values(item.config).length > 0 && typeof item.config != 'string'){
                            item.config = JSON.stringify(item.config)
                        }

                        if(item.config && Object.values(item.config) == 0 && typeof item.config != 'string'){
                            item.config = JSON.stringify(item.config)
                        }

                        item.config = item.config ? JSON.parse(item.config) : JSON.parse('{}')

                        section.items[item.item_id] = { ...item }
                        Vue.set(section.items, item.item_id, { ...item })
                        // list.sections[sec_id].items[item.id] = { ...item };
                        // Vue.set(list.sections[sec_id].items, item.id, { ...item });
                    })

                    list.sections[section.section_id] = { ...section }
                    Vue.set(list.sections, section.section_id, { ...section })
                })

                aItems[list.id] = list
            })
        }

        commit('setFormat', { items: aItems })
    },

    calcScore({ state, context, commit, dispatch, rootState }, id) {
        var aItems = state.items
        var aTemplates = state.templates
        var last_audits = []

        // var alert_pending_count = {};
        // var alert_pending_show = {};
        // var alert_important_show = {};

        Object.keys(aTemplates).map(function (tpl_id, o) {
            var template = aTemplates[tpl_id]

            if (template.last_audit) {
                last_audits.push(template.last_audit.id)
            }
        })

        Object.keys(aItems).map(function (list_id, e) {
            var list = aItems[list_id]
            var template = aTemplates[list.template_id]
            if (typeof id === 'undefined' || list_id == id) {
                // var past_score = list.score ? list.score : 0;

                if (last_audits.includes(list.id)) {
                    template.completed = 0
                    template.total = 0
                    template.total_score = 0
                    template.last_score = 0
                }

                list.def = state.config
                list.uncompleted = 0
                list.completed = 0
                list.unanswered = 0
                list.total_score = 0
                list.total = 0
                list.score = 0

                // Set status of the Audit
                if (moment(list.business_date) > moment()) {
                    list.status = 'scheduled'
                } else if (list.complete_date) {
                    list.status = 'completed'
                } else if (list.started == 1) {
                    list.status = 'progress'
                }

                // Loop for all sections
                Object.keys(list.sections).map(function (sec_id, o) {
                    var section = list.sections[sec_id]

                    section.completed = 0
                    section.total = 0
                    section.score = 0
                    section.total_score = 0

                    // Loop for all items
                    Object.keys(section.items).map(function (item_id, i) {
                        var item = section.items[item_id]
                        item.is_visible = true
                        item.alerts = false

                        // Subtasks
                        if (typeof item.conditions !== 'undefined' && item.conditions != null && item.conditions) {
                            var conditions = item.conditions
                            var current_show = null
                            var current_order = -1
                            var cond_operator = typeof conditions.operator !== 'undefined' ? conditions.operator : 'AND'
                            for (var key in conditions.values) {
                                var show = false
                                let condition = conditions.values[key]

                                let value = section.items[key] ? section.items[key].value : false
                                item.is_visible = false

                                var operator = condition && condition.operator ? condition.operator : '='
                                var val = condition ? condition.value : false

                                if ((value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(section.items[key].type)) && section.items[key] && section.items[key].is_visible) {
                                    if (operator == 'true' && val == 'true') {
                                        show = true
                                    } else {
                                        // Get task type from parent
                                        switch (parseInt(section.items[key].type)) {
                                            //   switch (item.type) {
                                            case FORMAT.EMAIL:
                                            case FORMAT.DOCUMENT:
                                            case FORMAT.CHECK:
                                            case FORMAT.YESNO:
                                                show = compare(parseInt(val) == 1 || (val == '' && operator == '!empty'), value == true || value == 'true' || (value != null && val == '' && operator == '!empty'), operator)
                                                break
                                            case FORMAT.YESNONC:
                                                show = compare(parseInt(val) || (val == '' && operator == '!empty'), value || (val == '' && operator == '!empty'), operator)
                                                break
                                            case FORMAT.MULTIPLE:
                                                show = compare(val || (val == '' && operator == '!empty'), value, operator)
                                                break
                                            case FORMAT.TEXT:
                                            case FORMAT.TEXTAREA:
                                            case FORMAT.PHOTO:
                                            case FORMAT.DATE:
                                            case FORMAT.TIME:
                                            case FORMAT.DATETIME:
                                            case FORMAT.SIGNATURE:
                                                show = compare(val, value, operator)
                                                break
                                            case FORMAT.NUMBER:
                                                var aux = value.toString().replace(',', '.')
                                                show = compare(parseFloat(aux), val, operator) || (val == '' && operator == '!empty')
                                                break
                                            case FORMAT.TEMPERATURE:
                                                var aux = value.toString().replace(',', '.')
                                                show = compare(parseFloat(aux), val, operator) || (val == '' && operator == '!empty')
                                                break
                                            default:
                                                break
                                        }
                                    }
                                }

                                if (current_show == null) current_show = show
                                else {
                                    switch (cond_operator) {
                                        case 'AND':
                                            current_show = current_show && show
                                            break
                                        case 'OR':
                                            current_show = current_show || show
                                            break
                                    }
                                }
                                if (show) {
                                    current_order = section.items[key].order
                                }
                            }

                            item.is_visible = current_show
                            item.order = parseInt(current_order) + 0.5
                        }

                        // Alerts TODO
                        if (item.alerts_triggers) {
                            let alerts = item.alerts_triggers
                            let value = item.value

                            let oAlert = {}
                            let message = ''
                            let preview = ''
                            let issues = []
                            let classes = []

                            for (var i in alerts) {
                                let alert = alerts[i]
                                let operator = alert.operator ? alert.operator : '='
                                let val = alert.value

                                let show = false

                                if ((value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(item.type)) && item.is_visible) {
                                    if (operator == 'true' && val == 'true') {
                                        show = true
                                    } else {
                                        switch (item.type) {
                                            case FORMAT.EMAIL:
                                            case FORMAT.DOCUMENT:
                                            case FORMAT.CHECK:
                                            case FORMAT.YESNO:
                                                show = compare(parseInt(val) == 1 || (val == '' && operator == '!empty'), value == true || value == 'true', operator)
                                                break
                                            case FORMAT.YESNONC:
                                                show = compare(parseInt(val) || (val == '' && operator == '!empty'), value, operator)
                                                break
                                            case FORMAT.MULTIPLE:
                                                show = compare(val || (val == '' && operator == '!empty'), value, operator)
                                                break
                                            case FORMAT.TEXT:
                                            case FORMAT.TEXTAREA:
                                            case FORMAT.PHOTO:
                                            case FORMAT.DATE:
                                            case FORMAT.TIME:
                                            case FORMAT.DATETIME:
                                            case FORMAT.SIGNATURE:
                                                show = compare(val, value, operator)
                                                break
                                            case FORMAT.NUMBER:
                                                var aux = value.toString().replace(',', '.')
                                                show = compare(parseFloat(aux), val, operator) || (value != '-' && operator == '!empty')
                                                break
                                            case FORMAT.TEMPERATURE:
                                                var aux = value.toString().replace(',', '.')
                                                show = compare(parseFloat(aux), val, operator) || (value != '-' && operator == '!empty')
                                                break
                                            default:
                                                break
                                        }
                                    }
                                }

                                if (show && alert.msg) {
                                    if (message == '') message = alert.msg
                                    else message = message + '<br/>' + alert.msg

                                    if (preview == '') preview = alert.msg
                                    else preview = preview + ', ' + alert.msg
                                }

                                if (show && alert.tpl) {
                                    issues.push(alert.tpl)
                                }
                                if (show && alert.class) {
                                    if (alert.class == 'alert') {
                                        alert.class = 'warning'
                                    }
                                    classes.push(alert.class)
                                }
                            }

                            oAlert = {
                                preview: preview,
                                message: message,
                                issues: issues,
                                class: classes
                            }

                            if (oAlert.message.length > 0 || oAlert.issues.length > 0) {
                                item.alerts = oAlert
                                Vue.set(item, 'alerts', oAlert)
                            }
                        }

                        // CALC SCORE PROPIO DE AUDITS
                        if (((!item.parent_id && list.def.item.itemsExcluded.indexOf(item.type) == -1) || (item.parent_id && item.is_visible)) && !item.planned_to_date && item.apply) {
                            item.score = 0
                            let scoreTotal = item.param && item.param.total ? item.param.total : 0
                            let conditions = item.param && item.param.conditions ? item.param.conditions : []
                            let value = item.value
                            let result = 0
                            let show = false

                            if (!show) {
                                for (var i in conditions) {
                                    let param = conditions[i]
                                    let operator = param.operator ? param.operator : '='
                                    let val = param.value
                                    let score = param.p

                                    if ((value !== null || [FORMAT.CHECK, FORMAT.DOCUMENT].includes(item.type) || !item.apply) && item.is_visible) {
                                        switch (item.type) {
                                            case FORMAT.EMAIL:
                                            case FORMAT.DOCUMENT:
                                            case FORMAT.CHECK:
                                            case FORMAT.YESNO:
                                                show = compare(parseInt(val) == 1, value == true || value == 'true', operator)
                                                break
                                            case FORMAT.YESNONC:
                                                show = compare(val || (val == '' && operator == '!empty'), value, operator)
                                                break
                                            case FORMAT.MULTIPLE:
                                                show = compare(val || (val == '' && operator == '!empty'), value, operator)
                                                break
                                            case FORMAT.TEXT:
                                            case FORMAT.TEXTAREA:
                                            case FORMAT.PHOTO:
                                            case FORMAT.DATE:
                                            case FORMAT.TIME:
                                            case FORMAT.DATETIME:
                                            case FORMAT.SIGNATURE:
                                                show = compare(val, value, operator)
                                                break
                                            case FORMAT.NUMBER:
                                                var aux = value.toString().replace(',', '.')
                                                show = compare(parseFloat(aux), val, operator) || (value != '-' && operator == '!empty')
                                                break
                                            case FORMAT.TEMPERATURE:
                                                var aux = value.toString().replace(',', '.')
                                                show = compare(parseFloat(aux), val, operator) || (value != '-' && operator == '!empty')
                                                break
                                            default:
                                                break
                                        }
                                        if (show) {
                                            item.score = score
                                            section.completed = parseFloat(section.completed) + parseFloat(item.score)
                                            list.completed = parseFloat(list.completed) + parseFloat(item.score)
                                        }
                                    }
                                }
                            }
                            // Posible total score
                            list.total_score = parseFloat(list.total_score) + parseFloat(scoreTotal)
                            section.total_score = parseFloat(section.total_score) + parseFloat(scoreTotal)

                            // Total tal cual de items
                            if (![FORMAT.LINK, FORMAT.RESOURCE].includes(item.type)) {
                                list.total++
                                section.total++
                            }
                        }

                        section.items[item.item_id] = { ...item }
                        Vue.set(section.items, item.item_id, { ...item })
                    })

                    section.score = Math.round((parseFloat(section.completed) * 100) / parseFloat(section.total_score))

                    if (last_audits.includes(list.id)) {
                        template.total_score = parseFloat(template.total_score) + parseFloat(list.total_score)
                        template.total = parseFloat(template.total) + parseFloat(list.total)
                        template.completed = parseFloat(template.completed) + parseFloat(list.completed)
                    }

                    list.sections[section.section_id] = { ...section }
                    Vue.set(list.sections, section.section_id, { ...section })
                })

                list.score = list.total_score == 0 ? 0 : Math.round((parseFloat(list.completed) * 100) / parseFloat(list.total_score))

                if (last_audits.includes(list.id)) {
                    template.last_score = parseFloat(template.last_score) + parseFloat(list.score)
                }

                aItems[list.id] = { ...list }
            }
        })

        // ORDER
        Object.keys(aItems).map(function (item_id, i) {
            let list = JSON.parse(JSON.stringify(aItems[item_id]))
            Object.keys(list.sections).map(function (sec_id, o) {
                var section = list.sections[sec_id]
                var items = Object.keys(section.items)
                    .sort((a, b) => {
                        return parseFloat(list.sections[sec_id].items[a].order) - parseFloat(list.sections[sec_id].items[b].order)
                    })
                    .reduce((prev, curr, i) => {
                        prev[i] = list.sections[sec_id].items[curr]
                        return prev
                    }, {})

                let aux2 = {}
                for (var k in items) {
                    aux2[items[k].item_id] = items[k]
                }
                section.items = aux2
            })
            aItems[item_id] = list
        })

        Object.keys(aTemplates).map(function (tpl_id, o) {
            var template = aTemplates[tpl_id]

            template.total_score = parseFloat(template.total_score) == 0 ? 0 : Math.round((parseFloat(template.completed) * 100) / parseFloat(template.total_score))
        })

        commit('setTemplates', { data: aTemplates })
        commit('setScore', { items: aItems })
    },
    loadItems(context, params) {
        if (fnCheckConnection() && context.rootGetters.getFastConnection) {
            if(params.noRefresh){
                const items = context.getters.getItems 
                context.dispatch('format', { data: items })
                context.dispatch('calcScore')
                context.commit('setNumberItems', items.length)
                context.commit('setNumberPage', context.state.number_page)
                return
            }

            if (!fnCheckConnection()) {
                return loadItemsOffline(context.commit, context.dispatch)
            }

            return api.post('checklist/resume/' + params.template, { number_pages: context.state.number_page, number_items: 10, type: state.type })
            .then(function (response) {

                _.forEach(response.data.values, function (value, key) {
                    log('-> INSERT', value.id,value)
                    db_audits.setItem(value.id, JSON.parse(JSON.stringify(value)))
                })

                context.dispatch('format', { data: response.data.values })
                context.dispatch('calcScore')
                context.commit('setResume', { data: response.data.schema })
                context.commit('setNumberItems', response.data.number_items)
                context.commit('setNumberPage', context.state.number_page)
            }).catch((error) => {
                return loadItemsOffline(context.commit, context.dispatch)
            })
        } else if(!params.noRefresh) {
            context.dispatch('calcScore')
            return loadItemsOffline(context.commit, context.dispatch)
        }
    },
    changeSupervisor({ state, context, commit, dispatch, rootState }, params = {}) {
        commit('setSupervisor', { id: params.emp_id })
    },
    loadFromDate({ state, context, commit, dispatch, rootState }, params = {}) {
        params.date = params.date ? params.date : moment().format('YYYY-MM-DD')
        return api.get('checklist/' + state.type + '/' + params.date, {}).then(function (response) {
            return response
        })
    },
    recoverValues(context, params) {
        return api.post('checklist/recover/' + params.check, {}).then(function (response) {
            if (response.status) {
                context.dispatch('format', { data: response.data })
                context.dispatch('calcScore', params.id)
            } else {
                router.go()
            }

            return response.data
        })
    },
    syncAction(context, params) {
        params.queued_date = moment().valueOf()
        params.id = moment().valueOf()

        return context.dispatch('checklist/addAuditToQueue', params, { root: true }).then(() => {
            return { status: true }
        })
    },
    sync(context, queue) {
        let checklists = {}
        for (let key in queue) {
            let queueElement = queue[key]
            if (typeof checklists[queueElement.checklist] === 'undefined') {
                checklists[queueElement.checklist] = {
                    items: [],
                    resume: { it: 0, is: 0, a: 0, n: 0 },
                    total: 0,
                    answered: 0,
                    score: 0
                }
            }

            checklists[queueElement.checklist].resume = queueElement.resume
            checklists[queueElement.checklist].total = queueElement.total
            checklists[queueElement.checklist].answered = queueElement.answered
            checklists[queueElement.checklist].score = queueElement.score
            checklists[queueElement.checklist].items.push(queueElement)
        }
        return api
            .post(`checklist/${state.type}/sync`, { checklists: JSON.stringify(checklists) })
            .then(function (response) {
                if(!response.status){
                    throw new Error('API error') //Por si falla algo de email
                }
                if(response.data){
                    const issues = response.data.filter(queue => queue.issue_id)
                    if(issues.length > 0){
                        context.dispatch('updateIssueOfflineDatabase', issues)
                        response.data = response.data.filter(queue => !queue.issue_id)
                    }
                }
                return context
                    .dispatch('updateOfflineDatabase', response)
                    .then(() => {
                        return response
                    })
                    .catch(function (error) {
                        logError(error)
                        return false
                    })
            })
            .catch(function (error) {
                logError(error)
                return false
            })
    },
    async updateIssueOfflineDatabase(context, response) {
        if(response){
            const checklists = response
            log('>> updateIssueOfflineDatabase', checklists)
            checklists.forEach(async (checklist) => {
                //Actualizar el estado de sincronización de los registros
                await store.dispatch('issues/updateStateOfSync', checklist)

                log('>> updateOfflineDatabase issues', checklist)
                // Actualizar el id de sincronización de los registros
                if(checklist && checklist.checklist_temporal && checklist.checklist){
                    const register = await db_issues.getItem(checklist.checklist_temporal)
                    if(register){
                        register.id = checklist.checklist
                        register.issue_id = checklist.issue_id
                        await db_issues.removeItem(checklist.checklist_temporal)
                        await db_issues.setItem(checklist.checklist, register)
                    }
                    //change the key of the item and id
                    const copy = store.getters['issues/getAll']
                    log('>> updateOfflineDatabase issues store', copy)
                    copy[checklist.checklist_temporal].id = checklist.checklist
                    copy[checklist.checklist_temporal].requireSync = false
                    copy[checklist.checklist_temporal].issue_id = checklist.issue_id
                    copy[checklist.checklist_temporal].temporal_id = checklist.checklist_temporal
                    const updateItem = { [checklist.checklist]: copy[checklist.checklist_temporal] }
                    delete copy[checklist.checklist_temporal]

                    const allItems = { ...updateItem, ...copy}
                    /* context.state.items = {...copy} */
                    await store.dispatch('issues/format', {data: allItems})

                    //update allItems
                    const newIssueItem = store.getters['issues/getAll'][copy.status]
                    newIssueItem[checklist.checklist_temporal].id = checklist.checklist
                    newIssueItem[checklist.checklist_temporal].requireSync = false
                    newIssueItem[checklist.checklist_temporal].issue_id = checklist.issue_id
                    newIssueItem[checklist.checklist_temporal].temporal_id = checklist.checklist_temporal
                    const newAllItems = { ...newIssueItem}
                    store.commit('issues/setAllItems', { items: newAllItems, type: copy.status })
                }
            })
        }
    },
    async mergeOffline(context, params = {}) {
        await db_audits_queue.iterate(function (answer, checklistId) {
            if (typeof params.data[answer.checklist] !== 'undefined') {
                for (let sectionId in params.data[answer.checklist].sections) {
                    if (typeof params.data[answer.checklist].sections[sectionId].items[answer.task] !== 'undefined') {
                        params.data[answer.checklist].sections[sectionId].items[answer.task].employee = answer.employee
                        params.data[answer.checklist].sections[sectionId].items[answer.task].complete_date = answer.complete_date
                        params.data[answer.checklist].sections[sectionId].items[answer.task].value = answer.value ? answer.value.toString() : answer.value
                        params.data[answer.checklist].sections[sectionId].items[answer.task].requireSync = true
                    }
                }
            }
        })
        return params
    },
    async clearOfflineQueue(context, params) {
        log('clearOfflineQueue', params)
        const data = params.data
        const action = params.action
        //['answer', 'reset', 'validate', 'unvalidate', 'complete', 'uncomplete']
        const mapActionsToRemove = {
            answer: ['answer', 'reset'],
            reset: ['answer', 'reset'],
            validate: ['validate', 'unvalidate'],
            unvalidate: ['validate', 'unvalidate']
        }

        const actionsToRemove = mapActionsToRemove[action]

        await db_audits_queue.iterate(async function (answer, key, iterationNumber) {
            const isTheSameChecklist = answer.checklist === data.checklist && answer.task === data.task
            if (actionsToRemove.includes(answer.action) && isTheSameChecklist) {
                await db_audits_queue.removeItem(key)
            }
        })
    },
    async updateStateOfSync(context, params){
        log('>> updateStateOfSync', params)
        // Actualizar el estado de sincronización de los registros
        if(params && params.checklist_temporal){
            const register = await db_audits.getItem(params.checklist_temporal)
            if(register){
                register.requireSync = false
                await db_audits.setItem(params.checklist_temporal, register)
            }
            state.items[params.checklist_temporal].requireSync = false
        }
    },
    
    async updateOfflineDatabase(context, response) {
        if(response.data){
            if(response.data.type && response.data.type !== 'add') return

            const checklists = response.data
            checklists.forEach(async (checklist) => {
                //Actualizar el estado de sincronización de los registros
                await context.dispatch('updateStateOfSync', checklist)

                log('>> updateOfflineDatabase', checklist)
                // Actualizar el id de sincronización de los registros
                if(checklist && checklist.checklist_temporal && checklist.checklist){
                    const register = await db_audits.getItem(checklist.checklist_temporal)
                    if(register){
                        register.id = checklist.checklist
                        await db_audits.removeItem(checklist.checklist_temporal)
                        await db_audits.setItem(checklist.checklist, register)
                    }
                    //change the key of the item and id
                    const copy = {...context.state.items}
                    copy[checklist.checklist_temporal].id = checklist.checklist
                    copy[checklist.checklist_temporal].temporal_id = checklist.checklist_temporal
                    const updateItem = { [checklist.checklist]: copy[checklist.checklist_temporal] }
                    delete copy[checklist.checklist_temporal]

                    const allItems = { ...updateItem, ...copy}
                    context.state.items = {...copy}
                    await context.dispatch('format', { data: allItems })
                }
            })
        }
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    setTemplates(state, payload) {
        var templates = {}

        if (payload.data != undefined) {
            Object.keys(payload.data).map(function (tpl_id, i) {
                var tpl = { ...payload.data[tpl_id] }

                tpl.last_update = tpl.last_update ? (moment.isMoment(tpl.last_update) ? tpl.last_update : moment(tpl.last_update * 1000)) : false

                tpl.last_score = tpl.last_score && !Number.isNaN(tpl.last_score) ? tpl.last_score : 0
                tpl.total_score = tpl.total_score && !Number.isNaN(tpl.total_score) ? tpl.total_score : 0
                tpl.total = tpl.total && !Number.isNaN(tpl.total) ? tpl.total : 0
                tpl.completed = tpl.completed && !Number.isNaN(tpl.completed) ? tpl.completed : 0

                templates[tpl.id] = tpl
            })
        }

        state.templates = templates
        Vue.set(state, 'templates', { ...templates })
    },
    setTemplate(state, template) {
        var templates = { ...state.templates }
        templates[template.id] = template

        state.templates = templates
        Vue.set(state, 'templates', { ...templates })
    },
    setItem(state, item) {
        var items = { ...state.items }
        items[item.id] = item

        state.items = items
        Vue.set(state, 'items', { ...items })
    },
    removeItem(state, id) {
        var items = { ...state.items }
        delete items[id]

        state.items = items
        Vue.set(state, 'items', { ...items })
    },
    resetStats(state, payload) {
        state.stats.alerts = {}
        state.stats.activity = {}
    },
    setStats(state, payload) {
        state.stats[payload.where] = {}
        state.stats[payload.where][payload.key_name] = payload.values
    },
    setFormat(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
    },
    setScore(state, payload) {
        state.items = payload.items
        Vue.set(state, 'items', { ...payload.items })
    },
    setAnswerProp(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var prop = payload.prop
        var value = payload.value
        var section = payload.getters['getSectionFromItem'](check, answer)

        state.items[check].sections[section].items[answer][prop] = value

        Vue.set(state, 'items', { ...state.items })
    },
    setComment(state, payload) {
        let check = payload.check
        let answer = payload.answer
        var section = payload.getters['getSectionFromItem'](check, answer)

        let aux = state.items[check].sections[section].items[answer]
        aux.message = payload.message
        aux.image = payload.image ? payload.image : false
        aux.requireSync = payload.requireSync

        Vue.set(state.items[check].sections[section].items, answer, { ...aux })
        Vue.set(state, 'items', { ...state.items })
    },
    setNumberItems(state, payload) {
        state.number_items = payload
        Vue.set(state, 'number_items', state.number_items)
        db_templates.setItem('registers_items', payload)
    },
    setNumberPage(state, payload) {
        state.number_page = payload
        Vue.set(state, 'number_page', state.number_page)
        db_templates.setItem('registers_page', payload)
    },
    setAnswer(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var value = payload.value
        var date = payload.date
        var section = payload.getters['getSectionFromItem'](check, answer)

        state.items[check].sections[section].items[answer].value = value
        state.items[check].sections[section].items[answer].requireSync = payload.requireSync

        if (date) {
            var item = state.items[check].sections[section].items[answer]
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) state.items[check].sections[section].items[answer].employee = emp

        Vue.set(state, 'items', { ...state.items })
    },
    setApply(state, payload) {
        const check = payload.check
        const answer = payload.answer
        const emp = payload.emp
        const apply = payload.apply
        const date = payload.date
        const section = payload.getters['getSectionFromItem'](check, answer)

        state.items[check].sections[section].items[answer].apply = apply
        state.items[check].sections[section].items[answer].value = null

        if (date) {
            state.items[check].sections[section].items[answer].complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
            state.items[check].sections[section].items[answer].value = null
        } else {
            state.items[check].sections[section].items[answer].complete_date = false
        }

        if (emp) {
            state.items[check].sections[section].items[answer].employee = emp
        }

        Vue.set(state, 'items', { ...state.items })
    },
    setReset(state, payload) {
        if (payload.getters != undefined) {
            var check = payload.check
            var answer = payload.answer
            var section = payload.getters['getSectionFromItem'](check, answer)

            state.items[check].sections[section].items[answer].complete_date = false
            state.items[check].sections[section].items[answer].employee = false
            state.items[check].sections[section].items[answer].review = false
            state.items[check].sections[section].items[answer].value = null

            Vue.set(state, 'items', { ...state.items })
        }
    },
    setValidation(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var emp = payload.emp
        var date = payload.date
        var section = payload.getters['getSectionFromItem'](check, answer)

        state.items[check].sections[section].items[answer].review = {}
        if (date) {
            var item = state.items[check].sections[section].items[answer].review
            item.complete_date = !moment.isMoment(date) && date != false ? moment(date * 1000) : item.complete_date
        }
        if (emp) {
            var employee = store.getters['employee/getEmployee'](emp)
            state.items[check].sections[section].items[answer].review.employee = employee
        }
        state.items[check].sections[section].items[answer].review.value = 1

        Vue.set(state, 'items', { ...state.items })
    },
    setUnvalidation(state, payload) {
        var check = payload.check
        var answer = payload.answer
        var section = payload.getters['getSectionFromItem'](check, answer)

        state.items[check].sections[section].items[answer].review = false

        Vue.set(state, 'items', { ...state.items })
    },
    setResume(state, payload) {
        var aItems = {}

        Object.keys(payload.data).map(function (id, i) {
            var item = payload.data[id]

            if (item.type == '8') {
                aItems[item.id] = item
            }
        })

        state.headers = aItems
        Vue.set(state, 'headers', aItems)
    },
    setSupervisor(state, payload) {
        state.supervisor = payload.id
        Vue.set(state, 'supervisor', payload.id)
    },
    setRequireSync(state, payload) {
        let check = payload.check
        let answer = payload.answer
        let apiId = payload.apiId

        console.log('setRequireSync', check, answer, apiId)
        check = state.items[check] ? check : (state.items[apiId] ? apiId : false)
        console.log('setRequireSync 2', check)

        if (typeof state.items[check] !== 'undefined') {
            for (let sectionId in state.items[check].sections) {
                if (typeof state.items[check].sections[sectionId].items[answer] !== 'undefined') {
                    state.items[check].sections[sectionId].items[answer].requireSync = payload.requireSync
                    state.items[check].sections[sectionId].items[answer] = { ...state.items[check].sections[sectionId].items[answer] }
                }
            }
        }

        Vue.set(state, 'items', { ...state.items })
    }
}

function loadTemplatesOffline(context) {
    let templates = []
    let count = 0
    return db_templates
        .iterate(function (value, key, iterationNumber) {
            value = typeof value === 'string' ? JSON.parse(value) : value
            if (parseInt(value.type) == parseInt(context.state.type)) {
                templates.push(value)
                count++
            }
        })
        .then(function () {
            if (count > 0) {
                //sort by created date desc
                templates.sort((a, b) => {
                    return moment(a.created_date) - moment(b.created_date)
                })

                context.commit('setTemplates', { data: templates })
            }
        })
}

function loadItemsOffline(commit, dispatch) {
    let items = {}
    let count = 0
    log('>> loadItemsOffline')
    return db_audits
        .iterate(function (value, key, iterationNumber) {
            items[value.id] = {...value}
            count++
        })
        .then(function () {
            log('loadItemsOffline', items)
            if (count > 0) {
                dispatch('mergeOffline', { data: items }).then((response) => {
                    dispatch('format', { data: response.data })
                    dispatch('calcScore')
                })
            }
        })
}

function loadDetailOffline(context, id) {
    const items = {}
    return db_audits.
        iterate(function (value, key, iterationNumber) {
            if(value.id == id){
                items[value.id] = value
            }
        })
        .then(function () {
            log('loadDetailOffline', items)
            context.dispatch('format', { data: items })
            context.dispatch('calcScore')
        })
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
