import Vue from 'vue'
import i18n from '@/i18n'

import { Api } from '@/core/api.js'

import localforage from 'localforage'
const api = new Api()

var db_categories = localforage.createInstance({
    name: 'alexdb',
    storeName: 'library_categories'
})
var db_sections = localforage.createInstance({
    name: 'alexdb',
    storeName: 'library_sections'
})
var db_files = localforage.createInstance({
    name: 'alexdb',
    storeName: 'library_files'
})
var db_highlight_files = localforage.createInstance({
    name: 'alexdb',
    storeName: 'library_highlight_files'
})

// Triquiñuela para resetear el state
const getDefaultState = () => {
    return {
        categories: {},
        files: {},
        highlight_files: {},
        pdfLibrary: false,
        isFullscreen: false
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    getCategories: (state, getters, rootState) => {
        return state.categories
    },

    getCategory: (state, getters, rootState) => (category_id) => {
        return state.categories[category_id]
    },
    getPdfLibrary: (state, getters, rootState) => {
        return state.pdfLibrary
    },

    getSection: (state, getters, rootState) => (section_id) => {
        return state.sections[section_id]
    },

    getFiles: (state, getters, rootState) => (category_id) => {
        if (category_id) {
            return Object.values(state.files).filter((file) => file.category_id == category_id)
        }
        return state.files
    },

    getFilesSearched: (state, getters, rootState) => (search) => {
        var searchValues = search.split(' ')
        var results = []

        for (var fileIndex in state.files) {
            var file = state.files[fileIndex]
            var filename =
                file && file.name != null && file.name
                    ? file.name
                          .toString()
                          .trim()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .toLowerCase()
                    : ''
            log(file)
            var filedescription =
                file && file.description
                    ? file.description
                          .toString()
                          .trim()
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .toLowerCase()
                    : ''

            file.searchscore = 0

            for (var searchIndex in searchValues) {
                var searchWord = searchValues[searchIndex]
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()

                file.searchscore = filename.indexOf(searchWord) > -1 ? (file.searchscore += 1 + searchWord.length * 0.15) : file.searchscore
                file.searchscore = filedescription.indexOf(searchWord) > -1 ? (file.searchscore += 0.5 + searchWord.length * 0.1) : file.searchscore
            }

            if (file.searchscore > 0) {
                results.push(file)
            }
        }

        results.sort((a, b) => (parseInt(a.searchscore) < parseInt(b.searchscore) ? 1 : -1))

        return results
    },

    getFile: (state, getters, rootState) => (file_id) => {
        return state.files[file_id]
    },

    getLastFiles: (state, getters, rootState) => {
        var sorted = _.sortBy(state.files, ['upload_date'])
        return _.slice(sorted, 0, 5)
    },

    getHighlightFiles: (state, getters, rootState) => {
        return state.highlight_files
    },

    getFullScreen: (state, getters, rootState) => {
        return state.isFullscreen
    }
}

// actions
const actions = {
    // Commonisimos, está en todos los modules
    resetState({ commit }) {
        commit('resetState')
    },

    loadFiles(context, params) {
        var data = {
            categories: {},
            sections: {},
            files: {},
            highlight_files: {}
        }

        log("context.rootGetters['getRefresh'].library", context.rootGetters['getRefresh'].library)
        if (context.rootGetters['getRefresh'].library && fnCheckConnection()) {
            return api.get('library/list', {}).then(function (response) {
                var role = context.rootGetters['employee/getEmployee'](context.rootGetters['loginUser/getLocalEmployee']).rol
                log('>> role', role)
                if (response.data) {
                    context.commit('setRefreshLibrary', false, { root: true })
                    log('>> response', response.data)
                    var categories = []
                    var sections = []
                    var files = []
                    var highlight_files = []

                    for (var k in response.data.categories) {
                        var cat = response.data.categories[k]
                        if (cat.roles.length == 0 || cat.roles.includes(role)) {
                            categories[cat.id] = cat

                            // for (var j in cat.files) {
                            //     // FILES
                            //     var file = cat.files[j]
                            //     file.subcategory_id = cat.id
                            //     file.category_id = cat.id
                            //     files[file.id] = file
                            //     if (file.highlight || file.highlight == '1') {
                            //         highlight_files[file.id] = file
                            //     }
                            // }

                            for (var i in cat.subcategories) {
                                var section = cat.subcategories[i]

                                // IF ROLES GRANT ACCESS
                                log('>> section', section)
                                if (section.roles.length == 0 || section.roles.includes(role)) {
                                    // CATEGORIES
                                    cat.visible = false
                                    categories[cat.id] = cat
                                    // SUBCATEGORIES
                                    section.selected = false
                                    sections[section.id] = section

                                    // for (var j in section.files) {
                                    //     // FILES
                                    //     var file = section.files[j]
                                    //     file.subcategory_id = section.id
                                    //     file.category_id = cat.id
                                    //     files[file.id] = file
                                    //     if (file.highlight || file.highlight == '1') {
                                    //         highlight_files[file.id] = file
                                    //     }
                                    // }
                                }
                                // IF ROLE ACCESS IS DENIED
                                else {
                                    delete cat.subcategories[i]
                                    categories[cat.id] = cat
                                }

                                //Recursive unset selected
                                context.dispatch('unsetCategoryRecursive', { section: section })
                            }
                        }
                    }
                    // categories.unshift({
                    //     id: 0,
                    //     name: i18n.t('library.highlight_documents'),
                    //     highlight: true,
                    //     order: -1
                    // })

                    // highlight files

                    files = response.data.files

                    for (let i in files) {
                        let file = files[i]
                        if (file.highlight || file.highlight == '1') {
                            highlight_files[file.id] = file
                        }
                    }

                    data = {
                        categories: categories,
                        sections: sections,
                        files: response.data.files,
                        highlight_files: highlight_files
                    }

                    // var data = format(response.data);
                    context.commit('setCategories', { data: data })

                    // Save to local db
                    db_categories.clear()
                    _.forEach(_.values(data.categories), function (value, key) {
                        db_categories.setItem(value.id, value)
                    })

                    db_sections.clear()
                    _.forEach(_.values(data.sections), function (value, key) {
                        db_sections.setItem(value.id, value)
                    })

                    db_files.clear()
                    _.forEach(_.values(data.files), function (value, key) {
                        db_files.setItem(value.id, value)
                    })

                    db_highlight_files.clear()
                    _.forEach(_.values(data.highlight_files), function (value, key) {
                        db_highlight_files.setItem(value.id, value)
                    })

                    context.rootState.refresh.library = false
                } else {
                }
            })
        } else {
            db_categories
                .iterate(function (value, key, iterationNumber) {
                    data.categories[key] = value
                })
                .then(function () {
                    return db_sections.iterate(function (value, key, iterationNumber) {
                        data.sections[key] = value
                    })
                })
                .then(function () {
                    return db_files.iterate(function (value, key, iterationNumber) {
                        data.files[key] = value
                    })
                })
                .then(function () {
                    return db_highlight_files.iterate(function (value, key, iterationNumber) {
                        data.highlight_files[key] = value
                    })
                })
                .then(function () {
                    if (_.size(_.keys(data.categories)) > 0) {
                        context.commit('setCategories', { data: data })
                    }
                })
        }
    },

    unsetCategoryRecursive(context, params) {
        const section = params.section
        const role = context.rootGetters['employee/getEmployee'](context.rootGetters['loginUser/getLocalEmployee']).rol
        if(section.subcategories && Object.values(section.subcategories).length > 0){
            Object.values(section.subcategories).forEach(subcategory => {
                if (subcategory.roles.length == 0 || subcategory.roles.includes(role)) {
                    subcategory.selected = false
                    subcategory.visible = false

                    if(subcategory.subcategories.length > 0){
                        context.dispatch('unsetCategoryRecursive', {section: subcategory})
                    }
                }else{
                    delete section.subcategories[subcategory.id]
                }
            })
        }
    },

    viewLog(context, params) {
        return api.post('library/resources/view/add', params).then(function (response) {
            return response.status
        })
    },

    openFullscreen(context, params) {
        context.commit('setFullscreen', true)
    },

    closeFullscreen(context, params) {
        context.commit('setFullscreen', false)
    }
}

// mutations
const mutations = {
    // Commonisimos, está en todos los modules
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    setPdfLibrary(state, payload) {
        state.pdfLibrary = payload
        Vue.set(state, 'pdfLibrary', payload)
    },

    setCategories(state, payload) {
        Vue.set(state, 'categories', { ...payload.data.categories })
        Vue.set(state, 'highlight_files', { ...payload.data.highlight_files })
        Vue.set(state, 'files', { ...payload.data.files })
    },

    selectCategory(state, category) {
        for (var k in state.categories) {
            var cat = state.categories[k]

            for (var i in cat.subcategories) {
                var section = cat.subcategories[i]
                if (section.id == category.id) {
                    section.selected = true
                } else {
                    section.selected = false
                }
            }
        }

        Vue.set(state, 'categories', { ...state.categories })
    },

    setFullscreen(state, payload) {
        state.isFullscreen = payload
        Vue.set(state, 'isFullscreen', payload)
    }
}

function format(raw) {
    var categories = []
    var sections = []
    var files = []

    for (var k in raw) {
        var cat = raw[k]
        cat.visible = false
        categories[cat.id] = cat

        for (var i in cat.subcategories) {
            var section = cat.subcategories[i]
            section.selected = false
            sections[section.id] = section

            for (var j in section.files) {
                var file = section.files[j]
                file.subcategory_id = section.id
                file.category_id = cat.id
                files[file.id] = file
            }
        }
    }

    return { categories: categories, sections: sections, files: files }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
