import { Api } from '@/core/api.js'
import Vue from 'vue'

import { TIMERPRODUCT_STATUS } from '@/constants'
const api = new Api()

const state = {
    categories: {},
    states: [],
    timers: [],
    interval: null
}

const getters = {
    getStates(state, getters, rootState) {
        return state.states
    },
    getCategories(state, getters, rootState) {
        return state.categories
    },
    getTimers(state, getters, rootState) {
        return state.timers
    },

    getTimersByCategory: (state, getters, rootState) => (categoryId) => {
        var timers = {}

        var columns = {}
        for (var idx in state.states) {
            timers[state.states[idx].id] = []
        }

        for (var idx in state.timers) {
            var timer = state.timers[idx]
            if (!categoryId || (categoryId && categoryId == timer.category)) {
                timers[timer.column].push(timer)
            }
        }
        return timers
    },
    getFilteredTimers: (state, getters, rootState) => (categoryId, stateId) => {
        var timers = []

        for (var idx in state.timers) {
            var timer = state.timers[idx]
            if (!categoryId || (categoryId && categoryId == timer.category)) {
                timers[timer.column].push(timer)
            }
        }
        return timers
    }

}

const actions = {
    loadSchema(context, params) {
        // TODO - API request
        var columns = [
            { id: '1', name: 'Cámara', qty: 3, color: 'ok' },
            { id: '2', name: 'Mesa', qty: 3, color: 'ok' },
            { id: false, name: 'Desechar', qty: 2, color: 'error' }
        ]
        var categories = {
            false: { id: false, name: 'Todos' },
            '1': { id: '1', name: 'Salsas' },
            '2': { id: '2', name: 'Vegetales' },
            '3': { id: '3', name: 'Otros' },
            '4': { id: '4', name: 'Postres' },
            '5': { id: '5', name: 'Carne' }
        }

        context.commit('setStates', columns)
        return context.commit('setCategories', categories)
    },
    loadTimers(context, params) {
        var timers = {
            '1': { id: '1', name: 'Queso lonchas', product_id: '1', category: '3', column: '1', start_date: moment().subtract(3, 'hour').unix(), end_date: moment().add(2, 'hour').unix(), image: 'https://png.pngtree.com/png-clipart/20230807/original/pngtree-cheese-slice-icon-in-flat-design-dairy-sign-design-vector-picture-image_10063748.png', active: true, status: 2 },
            '2': { id: '2', name: 'Bacon', product_id: '2', category: '5', column: '2', start_date: moment().subtract(2, 'hour').unix(), end_date: moment().add(5, 'second').unix(), image: 'https://png.pngtree.com/png-clipart/20230807/original/pngtree-cheese-slice-icon-in-flat-design-dairy-sign-design-vector-picture-image_10063748.png', active: true, status: 2 },
            '3': { id: '3', name: 'Bacon', product_id: '2', category: '5', column: '1', start_date: moment().subtract(2, 'hour').unix(), end_date: moment().add(2, 'hour').unix(), prep_date: moment().add(14, 'second').unix(), image: 'https://png.pngtree.com/png-clipart/20230807/original/pngtree-cheese-slice-icon-in-flat-design-dairy-sign-design-vector-picture-image_10063748.png', active: true, status: 1 },
            '4': { id: '4', name: 'Tomate', product_id: '3', category: '5', column: '1', start_date: moment().subtract(2, 'hour').unix(), end_date: moment().add(32, 'minute').unix(), image: 'https://png.pngtree.com/png-clipart/20230807/original/pngtree-cheese-slice-icon-in-flat-design-dairy-sign-design-vector-picture-image_10063748.png', active: true, status: 2 }
        }

        if (state.interval) {
            clearInterval(state.interval)
        }

        var interval = setInterval(function() {
            return context.dispatch('processTimers')
        }, 1000)

        context.commit('setInterval', interval)

        context.commit('setTimers', timers)
        return context.dispatch('updateCategories')
    },
    processTimers(context) {
        var products = {}
        var updated = false
        for (var idx in state.timers) {
            var timer = state.timers[idx]

            if (timer.status > 0) { // Only process active timers
                var now = moment()

                // Timer prep date
                if (timer.prep_date && timer.status == TIMERPRODUCT_STATUS.PREPARATION) {
                    var duration = moment.duration(moment(timer.prep_date * 1000).diff(now))
                    var diff = duration.asSeconds()
                    if (diff <= 0) {
                        timer.status = TIMERPRODUCT_STATUS.AVAILABLE
                        updated = true
                    }
                }

                // Timer expiration date
                var duration = moment.duration(moment(timer.end_date * 1000).diff(now))
                var diff = duration.asSeconds()
                if (diff <= 0) {
                    timer.status = TIMERPRODUCT_STATUS.EXPIRED
                    timer.column = false
                    updated = true
                } else if (diff <= 60 * 60) { // Timer is less than 1 hour of expiration
                    timer.status = TIMERPRODUCT_STATUS.CLOSE_TO_EXPIRE
                    updated = true
                }
            }

            // Update timer status of related product
            if (typeof products[timer.product_id] === 'undefined') {
                products[timer.product_id] = []
            }

            if (products[timer.product_id].length > 0) {
                timer.same_product_status = products[timer.product_id][0].status

                for (var idx in products[timer.product_id]) {
                    // products[timer.product_id][idx].same_product_status = timer.status
                    state.timers[products[timer.product_id][idx].id].same_product_status = timer.status
                }
                updated = true
            }
            products[timer.product_id].push(timer)
        }

        if (updated) {
            return context.commit('setTimers', { ...state.timers })
        }
    },
    selectCategory(context, categoryId) {
        var categories = state.categories
        Object.keys(categories).forEach(key => categories[key].selected = false)
        categories[categoryId].selected = true
        return context.commit('setCategories', { ...categories })
    },
    updateCategories(context) {
        var categories = state.categories
        var timers = state.timers
        Object.keys(categories).forEach(key => categories[key].qty = 0)

        for (var idx in timers) {
            var timer = timers[idx]
            categories[timer.category].qty += 1
            categories[false].qty += 1
        }

        return context.commit('setCategories', { ...categories })
    }
}

const mutations = {
    setStates(state, data) {
        state.states = data
    },
    setCategories(state, data) {
        state.categories = data
    },
    setTimers(state, data) {
        state.timers = data
    },
    setInterval(state, data) {
        state.interval = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
