// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'

import store from './store'
import router from './router'

import _ from 'lodash' // options is optional

// MIXINS
import mixin from './core/mixin'

// LANGUAGE
import i18n from './i18n'

// PLUGINS PROPIOS
import popup from './plugins/popup'
import overlay from './plugins/overlay'
import loader from './plugins/loader'
import bottombar from './plugins/bottomBar'
import snackbar from './plugins/snackbar'
import columnFilter from './plugins/columnFilter'
import splashscreen from './plugins/splashscreen'

// PLUGINS AJENOS
// import Vuetify from 'vuetify'
import FunctionalCalendar from 'vue-functional-calendar'
import VueMq from 'vue-mq'
// import VueSpeech from 'vue-speech'

import 'es6-promise/auto'

//ALLOW TO ZOOM IN AND OUT IN MOBILE DEVICES
import panZoom from 'vue-panzoom'
Vue.use(panZoom)

// all Cloudinary components:
import Cloudinary from 'cloudinary-vue'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

window.Vue = Vue

Vue.use(i18n)

// Vue.use(Vuetify)
Vue.use(popup)
Vue.use(overlay)
Vue.use(loader)
Vue.use(bottombar)
Vue.use(snackbar)
Vue.use(columnFilter)
Vue.use(splashscreen)
// Vue.use(VueSpeech)

Vue.use(FunctionalCalendar, {
    dayNames: i18n.t('calendar.day_names'),
    monthNames: i18n.t('calendar.months')
})

Vue.use(VueMq, {
    breakpoints: {
        portrait: 901,
        landscape: 1200,
        desktop: Infinity
    }
})
Vue.use(Cloudinary, {
    configuration: { cloudName: 'intowin' }
})

Vue.config.productionTip = false
// Vue.config.devtools = true //Only for debug

/* CUSTOM DIRECTIVES */
Vue.directive('focus', {
    // When the bound element is inserted into the DOM...
    inserted: function (el) {
        // Focus the element
        el.focus()
    }
    // Ejemplo en process de popup
    // https://vuejs.org/v2/guide/custom-directive.html
    // componentUpdated: function (el, binding) {
    //   if( binding.value ){
    //     el.focus();
    //   }
    // },
})

import runtime from "serviceworker-webpack-plugin/lib/runtime";

if ("serviceWorker" in navigator) {
    runtime.register();
}

/* eslint-disable no-new */

// import { setCacheNameDetails } from 'workbox-core';

// setCacheNameDetails({
//     prefix: 'andy',
//     suffix: 'v1',
//     precache: 'precacheandy',
//     runtime: 'precacheandy',
//     //   googleAnalytics: 'custom-google-analytics-name'
// });
// main

// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.getRegistrations().then(function (registrations) {
//         for (let registration of registrations) {
//             registration.unregister()
//         }
//     })
//     // navigator.serviceWorker
//     //     .register('service-worker.js')
//     //     .then(console.log)
//     //     .catch(console.error)
// }

// self.addEventListener('activate', function (event) {

//     var cacheAllowlist = ['pages-cache-v1', 'blog-posts-cache-v1'];

//     event.waitUntil(
//         caches.keys().then(function (cacheNames) {
//             return Promise.all(
//                 cacheNames.map(function (cacheName) {
//                     if (cacheAllowlist.indexOf(cacheName) === -1) {
//                         return caches.delete(cacheName);
//                     }
//                 })
//             );
//         })
//     );
// });

// self.addEventListener('activate', event => {
//     alert('hi')
//     console.log('V1 now ready to handle fetches!')
// })

window.handleOpenURL = function (url) {
    console.log('received url: ' + url)
    url = url.replace('andyapp://', '/')
    router.push(url)
}

// service worker
addEventListener('message', (messageEvent) => {
    if (messageEvent.data === 'skipWaiting') return skipWaiting()
})

addEventListener('fetch', (event) => {
    event.respondWith(
        (async () => {
            if (event.request.mode === 'navigate' && event.request.method === 'GET' && registration.waiting && (await clients.matchAll()).length < 2) {
                registration.waiting.postMessage('skipWaiting')
                return new Response('', { headers: { Refresh: '0' } })
            }
            return (await caches.match(event.request)) || fetch(event.request)
        })()
    )
})

function fnEndInitializationApp() {
    // VALIDE IF DEVICE IS WITH CURRENT VERSION
    if (!store.getters['printer/getDeviceNewConfiguration']) {
        if (store.getters.getIsApp) {
            store.dispatch('printer/updateDeviceToNewConfiguration')
        } else {
            store.commit('printer/setDeviceNewConfiguration', true)
        }
    }
}

function fnRequestBTConnect() {
    bluetoothle.hasPermissionBtConnect((permission) => {
        if (permission.hasPermission) {
            localStorage.setItem('bluetoothle_enabled', 1)
            fnEndInitializationApp()
        } else {
            bluetoothle.requestPermissionBtConnect(
                (data) => {
                    if (data && data.requestPermission) {
                        localStorage.setItem('bluetoothle_enabled', 1)
                        fnEndInitializationApp()
                    } else {
                        localStorage.setItem('bluetoothle_enabled', 0)
                        fnEndInitializationApp()
                    }
                },
                (err) => {
                    console.error('fnRequestBTConnect - requestPermissionBtConnect', err)
                    fnEndInitializationApp()
                }
            )
        }
    })
}

function fnRequestBTScanPermission() {
    bluetoothle.hasPermissionBtScan((permission) => {
        if (permission.hasPermission) {
            fnRequestBTConnect()
        } else {
            bluetoothle.requestPermissionBtScan(
                (data) => {
                    if (data && data.requestPermission) {
                        fnRequestBTConnect()
                    } else {
                        localStorage.setItem('bluetoothle_enabled', 0)
                    }
                },
                (err) => {
                    console.error('fnRequestBTScanPermission - requestPermissionBtScan', err)
                }
            )
        }
    })
}

function initRequestBTPermissions() {
    // var params = {
    //     request: true,
    //     statusReceiver: false,
    //     restoreKey: 'andyappcc'
    // }
    // bluetoothle.initialize(function (data) {
    //     console.log('BLE Initialize status:', data)
    //     if (data && data.status == 'enabled') {
    //         fnRequestBTScanPermission()
    //     } else {
    //         // TODO SHOW ERROR INIT BLE
    //         console.error('BLE Initialize Error', data)

    //         localStorage.setItem('bluetoothle_enabled', 1)

    //     }
    // }, params)
    fnEndInitializationApp()
}

// end service worker

// SET EVENTS TO REGISTER USER INTERACTIONS
log('REGISTRANDO EVENTOS DE INTERACCIONES DE USUARIO')
document.addEventListener(
    'visibilitychange',
    function () {
        if (document.hidden) {
            log('--- ANDY HIDDEN ---')
        } else {
            log('--- ANDY ACTIVE AGAIN ---')
            if (store.getters.getIsApp) {
                cordova.plugins.brotherprinter.getAutoTimeEnable(
                    function (enable) {
                        store.commit('setAutoTimeEnable', enable)
                    },
                    function (err) {
                        logError('ERROR AL OBTENER EL PARAMETRO' + err.toString())
                    }
                )
                cordova.plugins.brotherprinter.getAirPlaneModeEnable(
                    function (enable) {
                        store.commit('setAirPlaneModeEnable', enable)
                    },
                    function (err) {
                        logError('ERROR AL OBTENER EL PARAMETRO' + err.toString())
                    }
                )
            }

            let last_action = store.getters.getLastEmployeeAction
            log('LAST ACTION EMPLOYEE DETECTED', last_action, moment().valueOf() - store.getters.getTimeout * 1000)
            if (last_action && last_action.time < moment().valueOf() - store.getters.getTimeout * 1000) {
                var self = this
                const employee_id = store.getters['loginUser/getLocalEmployee']
                if(store.getters['loginUser/getAuditor']){
                    store.dispatch('login/logoutAuditorLocal')
                    self.$router.push('/audit')
                }
                if (employee_id) {
                    store
                        .dispatch('loginUser/doLogout', {
                            employee: employee_id
                        })
                        .then(function (response) {
                            router.push(mixin.redirectPath('/home')).catch((err) => {
                                if (err.name == 'NavigationDuplicated') {
                                    router.go(router.history.current)
                                }
                            })
                        })
                }
            }
            let conn = fnCheckConnection()
            store.commit('setConnState', conn)
        }
    },
    false
)

const events = ['keydown', 'mousedown', 'touchstart']
events.forEach((e) => {
    document.addEventListener(e, function () {
        store.commit('setLastEmployeeAction', { type: e, time: moment().valueOf() })
    })
})

// END SET EVENTS

if (process.env.VUE_APP_IS_APP == 'TRUE') {
    var appRootURL = window.location.href.replace('index.html', '')
    css_image_url = window.location.origin + window.location.pathname.replace('index.html', '') + 'img/'
    window.onerror = function (errorMsg, url, line, col, error) {
        var logMessage = errorMsg
        var stackTrace = null
        log(errorMsg + ' - ' + url + ' - ' + line + ' - ' + col + ' - ' + error)

        var sendError = function () {
            if (store.getters.getIsAndroid && FirebasePlugin) {
                FirebasePlugin.logError(
                    logMessage,
                    stackTrace,
                    function () {
                        log('Sent JS exception')
                    },
                    function (error) {
                        logError('Failed to send JS exception', error)
                    }
                )
            }
        }

        logMessage += ': url=' + url.replace(appRootURL, '') + '; line=' + line + '; col=' + col

        if (typeof error === 'object') {
            StackTrace.fromError(error).then(function (trace) {
                stackTrace = trace
                sendError()
            })
        } else {
            sendError()
        }
    }

    document.addEventListener(
        'deviceready',
        function () {
            log('DEVICE READY')
            // getLocalIP()
            //     .then((localDeviceIP) => {
            //         console.log('getLocalIP', localDevice)
            //     })
            //     .catch((e) => {
            //         alert('ERROR GETTING LOCAL IP', JSON.stringify(e))
            //     })

            // SETTING PATH FILES FOR APP
            var path = cordova.file.applicationStorageDirectory
            if (store.getters.getIsIOS) path += 'Documents/'
            log('SETTING PATH FILES: ', path)
            store.commit('setPathFiles', path)

            cordova.plugins.brotherprinter.getAutoTimeEnable(
                function (enable) {
                    store.commit('setAutoTimeEnable', enable)
                },
                function (err) {
                    logError('ERROR AL OBTENER EL PARAMETRO' + err.toString())
                }
            )
            cordova.plugins.brotherprinter.getAirPlaneModeEnable(
                function (enable) {
                    store.commit('setAirPlaneModeEnable', enable)
                },
                function (err) {
                    logError('ERROR AL OBTENER EL PARAMETRO' + err.toString())
                }
            )

            function pdMoveInternalFilesToRootDirectory(aFilesToCopy, fCallBack) {
                var permissions = cordova.plugins.permissions
                var list = [permissions.CAMERA /*, permissions.BLUETOOTH, permissions.BLUETOOTH_ADVERTISE, permissions.BLUETOOTH_SCAN */]

                permissions.checkPermission(list, success, error)

                function error() {
                    log('Camera or Accounts permission is not turned on')
                }

                function success(status) {
                    if (!status.hasPermission) {
                        permissions.requestPermissions(
                            list,
                            function (status) {
                                if (!status.hasPermission) error()
                                ble.isLocationEnabled(
                                    function () {
                                        console.log('Permission validated')
                                        ble.scan(
                                            [],
                                            1,
                                            function () {
                                                console.log('FINISH SCAN')
                                                localStorage.setItem('first_time', 1)

                                                getDeviceLocation()

                                                initRequestBTPermissions()
                                            },
                                            function (err) {
                                                localStorage.setItem('first_time', 1)

                                                getDeviceLocation()

                                                initRequestBTPermissions()
                                            }
                                        )
                                    },
                                    function () {
                                        console.error('location services are *not* enabled')
                                        localStorage.setItem('first_time', 1)

                                        getDeviceLocation()

                                        initRequestBTPermissions()
                                    }
                                )
                            },
                            error
                        )
                    } else {
                        getDeviceLocation()
                    }
                }
                // }
            }

            if (typeof localStorage.first_time === 'undefined' || localStorage.first_time == '1') {
                var aFilesToCopy = [
                    // { current_dir: cordova.file.applicationDirectory + 'www/extras/td2120_57mm.bin', root_dir: 'ALEX', name: 'td2120_57mm.bin' },
                    // { current_dir: cordova.file.applicationDirectory + 'www/extras/product2.pdz', root_dir: 'ALEX', name: 'product2.pdz' }
                ]

                pdMoveInternalFilesToRootDirectory(aFilesToCopy)

                // setTimeout(function() {console.log("ERROR THROW");throw new Error()}, 30);

                new Vue({
                    el: '#app',
                    i18n,
                    store,
                    router,
                    moment,
                    components: { App },
                    template: '<App/>',
                    mixins: [mixin]
                    /* mounted() {
                        let recaptchaScript = document.createElement('script')
                        recaptchaScript.setAttribute('src', '~@/core/store.js')
                        document.head.appendChild(recaptchaScript)
                      }, */
                })
            }
        },
        false
    )
} else {
    store.commit('printer/setDeviceNewConfiguration', true)
    new Vue({
        el: '#app',
        i18n,
        store,
        router,
        moment,
        components: { App },
        template: '<App/>',
        mixins: [mixin]
        /* mounted() {
              let recaptchaScript = document.createElement('script')
              recaptchaScript.setAttribute('src', '~@/core/store.js')
              document.head.appendChild(recaptchaScript)
            }, */
    })
}

function getDeviceLocation() {
    navigator.geolocation.getCurrentPosition(
        function (position) {
            log('GETTING POSITION: ', position)
            var coords = { latitude: position.coords.latitude, longitude: position.coords.longitude }
            store.commit('setGeoLocation', coords)
        },
        function (error) {
            log('ERROR GEOLOCATION -> ', error)
            if (error.code == 3) {
                setTimeout(getDeviceLocation, 10 * 60 * 1000)
            }
        },
        { timeout: 3000 }
    )
}
